<template>
  <v-card>
    <v-card v-for="(pregunta, i) in campo.preguntas" :key="i" class="card">
      <QuarterBox>
        <v-card-text class="llenar">
          {{ pregunta.pregunta }}
        </v-card-text>
      </QuarterBox>
      <MiddleBox>
        <v-textarea class="v-textarea--outlined llenar" v-model="pregunta.valor"></v-textarea>
        <v-btn class="red white--text" @click="eliminarPreguntaVentana(i); "><v-icon>mdi-delete</v-icon></v-btn>
        <v-btn class="blue white--text" @click="editarPregunta(i);"><v-icon>mdi mdi-pencil</v-icon></v-btn>
      </MiddleBox>
    </v-card>
    <FullBox class="menuA">
      <v-card>
        <v-btn  class="green white--text" @click="crearNuevaPregunta()"> <v-icon>mdi-plus</v-icon></v-btn>
      </v-card>
    </FullBox>
    <v-dialog
      v-model="ventanaEliminar">
      <v-card>
        <v-btn @click="eliminarPregunta()" class="red white--text">Elimnar Pregunta</v-btn>
        <v-btn @click="cancelar()" class="green white--text">Cancelar</v-btn>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ventanaNuevaPregunta">
      <v-card class="centrar">
        <v-textarea v-model="nuevaPregunta" class="medio"/>
        <FullBox>
          <v-btn @click="agregarPregunta()" class="green white--text">Agregar</v-btn>
          <v-btn @click="cancelarPregunta()" class="red white--text">Cancelar</v-btn>
        </FullBox>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ventanaEditarPregunta">
      <v-card class="centrar">
        <FullBox>
          <v-textarea v-model="preguntaEditar" class="medio"/>
        </FullBox>
        <v-btn class="green white--text" @click="confirmarCambios()"><v-icon>mdi mdi-check-bold</v-icon></v-btn>
        <v-btn @click="ventanaEditarPregunta = false" class="red white--text">Cancelar</v-btn>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>

import MiddleBox from './MiddleBox.vue';
import QuarterBox from './QuarterBox.vue';
import FullBox from './FullBox.vue';
export default({
  components: { MiddleBox, QuarterBox, FullBox },
  props: {
    campo: {
      require: true
    }
  },
  name: 'CampoEntrevista',
  data: () => ({
    ventanaEliminar: false,
    ventanaNuevaPregunta: false,
    ventanaEditarPregunta: false,
    preguntaEditar: '',
    indiceEditar:null,
    idEliminar: null,
    nuevaPregunta: ''
  }),
  methods: {
    eliminarPreguntaVentana: function(i){
      this.idEliminar = i
      this.ventanaEliminar = !this.ventanaEliminar
    },
    cancelar: function(){
      this.ventanaEliminar = !this.ventanaEliminar
    },
    eliminarPregunta:  function(){
      this.campo.preguntas.splice(this.idEliminar,1)
      this.ventanaEliminar = !this.ventanaEliminar
    },
    crearNuevaPregunta: function(){
      this.ventanaNuevaPregunta = !this.ventanaNuevaPregunta
    },
    agregarPregunta: function(){
      this.campo.preguntas.push({pregunta: this.nuevaPregunta,valor: null}) 
      this.cancelarPregunta()
      this.nuevaPregunta = ''
    },
    editarPregunta: function(i){
      this.indiceEditar = i
      this.ventanaEditarPregunta = !this.ventanaEditarPregunta
      this.preguntaEditar = this.campo.preguntas[i].pregunta
    },
    confirmarCambios: function(){
      this.ventanaEditarPregunta = !this.ventanaEditarPregunta
      this.campo.preguntas[this.indiceEditar].pregunta = this.preguntaEditar
    },
    cancelarPregunta: function(){
      this.crearNuevaPregunta()
    }
  }
})
</script>
<style scoped>
.llenar {
  width: 95%;
}
.v-textarea--outlined {
  border: 1px solid #ccc;
  border-radius: 4px;
}
.menuA{
  display: block;
}
.card{
  border: outset 1px rgb(255, 252, 252);
  display: flex;
  min-height: 80px;
}
.medio{
  display: inline-block;
  width: 50%;
}
.centrar{
  text-align: center;
}
</style>