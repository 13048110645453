<template>
  <div
    v-if="
      $dataUser[0].rol == 'Cliente' ||
      $dataUser[0].rol == 'Admin' ||
      $dataUser[0].idRol == 8 ||
      $dataUser[0].idRol == 7
    "
  >
    <FullBoxVue class="shadowHover">
      <h1>Nueva solicitud</h1>
      Los campos con un * son obligatorios
      <br />
      <v-alert
        :type="data.icon"
        v-for="(data, index) in mensajesEnvioForm"
        :key="index"
        closable
      >
        <span v-html="data.title"></span
      ></v-alert>
    </FullBoxVue>

    <FullBoxVue
      class="shadowHover"
      v-if="$dataUser[0].rol == 'Admin' || $dataUser[0].idRol == 7"
    >
      <h2>Seleccionar usuario</h2>
      <searchUserVue
        v-bind:people="userSearch"
        @selected="userSearchSelectedFunc"
      ></searchUserVue>
    </FullBoxVue>
    <MiddleBox class="shadowHover">
      <v-text-field
        v-model="CentroCostos"
        placeholder="Centro de costos"
      ></v-text-field>
      <div class="formContent">
        <div v-for="(data, index) in schemaServicio" :key="index">
          <p>
            Servicio: {{ index + 1 }} &nbsp;
            <v-btn color="error" @click="eliminarServicio(index)"
              >Eliminar</v-btn
            >
          </p>

          <v-select
            @change="buscarSubservicio(valuesServicio[index].servicio, index)"
            v-model="valuesServicio[index].servicio"
            :items="data.servicio"
            item-text="nombre"
            item-value="id"
            placeholder="Servicio*"
            class="fiftyPercent"
          ></v-select>
          <v-select
            v-model="valuesServicio[index].subservicio"
            :items="data.subservicio"
            item-text="nombre"
            item-value="id"
            placeholder="SubServicio*"
            class="fiftyPercent"
          ></v-select>
          <p>
            <b>Observación: </b
            >{{
              getobservaciones(
                valuesServicio[index].subservicio,
                data.subservicio
              )
            }}
          </p>
        </div>

        <v-btn color="success" @click="agregarServicios()"
          >Agregar servicio</v-btn
        >

        <br /><br />
        <BlitzForm
          :schema="schemaUbicacion"
          v-model="valuesUbicacion"
          :key="componentKeyUbicacion"
          gridGap="0"
          placeholderPosition="left"
        />
      </div>
    </MiddleBox>
    <MiddleBox class="shadowHover">
      <h2>Datos del candidato</h2>
      <BlitzForm
        class="BlitzForm"
        :schema="schemaCandidato"
        v-model="valuesCandidato"
        gridGap="0"
        placeholderPosition="left"
      />
      <input type="file" @change="uploadFile" ref="file" accept="image/*" />
      <br /><br />
    </MiddleBox>
    <FullBoxVue class="shadowHover">
      <v-alert
        v-if="errorRequeridos != ''"
        title="Hay errores en su formulario"
        type="error"
      >
        <span v-html="errorRequeridos"></span
      ></v-alert>
      <div v-if="!loading">
        <v-btn
          color="primary"
          @click="enviarFormulario"
          :disabled="errorRequeridos != ''"
        >
          Enviar Solicitud
        </v-btn>
      </div>
      <div v-else>
        <loadingCSS>
          Se están creando las solicitudes... <br />
          Esto puede tomar unos momentos
        </loadingCSS>
      </div>
    </FullBoxVue>
  </div>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";
import { BlitzForm } from "blitzar";
import Vue from "vue";
import loadingCSS from "../components/static/loadingCSS.vue";

import {
  VTextField,
  VRadioGroup,
  VRadio,
  VSlider,
  VCheckbox,
  VSwitch,
  VSelect,
} from "vuetify/lib";
// All components that are used in the form need to be globally registered.
Vue.component("VTextField", VTextField);
Vue.component("VRadioGroup", VRadioGroup);
Vue.component("VRadio", VRadio);
Vue.component("VSlider", VSlider);
Vue.component("VCheckbox", VCheckbox);
Vue.component("VSwitch", VSwitch);
Vue.component("VSelect", VSelect);

import {
  getServicio,
  getSubServicioSpecificUser,
  getServicioEspecifico,
  getServicioEspecificoEspecifico,
  getDepartamento,
  getCiudad,
  getDocumento,
  newForm,
  getClientes,
  getServicioSpecificUser,
} from "@/api";
import MiddleBox from "@/components/static/MiddleBox.vue";
import searchUserVue from "../components/searchUser.vue";

const valuesServiciodef = {
  servicio: "",
  subservicio: "",
};

const valuesUbicaciondef = {
  departamento: "",
  ciudad: "",
};

const valuesCandidatodef = {
  nombreCandidato: "",
  tipodocumento: "",
  documento: "",
  numeroCandidato: "",
  correo: "",
  direccion: "",
  observaciones: "",
};

const defaultResponsive =
  " @media (max-width: 600px) { width: 98%; max-width: 98%; margin: 1% }";

const thirtyPercent = "width: 28%;max-width: 28%;margin: 1%;";
const thirtyPercentWFloat =
  "width: 28%;max-width: 28%;margin: 1%; float: left;";

const fourtyPercent = "width: 38%;max-width: 38%;margin: 1%;";
const fourtyPercentWFloat =
  "width: 38%;max-width: 38%;margin: 1%; float: left;";

const fiftyPercent = "width: 48%;max-width: 48%;margin: 1%;";
const fiftyPercentWFloat = "width: 48%;max-width: 48%;margin: 1%; float: left;";

const sixtyPercent = "width: 58%;max-width: 58%;margin: 1%;";
const sixtyPercentWFloat = "width: 58%;max-width: 58%;margin: 1%; float: left;";

const seventyPercent = "width: 68%;max-width: 68%;margin: 1%;";
const seventyPercentWFloat =
  "width: 68%;max-width: 68%;margin: 1%; float: left;";

const hundredPercent = "width: 98%;max-width: 98%;margin: 1%;";
const hundredPercentWFloat =
  "width: 98%;max-width: 98%;margin: 1%; float: left;";

export default {
  data() {
    return {
      valuesServicio: [],
      CentroCostos: "",
      schemaServicio: [],

      valuesUbicacion: {
        departamento: "",
        ciudad: "",
      },
      schemaUbicacion: [],
      componentKey: 0,
      componentKeyUbicacion: -1,

      valuesCandidato: {
        nombreCandidato: "",
        tipodocumento: "",
        documento: "",
        numeroCandidato: "",
        correo: "",
        cargo: "",
        direccion: "",
        observaciones: "",
      },
      schemaCandidato: [],
      Images: null,
      datosUser: {},
      userSearch: [],
      userSearchSelected: [],
      serviciosDefault: [],
      loading: false,
      errorRequeridos: "Los datos están incompletos.",
      mensajesEnvioForm: [],
    };
  },

  methods: {
    myEventHandler(e) {
      if (e.currentTarget.innerWidth <= 600) {
        let classNames = document.getElementsByClassName("blitz-field");
        for (let i = 0; i < classNames.length; i++) {
          document.getElementsByClassName("blitz-field")[i].style = "";
        }
      }
    },

    getobservaciones(id, arraySubservicios) {
      for (let i in arraySubservicios) {
        if (arraySubservicios[i].id == id) {
          if (arraySubservicios[i].observaciones != "") {
            return arraySubservicios[i].observaciones;
          }
          return "No hay observaciones";
        }
      }
    },

    userSearchSelectedFunc(values) {
      this.userSearchSelected = values;
    },
    recargarFormulario() {
      this.componentKey += 1;
      this.componentKeyUbicacion -= 1;
    },

    validarForm(schema, values) {
      let returnValue = true;
      for (let i = 0; i < schema.length; i++) {
        let element = schema[i];
        if (element.isRequired) {
          if (
            values[element.id] == "" ||
            values[element.id] === null ||
            values[element.id].length === 0
          ) {
            if (this.errorRequeridos == "") {
              this.errorRequeridos =
                " El campo " + element.placeholder + " es requerido <br>";
            } else {
              this.errorRequeridos +=
                " El campo " + element.placeholder + " es requerido <br>";
            }
            returnValue = false;
          }
        }
      }
      return returnValue;
    },

    validarServicios() {
      let values = this.valuesServicio;
      let returnValue = true;
      for (let i = 0; i < values.length; i++) {
        if (
          values[i].servicio == "" ||
          values[i].servicio === null ||
          values[i].servicio.length === 0 ||
          values[i].subservicio == "" ||
          values[i].subservicio === null ||
          values[i].subservicio.length === 0
        ) {
          if (this.errorRequeridos == "") {
            this.errorRequeridos =
              " Campos del servicio " + (i + 1) + " están incompletos <br>";
          } else {
            this.errorRequeridos +=
              " Campos del servicio " + (i + 1) + " están incompletos <br>";
          }
          returnValue = false;
        }
      }
      return returnValue;
    },

    validarFormularios() {
      this.errorRequeridos = "";
      this.validarServicios();
      this.validarForm(this.schemaUbicacion, this.valuesUbicacion);
      this.validarForm(this.schemaCandidato, this.valuesCandidato);
    },

    uploadFile() {
      this.Images = this.$refs.file.files[0];
    },

    enviarFormulario() {
      this.loading = true;
      const formData = new FormData();
      formData.append("file", this.Images);
      formData.append("CentroCostos", this.CentroCostos);
      formData.append("InfoServicio", JSON.stringify(this.valuesServicio));
      formData.append("Ubicacion", JSON.stringify(this.valuesUbicacion));
      formData.append("Candidato", JSON.stringify(this.valuesCandidato));
      if (this.$dataUser[0].rol == "Admin" || this.$dataUser[0].idRol == 7) {
        formData.append("Usuario", JSON.stringify(this.userSearchSelected));
      }
      newForm(formData).then(
        function (response) {
          const separarString = (value) => value.split(/\r\n|\r|\n/, -1);
          let stringSeparada = separarString(response.data);
          this.mensajesEnvioForm = [];

          for (let msg in stringSeparada) {
            if (stringSeparada[msg] != "") {
              if (stringSeparada[msg].search("Error") == -1) {
                this.mensajesEnvioForm.push({
                  icon: "success",
                  title: stringSeparada[msg],
                });
                //this.valuesServicio = valuesServiciodef;
                //this.valuesUbicacion = valuesUbicaciondef;
                //this.valuesCandidato = valuesCandidatodef;
                this.loading = false;
                this.recargarFormulario();
                //setTimeout('document.location.reload()',1000);
              } else {
                this.mensajesEnvioForm.push({
                  icon: "error",
                  title: stringSeparada[msg],
                });
                this.loading = false;
                this.recargarFormulario();
              }
            }
          }
        }.bind(this)
      );
    },

    agregarServicios() {
      this.schemaServicio.push(
        structuredClone({
          servicio: this.serviciosDefault,
          subservicio: [],
        })
      );
      this.valuesServicio.push(structuredClone(valuesServiciodef));
    },

    eliminarServicio(index){
      this.schemaServicio.splice(index,1)

      if (typeof this.valuesServicio[index] !== 'undefined') {
        this.valuesServicio.splice(index,1)
      }
    },

    consultaServicio(idEmpresa) {
      getServicioSpecificUser(idEmpresa).then(
        function (response) {
          this.preServicios = response.data;
          this.serviciosDefault = [];
          for (let i = 0; i < this.preServicios.length; i++) {
            this.serviciosDefault.push({
              id: this.preServicios[i].ID_SERVICIO,
              nombre: this.preServicios[i].NOMBRE_SERVICIO,
            });
          }
          this.schemaServicio = [];
          this.schemaServicio.push(
            structuredClone({
              servicio: this.serviciosDefault,
              subservicio: [],
            })
          );
          this.valuesServicio = [];
          this.valuesServicio.push(structuredClone(valuesServiciodef));

          //this.agregarServicios();
          this.recargarFormulario();
        }.bind(this)
      );
    },

    buscarSubservicio(id, indice) {
      this.valuesServicio[indice].subservicio = "";
      let idUser = this.userSearchSelected;
      if (this.$dataUser[0].idRol == 8 || this.$dataUser[0].idRol == 2) {
        idUser = this.$dataUser[0].id;
      }
      getSubServicioSpecificUser(id, idUser).then(
        function (response) {
          if (response.data.length > 0) {
            let preSubServicios = response.data;
            let subserviciosDefault = [];
            for (let i = 0; i < preSubServicios.length; i++) {
              subserviciosDefault.push({
                id: preSubServicios[i].ID_SUBSERVICIO,
                nombre: preSubServicios[i].NOMBRE_SUBSERVICIO,
                observaciones: preSubServicios[i].observaciones,
              });
            }
            this.schemaServicio[indice].subservicio = subserviciosDefault;
            this.recargarFormulario();
          }
        }.bind(this)
      );
    },

    consultaCiudad(id) {
      getCiudad(id).then(
        function (response) {
          const indice = 1;
          if (response.data.length > 0) {
            const itemsOptionsTemplate = {
              value: "",
              text: "Seleccione la ciudad",
              disabled: true,
            };

            const options = [itemsOptionsTemplate];

            for (let i = 0; i < response.data.length; i++) {
              // Solución planteada así por un error de referencia de memoria
              const itemsOptionsTemplate = {
                value: "",
                text: "",
              };
              const subTemplate = itemsOptionsTemplate;
              itemsOptionsTemplate.value = response.data[i].ID_CIUDAD;
              subTemplate.text = response.data[i].NOMBRE_CIUDAD;
              options.push(subTemplate);
            }

            if (this.schemaUbicacion[indice] == null) {
              this.schemaUbicacion.splice(indice, 0, {
                id: "ciudad",
                span: true,
                component: "VSelect",
                placeholder: "Ciudad*",
                items: options,
                style: fiftyPercentWFloat,
                isRequired: true,
              });
            } else {
              this.schemaUbicacion[indice] = {
                id: "ciudad",
                span: true,
                component: "VSelect",
                placeholder: "Ciudad*",
                items: options,
                style: fiftyPercentWFloat,
                isRequired: true,
              };
            }
          } else {
            this.schemaUbicacion.splice(indice, 1);
          }
          this.recargarFormulario();
          this.validarFormularios();
        }.bind(this)
      );
    },
  },

  mounted() {
    if (this.$dataUser[0].idRol == 8 || this.$dataUser[0].idRol == 2) {
      this.consultaServicio(this.$dataUser[0].id);
    }

    const { offsetWidth, offsetHeight } = document.querySelector("#app");
    if (offsetWidth <= 600) {
      setTimeout(function () {
        let classNames = document.getElementsByClassName("blitz-field");
        for (let i = 0; i < classNames.length; i++) {
          document.getElementsByClassName("blitz-field")[i].style = "";
        }
      }, 200);
    }

    let classNames = document.getElementsByClassName("blitz-form__form");
    for (let i = 0; i < classNames.length; i++) {
      document.getElementsByClassName("blitz-form__form")[i].style =
        "display: block";
    }

    getClientes().then(
      function (response) {
        this.userSearch = response.data;
      }.bind(this)
    );

    getDepartamento().then(
      function (response) {
        const indice = 0;
        const totalForms = 5;
        const itemsOptionsTemplate = {
          value: "",
          text: "Seleccione el departamento",
          disabled: true,
        };

        const options = [itemsOptionsTemplate];

        for (let i = 0; i < response.data.length; i++) {
          // Solución planteada así por un error de referencia de memoria
          const itemsOptionsTemplate = {
            value: "",
            text: "",
          };
          const subTemplate = itemsOptionsTemplate;
          itemsOptionsTemplate.value = response.data[i].ID_DEPARTAMENTO;
          subTemplate.text = response.data[i].NOMBRE_DEPARTAMENTO;
          options.push(subTemplate);
        }

        if (this.schemaUbicacion[indice] == null) {
          this.schemaUbicacion.splice(indice, 0, {
            id: "departamento",
            span: true,
            component: "VSelect",
            placeholder: "Departamento*",
            items: options,
            style: fiftyPercentWFloat,
            isRequired: true,
          });
        } else {
          this.schemaUbicacion[indice] = {
            id: "departamento",
            span: true,
            component: "VSelect",
            placeholder: "Departamento*",
            items: options,
            style: fiftyPercentWFloat,
            isRequired: true,
          };
        }
        this.recargarFormulario();
      }.bind(this)
    );

    getDocumento().then(
      function (response) {
        const indice = 0;
        const totalForms = 5;
        const itemsOptionsTemplate = {
          value: "",
          text: "Tipo documento",
          disabled: true,
        };

        const options = [itemsOptionsTemplate];

        for (let i = 0; i < response.data.length; i++) {
          // Solución planteada así por un error de referencia de memoria
          const itemsOptionsTemplate = {
            value: "",
            text: "",
          };
          const subTemplate = itemsOptionsTemplate;
          itemsOptionsTemplate.value = response.data[i].ID_TIPO_DOCUMENTO;
          subTemplate.text = response.data[i].DESC_TIPO_DOCUMENTO;
          options.push(subTemplate);
        }

        const opcionesDocumentos = {
          id: "tipodocumento",
          span: true,
          component: "VSelect",
          placeholder: "Tipo de documento*",
          items: options,
          style: fourtyPercentWFloat,
          isRequired: true,
        };

        this.schemaCandidato = [
          {
            id: "nombreCandidato",
            component: "VTextField",
            placeholder: "Nombre del candidato*",
            isRequired: true,
          },
          opcionesDocumentos,
          {
            id: "documento",
            component: "VTextField",
            placeholder: "Número de documento*",
            style: sixtyPercent,
            isRequired: true,
          },
          {
            id: "numeroCandidato",
            component: "VTextField",
            placeholder: "Número telefónico*",
            style: fiftyPercentWFloat,
            isRequired: true,
          },
          {
            id: "correo",
            component: "VTextField",
            placeholder: "Correo*",
            style: fiftyPercentWFloat,
            isRequired: true,
          },
          {
            id: "cargo",
            component: "VTextField",
            placeholder: "Cargo*",
            style: fiftyPercentWFloat,
            isRequired: true,
          },
          {
            id: "direccion",
            component: "VTextField",
            placeholder: "Dirección*",
            style: fiftyPercentWFloat,
            isRequired: true,
          },
          {
            id: "observaciones",
            component: "VTextField",
            style: hundredPercentWFloat,
            placeholder: "Observaciones",
          },
        ];

        this.recargarFormulario();
      }.bind(this)
    );
  },

  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },

  watch: {
    valuesUbicacion(newValue, oldValue) {
      if (
        newValue.departamento &&
        newValue.departamento != oldValue.departamento
      ) {
        this.valuesUbicacion.ciudad = "";
        this.consultaCiudad(newValue.departamento);
      }
      this.validarFormularios();
    },

    valuesServicio: {
      handler(val) {
        this.validarFormularios();
      },
      deep: true,
    },
    valuesCandidato(newValue, oldValue) {
      this.validarFormularios();
    },

    userSearchSelected(newValue, oldValue) {
      this.consultaServicio(newValue);
    },
  },

  components: {
    FullBoxVue,
    BlitzForm,
    MiddleBox,
    searchUserVue,
    loadingCSS,
  },
};
</script>

<style scoped>
div {
  width: 100%;
}

VTextField {
  border: 2px solid #8c939d !important;
}

VSelect {
  border: 2px solid #8c939d !important;
  border-radius: 10px;
  padding: 2px;
}

.fiftyPercent {
  width: 48%;
  max-width: 48%;
  margin: 1%;
  float: left;
}

.blitz-form__form {
  display: block !important;
}
</style>
