<template>
  <div>
    <FullBox>
      <FullBox> 
        <h2>{{ titulo }}</h2>
        <v-btn @click="acivarExlusion = true"><v-icon>mdi mdi-playlist-edit</v-icon></v-btn>
      </FullBox>
      <div v-for="(entry,i) in campos" :key="i" >
        <div v-if="entry.wx === undefined && entry.ignorar === false">
          <QuarterBox
            v-if="
              entry.tipo === 'texto' ||
              entry.tipo === 'numero'  ||
              entry.tipo === 'desplegable'  ||
              entry.tipo === 'fecha'  ||
              entry.tipo === 'archivo'
              "
            >
            <RetornoCampo :campo="entry" :idSolicitud="idSolicitud" :idForm="idForm" :idIteracion="idIteracion" :i="i.toString()"/>
          </QuarterBox>
          <FullBox
          v-else-if="
              entry.tipo === 'textoLargo' ||
              entry.tipo === 'form' ||
              entry.tipo === 'tabla' ||
              entry.tipo === 'tablaComparativa' ||
              entry.tipo === 'completarTexto' ||
              entry.tipo === 'inf' ||
              entry.tipo === 'entrevista' ||
              entry.tipo === 'duplicaCampos' ||
              entry.tipo === 'evaluacionPoligrafia' ||
              entry.tipo === 'ConceptoGeneralVisita'
              ">
            <RetornoCampo :campo="entry" :idSolicitud="idSolicitud" :idForm="idForm" :idIteracion="idIteracion" :i="i.toString()"/>
          </FullBox>
        </div>
        <QuarterBox v-else-if="entry.wx === 1 ">
          <RetornoCampo :campo="entry" :idSolicitud="idSolicitud" :idForm="idForm" :idIteracion="idIteracion" :i="i.toString()"/>
        </QuarterBox>
        <MiddleBox v-else-if="entry.wx === 2 ">
          <RetornoCampo :campo="entry" :idSolicitud="idSolicitud" :idForm="idForm" :idIteracion="idIteracion" :i="i.toString()"/>
        </MiddleBox>
        <FullBox v-else-if="entry.wx === 3 ">
          <RetornoCampo :campo="entry" :idSolicitud="idSolicitud" :idForm="idForm" :idIteracion="idIteracion" :i="i.toString()"/>
        </FullBox>
      </div>
    </FullBox>
    <v-dialog v-model="acivarExlusion">
      <v-card>
        <FullBox>
          <v-btn @click="acivarExlusion = false"><v-icon x-large>mdi mdi-checkbox-multiple-marked</v-icon></v-btn>
        </FullBox>
        <MiddleBox v-for="(entry,i) in campos" :key="i">
          <h3>Campo: {{ i }}</h3>
          <h3>{{entry.label}}</h3>
          <v-btn
            v-if="entry.ignorar=== true"
            @click="entry.ignorar = false"
            class="red"
            ><v-icon x-large>mdi mdi-alpha-x-box</v-icon></v-btn>
          <v-btn
            v-else-if="entry.ignorar=== false"
            @click="entry.ignorar = true"
            class="green"
            ><v-icon x-large>mdi mdi-checkbox-marked</v-icon></v-btn>
          </MiddleBox>
          <FullBox>
            <v-btn @click="acivarExlusion = false"><v-icon x-large>mdi mdi-checkbox-multiple-marked</v-icon></v-btn>
          </FullBox>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import FullBox from './static/FullBox.vue'
import MiddleBox from './static/MiddleBox.vue'
import QuarterBox from './static/QuarterBox.vue'
import CampoFecha from './static/CampoFecha.vue'
import CreateForm from './CreateForm.vue'
import CreateTable from './CreateTable.vue'
import CampoArchivo from './static/CampoArchivo.vue'
import CreateTableComparative from './CreateTableComparative.vue'

export default({
  components: { FullBox, MiddleBox, QuarterBox, CampoFecha, CreateForm, CreateTable, CampoArchivo, CreateTableComparative},
  props: {
    titulo: String,
    campos: [], // {label: '',tipo: texto-numero-desplegable(lista)-fecha-form(form), valor: '',lista: [], ignorar: boolean }
    idForm: String,
    idIteracion: String,
    obligatorio: Boolean,
    idSolicitud: String
  },
  created () {
  },
    name: 'CreateForm',
  data: () => ({
    acivarExlusion: false,
    reglas: [
      value => !!value || 'Este campo es obligatorio'
    ],
    sinReglas: []
  })
})
</script>
<style scoped>
.tabla {
  width: 100%;
  overflow-x: auto;
}
</style>
