import GenerCampos from '../generCampos'

const generCampos = new GenerCampos()

var informeConfirmatorioPoligrafia = [
    {
       titulo: 'INFORMACIÓN PERSONAL',
       campos: [
          {
             label: 'FOTO DEL USUARIO',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false,
             justify: 'center'
          },
          {
             label: 'Nombres y Apellidos',
             tipo: 'texto',
             idCampo:6,
             valor: null,
             ignorar: false
          },
          {
             label: 'Número de Cédula',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Lugar de Expedición',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Fecha de Expedición',
             tipo: 'fecha',
             valor: null,
             ignorar: false
          },
          {
             label: 'Lugar de Nacimiento',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Fecha de Nacimiento',
             tipo: 'fecha',
             valor: null,
             ignorar: false
          },
          {
             label: 'Edad',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Estado Civil',
             tipo: 'desplegable',
             lista: ['Casado', 'Soltero', 'Unión libre'],
             valor: null,
             ignorar: false
          },
          {
             label: 'Dirección Residencia',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Barrio / Localidad',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Ciudad',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Teléfono',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Fecha Examen Poligráfico',
             tipo: 'fecha',
             valor: null,
             ignorar: false
          },
          {
             label: 'No. Polígrafos Anteriores',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Cargo al que Aspira',
             tipo: 'texto',
             idCampo:5,
             valor: null,
             ignorar: false
          },
          {
             label: 'Compañía Solicitante',
             tipo: 'texto',
             valor: null,
             idCampo: 1,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'ESTADO DE SALUD',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: [],
             listaValores: [
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Cómo califica su estado de salud?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Actualmente consume usted algún medicamento?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Ha tenido hospitalizaciones recientes?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Sufre de alguna enfermedad diagnosticada?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Ha estado en tratamientos psicológicos o psiquiátricos?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Le han realizado cirugías?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Ha tenido accidentes laborales?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
             ],
             formato: [],
             columnasPeque: [1],
             noEditable: true,
             ignorar: false
          },
          {
             label: '',
             tipo: 'inf',
             valor: 'Mencionar el detalle de lo que la persona manifieste en las preguntas que haya mencionado si',
             ignorar: false
          },
          {
             label: 'Observaciones',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          },
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: 'De acuerdo con lo mencionado frente al estado de salud físico y psicológico, se consideró a la persona',
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      tipo: 'texto',
                      valor: null
                   }
                },
                {
                   tipo: 'texto',
                   texto: 'para el desarrollo de su prueba.'
                }
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'PREGUNTAS A CONFIRMAR',
       campos: [
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `Posterior a la prueba inicial de Preempleo y por solicitud de la empresa `
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      tipo: 'texto',
                      valor: null
                   }
                },
                {
                   tipo: 'texto',
                   texto: `, se le practicó un examen de corroboración para verificar las reacciones
                   fisiológicas de engaño que presentó el evaluado en su prueba inicial.`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `En el anterior examen, el evaluado generó reacciones fisiológicas significativas de engaño a las preguntas:`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `R-1 ¿SUMINISTRÓ USTED ALGÚN DOCUMENTO O INFORMACIÓN FALSA EN SU HOJA DE VIDA PARA INGRESAR A TRABAJAR
                    EN LA AGRUPACIÓN LA PRADERA DE POTO ?  `
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      tipo: 'texto',
                      valor: null
                   }
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   valor: `El propósito de este segundo examen, es tratar de descubrir junto con el evaluado, qué pudo haberle
                   generado estas reacciones y tratar de lograr admisiones con relación a estos temas, para luego verificar sus
                   argumentos con el instrumento (el polígrafo).`
                }
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'ADMISIONES',
       campos: [
          {
             label: '',
             tipo: 'inf',
             valor: 'Las obtenidas durante la prueba.',
             ignorar: false
          },
          {
             label: '',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'TIPO DE PREGUNTAS RELEVANTES FORMULADAS DURANTE LA EVALUACIÓN',
       campos: [
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `En el proceso de la evaluación poligráfica se desarrolla una completa entrevista con multiplicidad
                   de preguntas. Sin embargo, ya en el momento de generar el test poligráfico, solo se permiten realizar un
                   número exacto de las cuales en el presente informe se enumeran aquellas preguntas relevantes que se
                   desarrollaron.`
                },
                {
                   tipo: 'br',
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `Se utilizó una técnica poligráfica convalidada por las diferentes asociaciones poligráficas a nivel
                   mundial, con el fin de registrar reacciones fisiológicas significativas de veracidad, en cada una de las
                   preguntas relevantes que se le desarrollaron en el proceso.`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `Se le realizaron varias gráficas de poligrafía, las cuales fueron analizadas y calificadas por el analista,
                   bajo los parámetros que para ello,  estipula la Asociación Americana de Poligrafistas (APA).`
                },
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'TEMAS A EVALUAR',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             columnasPeque: [0],
             formato: [
                { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                { label: '', tipo: 'texto', valor: null}
             ],
             cabeceras: [],
             listaValores: [
                [
                   { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                   { label: '', tipo: 'texto', valor: null}
                ],
                [
                   { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                   { label: '', tipo: 'texto', valor: null}
                ],
                [
                   { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                   { label: '', tipo: 'texto', valor: null}
                ],
                [
                   { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                   { label: '', tipo: 'texto', valor: null}
                ],
             ],
             ignorar: false,
             autoIncrementable: true
          },
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `Cada una de las preguntas anteriormente relacionadas, fueron explicadas con anterioridad a
                   la evaluación, las cuales se operacionalizaron, aclararon y enmarcaron dentro del contexto de lo
                   que se quería indagar, de tal forma que la evaluada las explicó y manifestó entenderlas con
                   claridad.`
                }
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'PARÁMETROS CIENTÍFICOS',
       campos: [
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `El examen poligráfico es realizado con un instrumento sistematizado marca `
                },
                {
                    tipo: 'campo',
                    campo: {
                       label: '',
                       tipo: 'texto',
                       valor: null
                    }
                },
                {
                    tipo: 'texto',
                    texto: `, teniendo en cuenta las normas internacionales estipuladas por la Asociación Americana de Poligrafistas (APA),
                    en el cual los patrones de verdad y decepción se establecen a través de la técnica conocida como `
                },
                {
                    tipo: 'campo',
                    campo: {
                       label: '',
                       tipo: 'texto',
                       valor: null
                    }
                 },
                 {
                    tipo: 'texto',
                    texto: ` que incluye un formato de preguntas neutrales, comparativas y relevantes.`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `El examen es ejecutado por un Poligrafista profesional certificado; una vez realizada la entrevista
                   previa, se procede a explicar los componentes del polígrafo CARDIO, NEUMÓGRAFO, EDA y SENSOR DE MOVIMIENTO,
                   así mismo se le informó que la evaluación es de carácter VOLUNTARIO, seguidamente procede a diligenciar la
                   autorización correspondiente para iniciar el examen y afirma haber entendido todo el procedimiento al que se
                   somete.`
                }
             ],
             ignorar: false
          },
      //     {
      //      label: '',
      //      tipo: 'evaluacionPoligrafia',
      //      cabeceras: ['R-1','R-2','R-3','R-4'],
      //      listaValores: [
      //            { label: '', tipo: 'texto', valor: ''},
      //            { label: '', tipo: 'texto', valor: ''},
      //            { label: '', tipo: 'texto', valor: ''},
      //            { label: '', tipo: 'texto', valor: ''}
      //         ],
      //         listaOpciones: [
      //            { label: '', tipo: 'desplegable', valor: '', lista: ['NSR', 'SR','NOP','INC']},
      //            { label: '', tipo: 'desplegable', valor: '', lista: ['NSR', 'SR','NOP','INC']},
      //            { label: '', tipo: 'desplegable', valor: '', lista: ['NSR', 'SR','NOP','INC']},
      //            { label: '', tipo: 'desplegable', valor: '', lista: ['NSR', 'SR','NOP','INC']}
      //         ],
      //         campoPuntuacion: {
      //            label: '',
      //            tipo: 'texto',
      //            valor: null,
      //         },
      //         campoOpcionGeneral: {
      //            label: '',
      //            tipo: 'desplegable',
      //            lista: ['NSR', 'SR','NOP','INC'],
      //            valor: null
      //         },
      //         ignorar: false
      //   }
       ]
    },
    {
       titulo: 'RESULTADO DEL EXAMEN',
       campos: [
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `Usando el ESS (Empirical Scoring System), que es un protocolo basado en la evidencia, normado y
                   estandarizado para el análisis de datos de la prueba. Los resultados estadísticos y matemáticos apoyan la
                   conclusión que el evaluado `
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      idDependiente: 6,
                      noEditable: true,
                      tipo: 'texto',
                      valor: null
                   }
                },
                {
                   tipo: 'texto',
                   texto: `, quien está aplicando al cargo de `
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      idDependiente: 5,
                      noEditable: true,
                      tipo: 'texto',
                      valor: null
                   }
                },
                {
                   tipo: 'texto',
                   texto: ` `
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      tipo: 'desplegable',
                      lista: ['NO PRESENTÓ (NSR) ','PRESENTÓ (SR) ', 'NO OPINION (NOP) ', 'INCONCLUSO (INC) '],
                      valor: null
                   }
                },
                {
                   tipo: 'texto',
                   texto: ` respuestas fisiológicas significativas de falta de veracidad a las preguntas relevantes de este examen. `
                },
                {
                    tipo: 'campo',
                    campo: {
                       label: '',
                       tipo: 'texto',
                       valor: null
                 }
              },
             ],
             ignorar: false
          },
          {
              label: 'FIRMA',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false
           }
       ]
    },
    {
       titulo: 'NOTAS ACLARATORIAS',
       campos: [
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `Los resultados de las pruebas poligráficas siempre serán entregados al cliente que contrató los
                   servicios. Cada contratante está en plena autonomía de tomar las decisiones laborales y/o judiciales que
                   considere necesarias para cada situación. Sin embargo se recuerda que en Colombia la prueba poligráfica
                   no se constituye en prueba pericial o judicial, es sólo un indicio. Según la legislación laboral colombiana,
                   en NINGÚN momento se podrá despedir a una persona por los resultados del examen de polígrafo, pues no se
                   considera justa causa. En Colombia, los resultados de la evaluación poligráfica se utilizan para la
                   investigación interna de las organizaciones; ya sea en la verificación de antecedentes, idoneidad en cargos o
                   en la investigación de algún siniestro. En ningún momento, la información suministrada por el evaluado será
                   utilizado por el examinador o contratante para acusarlo judicialmente`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `Los resultados obtenidos en la evaluación poligráfica, son de carácter confidencial y la divulgación
                   de los mismos, por parte del contratante a personas que no hayan sido autorizadas por el evaluado, se
                   considera una violación a su privacidad.`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `En la evaluación poligráfica se miden y se comparan reacciones fisiológicas de cada ser humano.
                   A cada individuo se le determina un estándar y luego se mide y se compara matemática y estadísticamente.
                   Después del este análisis se emite un concepto basado en la ponderación de las respuestas fisiológicas y
                   se determina, si las reacciones fisiológicas de la persona, son típicas de una persona que miente o por el
                   contrario son típicas de una persona que dice la verdad. Debido a que la mayoría de las investigaciones en
                   poligrafía, las han desarrollado agencias gubernamentales y universidades americanas, los términos se han
                   globalizado según su traducción del inglés:`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `N.S.R. (No Significant Response), esto quiere decir que la persona no presentó reacciones fisiológicas significativas de engaño.
                   S.R. (Significant Response), esto quiere decir que la persona si presentó reacciones fisiológicas significativas de engaño, en otras palabras, que mintió.
                   Cuando los valores estadísticos y matemáticos no sobrepasan el umbral mínimo de confiabilidad de la prueba del 91%, se tiene que emitir un concepto de Inconcluso (INC) o No opinión (NOP).
                   Inconcluso hace referencia a que se tomó una muestra fisiológica adecuada, pero en la ponderación no se logró alcanzar un valor estadístico suficiente para emitir un concepto.
                   No Opinión, hace referencia a que no se pudo tomar una muestra fisiológica adecuada de la prueba y por ello no se puede emitir un concepto alguno.
                   Reseña Bibliográfica.
                   Tomado de Terminology Reference for the Science of Psychophysiological Detection of Deception. American Polygraph Association 3rd Edition, 2012 by Donald Krapohl, Mark Handler Shirley Sturm.
                   `
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `Determinación de estado psicofisiológico:
                   Idóneo: En condiciones adecuadas para el desarrollo de la prueba
                   Marginal: Que presenta alguna situación médica o psicológica, que aunque no impide el desarrollo de la prueba, podría afectar la confiabilidad. Sin embargo se puede desarrollar la prueba y obtener información de interés o evitar un ilícito.
                   No idóneo: Que el evaluado no está en condiciones físicas o psicológicas para presentar el examen.
                   `
                }
  
             ],
             ignorar: false
          },
       ]
    },
    {
        titulo: 'DOCUMENTOS',
        campos: [
            {
              tipo: 'saltoPagina'
            },
           {
              label: 'AUTORIZACIÓN',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false
           }
        ]
    }
  ]


export default(informeConfirmatorioPoligrafia)