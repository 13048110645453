<template>
  <div class="app">
    <v-container>
      <div v-if="muestraLabel">{{ label }}</div>
      <div v-if="oneFile">
        <input type="file" @change="uploadFile" ref="file" accept="image/*" v-if="archivosSubidos.length < 1"/>
        &nbsp;
        <v-btn  color="success" @click="subirArchivo()" v-if="archivosSubidos.length < 1">Subir archivo</v-btn>
      </div>
      <div v-else-if="label != 'FOTO DEL USUARIO'">
        <input type="file" @change="uploadFile" ref="file" accept="image/*"/>
        &nbsp;
        <v-btn  color="success" @click="subirArchivo()" >Subir archivo</v-btn>
      </div>
      <div v-else-if="archivosSubidos.length < 1">
        <input type="file" @change="uploadFile" ref="file" accept="image/*" />
        &nbsp;
        <v-btn  color="success" @click="subirArchivo()">Subir archivo</v-btn>
      </div>
      <br /><br />
      <v-data-table :headers="headers" :items="archivosSubidos">
        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <v-icon
              small
              class="mr-2"
              color="primary"
              @click="descargarArchivosTable(item)"
            >
              mdi-download
            </v-icon>
            <v-icon
              small
              class="mr-2"
              color="error"
              v-if="
                item.idSubidoPor == $dataUser[0].id || $dataUser[0].idRol == 3
              "
              @click="eliminarArchivoTable(item)"
            >
              mdi-delete
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>
<script>
import { subirArchivosCandidato, downloadFile, eliminarArchivos, getArchivosIndividuales } from "../../api";
export default {
  props: {
    valor: {
      default: "",
    },
    label: {
      default: "",
    },
    permitido: {
      default: "",
    },
    idForm: {
      default: "",
    },
    idIteracion: {
      default: "",
    },
    idSolicitud: {
      default: "",
    },
    isPerfilFoto: {
      default: false,
    },
    oneFile: {
      default: false,
    },
    muestraLabel: {
      default: true,
    },
    campo: {
      default: {}
    }
  },
  name: "CampoArchivo",
  data: () => ({
    subiendoArchivo: 0,
    archivosSubidos: [],
    Images: null,
    headers: [
        { text: 'Acciones', value: 'actions', sortable: false },
        { text: "Id", value: "id" },
        { text: "Nombre de archivo", value: "nombreArchivo" },
        { text: "Fecha de subida", value: "creado" },
        { text: "subidoPor", value: "subidoPor" },
        { text: "Observaciones", value: "comentarios" },
        { text: "Archivo requerido", value: "observaciones" },

      ],
  }),
  methods: {
    uploadFile() {
      this.Images = this.$refs.file.files[0];
    },

    eliminarArchivoTable(data){
      this.eliminarArchivo(data.id)
    },
    eliminarArchivo(id) {
      eliminarArchivos(id).then(
        function (response) {
          this.realizarConsultaArchivos();
        }.bind(this)
      );
    },
    descargarArchivosTable(data){
      this.descargarArchivos(data.archivo, data.nombreArchivo)
    },
    descargarArchivos(filepath, fileName) {
      downloadFile(filepath).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
    },
    subirArchivo() {
      const formData = new FormData();
      formData.append("file", this.Images);
      const descriptor = this.retornarCampoLabel()
      formData.append("descripcion", descriptor);
      formData.append("nombreCortado", descriptor);
      formData.append("idSolicitud", this.idSolicitud);

      subirArchivosCandidato(formData).then(
        function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Solicitud enviada exitosamente",
          });
          this.realizarConsultaArchivos();
        }.bind(this)
      );
    },
    realizarConsultaArchivos() {
      const descriptor = this.retornarCampoLabel()
      getArchivosIndividuales(this.idSolicitud, descriptor).then(
        function (response) {
          this.archivosSubidos = response.data;
        }.bind(this)
      );
    },

    retornarCampoLabel(){
      const regex = /^(?=.*\d{3})\S.*\d{3}$/;
      if (regex.test(this.campo.label)) {
        return this.muestraLabel ? this.campo.label.replace(/\d+$/, "").trim() : this.campo.label.replace(/\d+$/, "").trim() + " " + this.idIteracion
      }
      return this.muestraLabel ? this.campo.label : this.campo.label + " " + this.idIteracion

    }
    // subirArchivo: function () {
    //   this.subiendoArchivo = 1;
    //   const formData = new FormData();
    //   formData.append("idForm", this.idForm);
    //   formData.append("idForm", this.idIteracion);
    //   formData.append("file", this.valor);

    //   subirArchivos(formData).then((response) => {
    //     this.subiendoArchivo = 2;
    //     console.log(response);
    //   });

    //   console.log(this.idForm);
    //   console.log(this.idIteracion);
    // },
  },
  mounted() {

    this.campo.label = this.retornarCampoLabel()

    this.realizarConsultaArchivos();

  },
};
</script>
<style scoped>
.app {
  border: 1px outset #e7e7e7;
  border-radius: 5px;
  padding: 5px;
}
.linea {
  display: inline-block;
  margin: 5px;
}
.entry {
  width: 70%;
}
.btn {
  width: 20%;
}
</style>
