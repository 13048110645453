<template>
  <div>
    <table class="tabla bordes" density="compact">
      <thead>
        <tr class="bordes">
          <th class='bordesX'></th>
          <th  v-for="(cabecera,i) in cabeceras" :key="i" class='bordesX min150'>
            {{ cabecera }}
          </th>
        </tr>
      </thead>
      <tbody>
          <tr v-for="(form,i) in forms " :key="i" >
            <td class="bordesY bordesX mx100">
              <strong>
                {{ form.titulo }}
              </strong>
            </td>
            <td class="bordesX">
              <div v-for="(campo,i) in form.campos1" :key="i">
                <v-text-field v-if="campo.tipo === 'texto'" :label="campo.label" v-model="campo.valor"></v-text-field>
              </div>
            </td>
            <td class="bordesX">
              <div v-for="(campo,i) in form.campos2" :key="i">
                <v-text-field :label="campo.label" v-model="campo.valor"></v-text-field>
              </div>
            </td>
          </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

export default ({
  components: { },
  props: {
    label: {
      default: ''
    },
    cabeceras: {
      default: []
    },
    forms: {
      default: []
    }
  },
  created () {
    this.forms.map(form => {
      form.campos2 = JSON.parse(JSON.stringify(form.campos1));
    })
  },
  name: 'CreateTableComparative'
})
</script>
<style scoped>
.bordes{
  border: 1px rgb(228, 228, 228) outset;
  border-radius: 0px;
}
.bordesX, tr{
  border-top: 1px rgb(121, 110, 153) outset;
}
.bordesY {
  border-right: 1px rgb(228, 228, 228) outset;
}
.tabla {
  width: 100%;
}
.mx100{
  min-width: 100px;
}
.tabla th,.tabla td  {
  width: calc(100% / var(--num-cabeceras));
}
.min150 {
  min-width: 150px;
}

</style>