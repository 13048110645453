import GenerCampos from '../generCampos'

const generCampos = new GenerCampos()

var referenciacion = [
    {
       titulo: 'INFORMACIÓN PERSONAL',
       campos: [
           {
              label: 'FOTO DEL USUARIO',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false,
              justify: 'center'
           },
           {
              label: 'Nombres Completos',
              tipo: 'texto',
              valor: null,
              ignorar: false,
              obligatorio:  false
           },
           {
              label: 'Tipo de Documento',
              tipo: 'desplegable',
              lista: ['RC','TI','CC','TE','CE','NIT','PAS'],
              valor: null,
              ignorar: false,
              obligatorio:  false
           },
           {
              label: 'No documento',
              tipo: 'texto',
              valor: null,
              ignorar: false,
              obligatorio:  false
           },
           {
              label: 'Lugar de nacimiento',
              tipo: 'texto',
              valor: null,
              ignorar: false,
              obligatorio:  false
           },
           {
              label: 'Fecha de Nacimiento',
              tipo: 'fecha',
              valor: null,
              ignorar: false,
              obligatorio:  false
           }
       ]
    },
    {
       titulo: 'RESUMEN RIESGO ESTUDIO DE REFERENCIACIÓN',
       campos: [
         {
          label: 'Información Académica',
          tipo: 'desplegable',
          lista: ['Bajo', 'Medio', 'Alto'],
          valor: null,
          ignorar: false,
          obligatorio:  false
         },
         {
          label: 'Información Laboral',
          tipo: 'desplegable',
          lista: ['Bajo', 'Medio', 'Alto'],
          valor: null,
          ignorar: false,
          obligatorio:  false
         },
         {
          label: 'Información Personal ',
          tipo: 'desplegable',
          lista: ['Bajo', 'Medio', 'Alto'],
          valor: null,
          ignorar: false,
          obligatorio:  false
         },
         {
          label: 'Concepto General Asesor',
          tipo: 'textoLargo',
          valor: null,
          ignorar: false,
          obligatorio:  false,
          wx: 3
         },
       ]
    },
    {
        titulo: 'SEGURIDAD SOCIAL',
        campos: [
              {
                 label: '',
                 tipo: 'tabla',
                 ignorar: false,
                 cabeceras: [],
                 formato: [],
                 listaValores: [
                    [
                       {label: '', tipo: 'texto', valor: 'Eps',noEditable:true},
                       {label: '', tipo: 'texto', valor: null},
                       {label: '', tipo: 'texto', valor: 'Tipo de Afiliación',noEditable:true},
                       {label: '', tipo: 'desplegable', lista: ['Contributivo', 'Subsidiado','No aplica'], valor: null},
                       {label: '', tipo: 'texto', valor: 'Estado Afiliación',noEditable:true},
                       {label: '', tipo: 'desplegable', lista: ['Activo', 'Inactivo','No aplica'], valor: null},
                    ],
                    [
                       {label: '', tipo: 'texto', valor: 'Fondo Pensión',noEditable:true},
                       {label: '', tipo: 'texto', valor: null},
                       {label: '', tipo: 'texto', valor: 'Tipo de Afiliación',noEditable:true},
                       {label: '', tipo: 'desplegable', lista: ['Contributivo', 'Subsidiado','No aplica'], valor: null},
                       {label: '', tipo: 'texto', valor: 'Estado Afiliación',noEditable:true},
                       {label: '', tipo: 'desplegable', lista: ['Activo', 'Inactivo','No aplica'], valor: null},
                    ],
                    [
                       {label: '', tipo: 'texto', valor: 'Riesgos Laborales',noEditable:true},
                       {label: '', tipo: 'texto', valor: null},
                       {label: '', tipo: 'texto', valor: 'Tipo de Afiliación',noEditable:true},
                       {label: '', tipo: 'desplegable', lista: ['Contributivo', 'Subsidiado','No aplica'], valor: null},
                       {label: '', tipo: 'texto', valor: 'Estado Afiliación',noEditable:true},
                       {label: '', tipo: 'desplegable', lista: ['Activo', 'Inactivo','No aplica'], valor: null},
                    ],
                 ],
                 noEditable: true
              },
              {
                 label: 'Observaciones',
                 tipo: 'textoLargo',
                 valor: null,
                 ignorar: false,
                 obligatorio:  false
              }
        ]
    },
    {
        titulo: 'REFERENCIAS LABORALES',
        campos: [
           {
              label: '',
              tipo:'duplicaCampos',
              campo: {
                 label: '',
                 tipo: 'form',
                 ignorar: false,
                 form: {
                    titulo: '',
                    campos: [
                       {
                          label: "Empresa",
                          tipo: "texto",
                          valor: null,
                          ignorar: false
                       },
                       {
                          label: "Cargo",
                          tipo: "texto",
                          valor: null,
                          ignorar: false
                       },
                       {
                          label: "Fecha Ingreso",
                          tipo: "fecha",
                          valor: null,
                          ignorar: false
                       },
                       {
                          label: "Fecha Retiro",
                          tipo: "fecha",
                          valor: null,
                          ignorar: false
                       },
                       {
                          label: "Motivo de retiro",
                          tipo: "texto",
                          valor: null,
                          ignorar: false
                       },
                       {
                          label: "Tipo de contrato",
                          tipo: "texto",
                          valor: null,
                          ignorar: false
                       },
                       {
                          label: "Teléfono",
                          tipo: "texto",
                          valor: null,
                          ignorar: false
                       },
                       {
                          label: "Celular",
                          tipo: "texto",
                          valor: null,
                          ignorar: false
                       },
                       {
                        label: "Observación",
                        tipo: "textoLargo",
                        valor: null,
                        ignorar: false
                     },
                    ]
                 }
              },
              listadoCampos: [],
              ignorar: false
           },
           {
              label: 'Observación',
              tipo: 'textoLargo',
              valor: null,
              ignorar: false,
              obligatorio:  false
           }
        ]
    },
    {
        titulo: 'INFORMACIÓN ACADÉMICA',
        campos: [
           {
              label: '',
              tipo:'duplicaCampos',
              campo: {
                 label: '',
                 tipo: 'form',
                 ignorar: false,
                 form: {
                    titulo: '',
                    campos: [
                       {label: 'Nivel educativo', tipo: 'texto', valor: null},
                       {label: 'Título obtenido', tipo: 'texto', valor: null},
                       {label: 'Modalidad', tipo: 'texto', valor: null},
                       {label: 'Institución', tipo: 'texto', valor: null},
                       {label: 'País - Ciudad', tipo: 'texto', valor: null},
                       {label: 'Año de graduación', tipo: 'texto', valor: null},
                       {label: 'Icfes', tipo: 'texto', valor: null},
                       {label: 'Doc. de presentación', tipo: 'texto', valor: null},
                       {
                          label: 'Observación',
                          tipo: 'textoLargo',
                          valor: null,
                          ignorar: false,
                          obligatorio:  false,
                          wx: 3
                       }
                    ]
                 }
              },
              listadoCampos: [],
              ignorar: false
           },
           {
              label: '',
              tipo: 'inf',
              valor: 'Informacion Academica Sin Icfes',
              ignorar: false
           },
           {
              label: '',
              tipo:'duplicaCampos',
              campo: {
                 label: '',
                 tipo: 'form',
                 ignorar: false,
                 form: {
                    titulo: '',
                    campos: [
                       {label: 'Nivel educativo', tipo: 'texto', valor: null},
                       {label: 'Título obtenido', tipo: 'texto', valor: null},
                       {label: 'Modalidad', tipo: 'texto', valor: null},
                       {label: 'Institución', tipo: 'texto', valor: null},
                       {label: 'País', tipo: 'texto', valor: null},
                       {label: 'Año de graduación', tipo: 'texto', valor: null},
                       {
                          label: 'Observación',
                          tipo: 'textoLargo',
                          valor: null,
                          ignorar: false,
                          obligatorio:  false,
                          wx: 3
                       }
                    ]
                 }
              },
              listadoCampos: [],
              ignorar: false
           },
        ]
    },
    {
        titulo: 'REFERENCIA PERSONAL',
        campos: [
          {
            label: '',
            tipo:'duplicaCampos',
            campo: {
                tipo: 'form',
                form: {
                  titulo: '',
                  campos: [
                      {
                        label: 'Nombres y Apellidos',
                        tipo: 'texto',
                        valor: null,
                        ignorar: false,
                        obligatorio:  false
                      },
                      {
                        label: 'Ciudad',
                        tipo: 'texto',
                        valor: null,
                        ignorar: false,
                        obligatorio:  false
                      },
                      {
                        label: 'Teléfono',
                        tipo: 'texto',
                        valor: null,
                        ignorar: false,
                        obligatorio:  false
                      },
                      {
                        label: 'Ocupación',
                        tipo: 'texto',
                        valor: null,
                        ignorar: false,
                        obligatorio:  false
                      },
                      {
                        label: 'Empresa',
                        tipo: 'texto',
                        valor: null,
                        ignorar: false,
                        obligatorio:  false
                      },
                      {
                        label: 'Parentesco',
                        tipo: 'texto',
                        valor: null,
                        ignorar: false,
                        obligatorio:  false
                      },
                      {
                        label: 'Concepto',
                        tipo: 'textoLargo',
                        valor: null,
                        ignorar: false,
                        obligatorio:  false,
                        wx: 3
                      },
                      {
                        label: "Observación",
                        tipo: "textoLargo",
                        valor: null,
                        ignorar: false
                    },
                  ]
                }
            },
            listadoCampos: [],
            ignorar: false
          }
        ]
    },
    {
       titulo: 'REFERENCIA FAMILIAR',
       campos: [
          {
             label: 'Nombres y Apellidos',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
          },
          {
             label: 'Ciudad',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
          },
          {
             label: 'Teléfono',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
          },
          {
             label: 'Ocupación',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
          },
          {
             label: 'Empresa',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
          },
          {
             label: 'Parentesco',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
          },
          {
             label: 'Concepto',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio:  false,
             wx: 3
          },
          {
              label: 'FIRMA',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false
           }
        ]
    },
    {
       titulo: 'DOCUMENTOS',
       campos: [
          {
            tipo: 'saltoPagina'
          },
          {
             label: 'AUTORIZACIÓN',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'CÉDULA',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'PASAPORTE',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'DIPLOMA BACHILLER',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'ICFES',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'ACTA DE GRADO BACHILLERATO',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'CERTIFICACIÓN LABORAL',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'OTROS SOPORTES',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'CONTRATO APRENDIZAJE',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          }
       ]
    }
  ]


export default(referenciacion)
