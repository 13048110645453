class GenerCampos {
    
    constructor() {
        this.miJson = {}
    }

    darCampoNumero(label = '', valor = null, idCampo = -1, simbolo = '', noEditable = false, ignorar = false) {
        this.miJson.tipo = 'numero';
        this.miJson.label = label;
        this.miJson.valor = valor;
        if (idCampo > 0) {
            this.miJson.idCampo = idCampo;
        }
        if (simbolo !== '') {
            this.miJson.simbolo = simbolo;
        }
        this.miJson.ignorar = ignorar;
        this.miJson.noEditable = noEditable;

        return this;
    }
    
    darCampoTexto(label = '', valor = null, idCampo = -1, noEditable = false, ignorar = false) {
    
        this.miJson.tipo = 'texto';
        this.miJson.label = label;
        this.miJson.valor = valor;
        if (idCampo > 0) {
            this.miJson.idCampo = idCampo;
        }
        this.miJson.ignorar = ignorar;
        this.miJson.noEditable = noEditable;
        return this;
    }
    darCampoDependiente(label = '', valor = null, idDependiente, idCampo = -1, ignorar = false) {

        this.miJson.tipo = 'texto';
        this.miJson.label = label;
        this.miJson.valor = valor;
        if (idCampo > 0) {
            this.miJson.idCampo = idCampo;
        }
        this.miJson.ignorar = ignorar;
        this.miJson.noEditable = true;
        this.miJson.idDependiente = idDependiente;
        return this;
    }

    darCampoDesplegable(label = '', valor = null, idCampo = -1, lista = [], ignorar = false) {
        this.miJson.tipo = 'desplegable';
        this.miJson.label = label;
        this.miJson.valor = valor;
        if (idCampo > 0) {
            this.miJson.idCampo = idCampo;
        }
        this.miJson.lista = lista;
        this.miJson.ignorar = ignorar;
        return this;
    }
    
    darCampoTabla(label = '', cabeceras = [], formatoListaCampos = [], listaValores = [[], []], noEditable = true, ignorar = false) {
        
        this.miJson.label = label;
        this.miJson.tipo = 'tabla';
        this.miJson.cabeceras = cabeceras;
        this.miJson.formato = formatoListaCampos;
        this.miJson.listaValores = listaValores;
        this.miJson.noEditable = noEditable;
        this.miJson.ignorar = ignorar;

        return this;
    }

    darCampoTextoLargo(label = '', valor = null, idCampo = -1, noEditable = false, ignorar = false) {
        this.miJson.tipo = 'textoLargo';
        this.miJson.label = label;
        this.miJson.valor = valor;
        if (idCampo > 0) {
            this.miJson.idCampo = idCampo;
        }
        this.miJson.ignorar = ignorar;
        this.miJson.noEditable = noEditable;
        return this;
    }

    darCampoCompletarTexto(label = '', listatextoCampos = [], ignorar = false) {
        this.miJson.tipo = 'completarTexto';
        this.miJson.ignorar = ignorar;
        this.miJson.secuencias = [];

        listatextoCampos.forEach(linea => {
            if (typeof linea === 'string') {
                if (linea === 'br') {
                    this.miJson.secuencias.push({ tipo: 'br' });
                } else {
                    this.miJson.secuencias.push({ tipo: 'texto', texto: linea });
                }
            } else {
                this.miJson.secuencias.push({ tipo: 'campo', campo: linea });
            }
        });

        return this;
    }
    darCampoValoraSumar(label,idColumnaSumar,valor){
        this.miJson.label = label
        this.miJson.tipo = 'numero'
        this.miJson.sumatorio = true
        this.miJson.columnaSumatoria = idColumnaSumar
        this.miJson.valor = valor
        this.miJson.ignorar = false
        return this
    }
    darCampoResultadoSuma(label,idCamposSumar=-1){
        
        this.miJson.label= label
        this.miJson.tipo= 'numero'
        this.miJson.noEditable = true
        this.miJson.resultadoSumatoria= idCamposSumar
        this.miJson.valor= null
        return this
    }
    darCampoForm(titulo = '', campos = []) {
        this.miJson.label = '';
        this.miJson.tipo = 'form';
        this.miJson.form = { titulo, campos };
        this.miJson.ignorar = false;
        return this;
    }
    darCampoInfo(mensaje){
        this.miJson.label= ''
        this.miJson.tipo='inf'
        this.miJson.valor= mensaje
        this.miJson.ignorar= false
        return this
    }
    darCampoFirma() {
        this.miJson.label = 'FIRMA'
        this.miJson.tipo ='archivo'
        this.miJson.permitido = 'image/png, image/jpeg'
        this.miJson.valor = null
        this.miJson.ignorar = false
        this.miJson.obligatorio = false
        return this;
    }
    darCampoEntrevista(mostrarPregunta){
        this.miJson.mostrarPregunta = mostrarPregunta
        this.miJson.label = '',
        this.miJson.tipo = 'entrevista',
        this.miJson.preguntas= []
        this.miJson.ignorar= false
        return this;
    }
    newPregunta(pregunta,valor){
        if (this.miJson.tipo !== 'entrevista') {
            throw new Error("El tipo debe ser 'entrevista' para agregar una nueva pregunta");
        }
        this.miJson.preguntas.push({ pregunta: pregunta, valor: valor });
        return this;
    }
    textoPegados(pegar){
        if (this.miJson.tipo !== 'entrevista') {
            throw new Error("El tipo debe ser 'entrevista' para agregar una nueva pregunta");
        }
        this.miJson.pegarTexto = pegar
        return this
    }

    setLabel(label) {
        this.miJson.label = label;
        return this;
    }

    setTipo(tipo) {
        this.miJson.tipo = tipo;
        return this;
    }

    setValor(valor) {
        this.miJson.valor = valor;
        return this;
    }

    setIdCampo(idCampo) {
        if (idCampo > 0) {
            this.miJson.idCampo = idCampo;
        }
        return this;
    }

    setLista(lista) {
        this.miJson.lista = lista;
        return this;
    }

    setCabeceras(cabeceras) {
        this.miJson.cabeceras = cabeceras;
        return this;
    }

    setFormato(formato) {
        this.miJson.formato = formato;
        return this;
    }

    setListaValores(listaValores) {
        this.miJson.listaValores = listaValores;
        return this;
    }

    setNoEditable(noEditable) {
        this.miJson.noEditable = noEditable;
        return this;
    }

    setIgnorar(ignorar) {
        this.miJson.ignorar = ignorar;
        return this;
    }
    setSimbolo(simbolo){
        this.miJson.simbolo = simbolo
        return this
    }
    setWx(wx){
        this.miJson.wx = wx
        return this
    }
    mostrarJson(){
        console.log(this.toJSON())
        return this
    }
    toJSON() {
        const copyJson = { ...this.miJson };
        this.miJson = {}; // Limpiar el objeto después de convertirlo en JSON
        return copyJson;
    }
}

export default GenerCampos

