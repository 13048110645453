<template>
  <FullBoxVue class="shadowHover">
    <h1>Ver empresas</h1>
    <br />
    <router-link :to="'/registerCliente'">
      <v-btn color="primary">Agregar nueva empresa</v-btn>
    </router-link>
    <br><br>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="peticiones"
        :search="search"
        @click:row="seleccionarPeticion"
      ></v-data-table>

    </v-card>
  </FullBoxVue>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";

import { getEmpresas } from "../api";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Estado Empresa", value: "estado" },
        { text: "Razón social", value: "razonsocial" },
        { text: "Tipo de documento", value: "tipoDocumento" },
        { text: "Documento", value: "documento" },
        { text: "Fecha de corte", value: "fechaCorte" },
        { text: "Fecha de remisión", value: "fechaRemision" },
      ],
      peticiones: [],
      idSolicitudSeleccionada: {},
      infoSolicitudRef: null
    };
  },
  components: {
    FullBoxVue,
  },
  methods: {
    seleccionarPeticion(option) {
      this.idSolicitudSeleccionada = option;
      this.$router.push({ path: '/empresa/'+this.idSolicitudSeleccionada.id}).catch(()=>{});
      //this.$swal('Hello Vue world!!!');
    },
  },
  mounted() {
    getEmpresas().then(
      function (response) {
        this.peticiones = response.data;
      }.bind(this)
    );
  },
};
</script>

<style></style>
