<template>
  <div class="contenedor">
    <div class="elementos-container contenedor" style="padding: 10px;">
      <span v-for="(secuencia, i) in secuencias" :key="i" class="contenedor">
        <template v-if="secuencia.tipo === 'texto'">{{ secuencia.texto }}</template>
        <template v-else-if="secuencia.tipo === 'campo'">
          <template v-if=" secuencia.campo.wx === undefined">
            <div
              v-if="secuencia.campo.tipo !== 'textoLargo' && secuencia.campo.tipo !== 'completarTexto'"
              class="campo"
            >
              <RetornoCampo :campo="secuencia.campo" class="campo"></RetornoCampo>
            </div>
            <div
              v-else
              class="campo"
            >
              <RetornoCampo :campo="secuencia.campo" class="campo campo-ancho"></RetornoCampo>
            </div>
          </template>
          <template v-else-if="secuencia.campo.wx === 1" >
            <QuarterBox class="contenedor box">
              <RetornoCampo :campo="secuencia.campo" ></RetornoCampo>
            </QuarterBox>
          </template>
          <template v-else-if="secuencia.campo.wx === 2">
            <MiddleBox class="contenedor box">
              <RetornoCampo :campo="secuencia.campo"></RetornoCampo>
            </MiddleBox>
          </template>
          <template v-else-if="secuencia.campo.wx === 3">
            <div
              v-if="secuencia.campo.tipo !== 'textoLargo' && secuencia.campo.tipo !== 'completarTexto'"
              class="campo3"
            >
            <FullBox class="contenedor box">
              <RetornoCampo :campo="secuencia.campo"></RetornoCampo>
            </FullBox>
            </div>
            <div
              v-else
              class="campo3"
            >
            <FullBox class="contenedor box">
              <RetornoCampo :campo="secuencia.campo"></RetornoCampo>
            </FullBox>
            </div>
            
          </template>
        </template>
        <template v-else-if="secuencia.tipo === 'br' "> 
          <br>
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import FullBox from './FullBox.vue'
import MiddleBox from './MiddleBox.vue'
import QuarterBox from './QuarterBox.vue'

export default {
  components: {FullBox, MiddleBox, QuarterBox },
  props: {
    secuencias: Array
  }
};
</script>

<style scoped>
.contenedor {
  text-align: left;
  background-color: rgb(242, 242, 255);
}

.elementos-container {
  white-space: normal; /* Permite saltos de línea cuando el contenido es demasiado largo */
}

.campo {
  display: inline-block;
  margin-right: 2px;
  margin-left: 2px;
  max-height: 50px;
}

.campo-ancho {
  white-space: normal; /* Para manejar contenido extenso sin romper la línea */
  max-width: 180px;
  width: 100%;
}
.campo3{
  display: inline-block;
  width: 100%;
  margin-right: 2px;
  margin-left: 2px;
}
.box {
  border-radius: 0%;
}
</style>
