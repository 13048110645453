import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FormBuilderView from '../views/FormBuilderView.vue'
import RegisterUserView from '../views/RegisterUserView.vue'
import PeticionesView from '../views/PeticionesView.vue'
import infoSolicitudView from '../views/infoSolicitudView.vue'
import CertificadosSolicitudesFuncionarioView from '../views/CertificadosSolicitudesFuncionarioView.vue'
import subirDatosEvaluadoView from '../views/subirDatosEvaluadoView.vue'
import cargaMasivaSolicitudesView from '../views/cargaMasivaSolicitudesView.vue'
import UsuariosView from '../views/UsuariosView.vue'
import UsuarioView from '../views/UsuarioView.vue'
import opcionesUserView from '../views/opcionesUserView.vue'
import modificarServiciosView from '../views/modificarServiciosView.vue'
import ServicioView from '../views/ServicioView.vue'
import RegisterClienteView from '../views/RegisterClienteView.vue'
import empresasView from '../views/empresasView.vue'
import EmpresaView from '../views/EmpresaView.vue'
import EmpresaAdminView from '../views/EmpresaAdminView.vue'
import actualizarArchivosEmpresa from '../views/actualizarArchivosEmpresa.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/nuevaSolicitud',
    name: 'Form Builder',
    component: FormBuilderView
  },
  {
    path: '/registerUser',
    name: 'Registrar usuario',
    component: RegisterUserView
  },
  {
    path: '/peticiones',
    name: 'Ver peticiones',
    component: PeticionesView
  },
  {
    path: '/peticion/:id',
    name: 'Petición',
    component: infoSolicitudView
  },
  {
    path: '/descargarCertificados',
    name: 'Petición',
    component: CertificadosSolicitudesFuncionarioView
  },
  {
    path: '/candidatoUpload/:id',
    name: 'candidatoUpload',
    component: subirDatosEvaluadoView
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: UsuariosView
  },
  {
    path: '/usuario/:id',
    name: 'usuario',
    component: UsuarioView
  },
  {
    path: '/cargaMasivaSolicitudes',
    name: 'cargaMasivaSolicitudes',
    component: cargaMasivaSolicitudesView
  },
  {
    path: '/opcionesUser',
    name: 'opcionesUsuario',
    component: opcionesUserView
  },
  {
    path: '/restorePassword'
  },
  {
    path: '/modificarServicios',
    name: 'modificarServicio',
    component: modificarServiciosView
  },
  {
    path: '/servicio/:id',
    name: 'servicio',
    component: ServicioView
  },
  {
    path: '/registerCliente',
    name: 'registerCliente',
    component: RegisterClienteView
  },
  {
    path: '/empresas',
    name: 'empresas',
    component: empresasView
  },
  {
    path: '/empresa/:id',
    name: 'empresa',
    component: EmpresaView
  },
  {
    path: '/empresaAdmin/',
    name: 'empresaAdmin',
    component: EmpresaAdminView
  },
  {
    path: '/archivosEmpresa/',
    name: 'archivosEmpresa',
    component: actualizarArchivosEmpresa
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
