<template>
  <div :key="componentKey">
    <FullBoxVue class="shadowHover">
      <div>
        <h1>Subir los archivos requeridos</h1>
        <p>Por favor suba los archivos que se requieren</p>
      </div>
    </FullBoxVue>

    <FullBoxVue >
      <MiddleBox class="shadowHover" v-for="(archivo, index) in archivosRequeridos" :key="index">
        <h2>{{ archivo.nombre }}</h2>
        <input type="file" @change="onFileChange($event, index)" />
        &nbsp;
        <v-btn color="success" @click="subirArchivo(index, archivo.nombre)"
          >Subir archivo</v-btn
        >

        <v-container>
          <h2>Archivos subidos</h2>
          <br /><br />
          <v-data-table :headers="headers" :items="archivo.archivosSubidos">
            <template v-slot:item.actions="{ item }">
              <div class="text-truncate">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  @click="descargarArchivosTable(item)"
                >
                  mdi-download
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  color="error"
                  @click="eliminarArchivoTable(item)"
                >
                  mdi-delete
                </v-icon>
              </div>
            </template>
          </v-data-table>


        </v-container>
        <br />
        <hr />
        <br />
      </MiddleBox>
    </FullBoxVue>

  </div>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";
import MiddleBox from "@/components/static/MiddleBox.vue";
import {
  getArchivosEmpresa,
  subirArchivosEmpresa,
  downloadFile,
  eliminarArchivosEmpresa,
  updateObservacionesArchivoInd,
} from "@/api";
export default {
  data() {
    return {
      idSolicitudRef: "",
      infoSolicitud: {},
      infoSolicitudCandidatos: {},
      componentKey: 1,
      estadoSolicitud: null,
      estados: null,
      idSolicitud: this.$route.params.id,
      archivosSubidos: [],
      Images: null,
      valido: false,
      userSearchSelected: [],
      userSearch: [],
      datosWCandidato: {},
      archivosRequeridos: [
        {nombre: "Cámara de comercio", archivosSubidos:""},
        {nombre: "RUT", archivosSubidos:""},
        {nombre: "Cédula representante legal principal y suplente", archivosSubidos:""},
        {nombre: "Estados financieros", archivosSubidos:""},
        {nombre: "Acuerdo de confidencialidad", archivosSubidos:""}
      ],
      archivosSeleccionados: [],
      headers: [
        { text: "Id", value: "id" },
        { text: "Nombre de archivo", value: "nombreArchivo" },
        { text: "Fecha de subida", value: "creado" },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },

  methods: {
    eliminarArchivoTable(data){
      this.eliminarArchivo(data.id)
    },

    descargarArchivosTable(data){
      this.descargarArchivos(data.archivo, data.nombreArchivo)
    },


    actualizarComponente() {
      this.componentKey += 1;
    },

    actualizarComentariosArchivo(id, comentarios) {
      let bodySol = {
        observacionesAnalistaProveedor: comentarios,
        idSolicitud: id,
      };
      updateObservacionesArchivoInd(bodySol);
    },

    onFileChange(event, index) {
      let file = event.target.files[0];
      this.archivosSeleccionados[index] = file;
    },



    realizarConsultaArchivos() {
      for (let i = 0; i < this.archivosRequeridos.length; i++) {
        this.archivosSeleccionados.push({});
      }
      for (let i = 0; i < this.archivosRequeridos.length; i++) {
        getArchivosEmpresa(
          this.$dataUser[0].id,
          this.archivosRequeridos[i].nombre
        ).then(
          function (response) {
            this.archivosRequeridos[i].archivosSubidos = response.data

            this.actualizarComponente();
          }.bind(this)
        );
      }
    },

    eliminarArchivo(id) {
      eliminarArchivosEmpresa(id).then(
        function (response) {
          window.location.reload();
        }.bind(this)
      );
    },

    realizarConsulta() {
      this.realizarConsultaArchivos();
    },

    subirArchivo(index, descripcion) {
      const formData = new FormData();
      formData.append("file", this.archivosSeleccionados[index]);
      formData.append("descripcion", descripcion);

      subirArchivosEmpresa(formData).then(
        function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Solicitud enviada exitosamente",
          });
          window.location.reload();
        }.bind(this)
      );
    },

    descargarArchivos(filepath, fileName) {
      downloadFile(filepath).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
    },
  },

  mounted() {
    this.realizarConsulta();
  },

  components: {
    FullBoxVue,
    MiddleBox,
  },
};
</script>

<style></style>
