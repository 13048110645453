<template>
  <div class="completo">
    <div class="fila" v-for="(campoN, i) in  campo.listadoCampos" :key="i">
      <RetornoCampo 
      class="campo"
      :campo="campoN"
      :idIteracion="idIteracion + i.toString()"
      :i="i"
      :idForm="idForm"
      :idSolicitud="idSolicitud"
      :label="campoN.label"></RetornoCampo>
      <v-card class="delete">
        <v-btn class="red white--text intoDelete" @click="eliminarCampo(i)"><v-icon>mdi-delete</v-icon></v-btn>
      </v-card>
      <hr>
    </div>
    <v-card class="menuA">
      <v-btn  class="green white--text" @click="duplicar()"> <v-icon >mdi-plus</v-icon></v-btn>
    </v-card>
  </div>
</template>
<script>

export default({
  name: 'DuplicaCampos',
  props: {
    campo: {
      default: ""
    },
    label: {
      default: "",
    },
    idForm: {
      default: "",
    },
    idIteracion: {
      default: "",
    },
    idSolicitud: {
      default: "",
    }
  },
  methods: {
    duplicar: function() {
      const nuevo = JSON.parse(JSON.stringify(this.campo.campo));
      this.campo.listadoCampos.push(nuevo)
    },
    eliminarCampo: function(i) {
      if (i >= 0 && i < this.campo.listadoCampos.length) {
        this.campo.listadoCampos.splice(i, 1);
      }
    }
  }
})

</script>
<style scoped>
.completo{
  display: block;
}
.campo {
  display: inline-block;
  width: 85%;
}
.delete {
  vertical-align: top;
  display: inline-block;
  width: 14%;
  text-align: center;
}
.intoDelete{
  margin-top: 50%;
  margin-bottom: 50%;
}
.menuA{
  display: block;
}
</style>