<template>
  <FullBoxVue class="shadowHover">
    <h1>Actualizar contraseña</h1>
    <br />
    <br><br>
    <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="contrasenaAntigua"
                  label="Ingrese contraseña antigua"
                  type="password"
                  :rules="rulesRequired"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field
                  v-model="contrasenaNueva"
                  :rules="rulesRequired"
                  label="Ingrese nueva contraseña"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="contrasenaNuevaValidacion"
                  :rules="rulesRequired"
                  label="Vuelva a ingresar nueva contraseña"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-btn color="primary" @click="actualizarContrasena">
              Actualizar Contraseña
            </v-btn>
        </v-form>
  </FullBoxVue>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";
import { updatePasswordWithOld } from "@/api"

export default {
  components: {
    FullBoxVue,
  },
  data() {
    return {
      contrasenaAntigua: "",
      contrasenaNueva: "",
      contrasenaNuevaValidacion: ""
    };
  },

  methods: {
    actualizarContrasena() {
      const validateForm = this.$refs.form.validate();
      if (validateForm) {
        this.loading = true;
        this.alertError = false;
        updatePasswordWithOld({
          contrasenaAntigua: this.contrasenaAntigua,
          contrasenaNueva: this.contrasenaNueva,
          contrasenaNuevaValidacion: this.contrasenaNuevaValidacion
        })
          .then(
            function (response) {
              if(response.data == ""){
              const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "success",
                title: "Contraseña cambiada correctamente, por favor, vuelva a iniciar sesión.",
              });
              localStorage.removeItem("userTkn");
              setTimeout('document.location.reload()',1000);
            }else{
              const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "error",
                title: "Contraseñas no coinciden",
              });
            }
            }.bind(this)
          )
          .catch(
            function (error) {
              const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "error",
                title: "Error al cambiar la contraseña.",
              });
            }.bind(this)
          );
      }
    },
  }
}
</script>

<style>

</style>
