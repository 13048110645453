<template>
  <FullBoxVue class="shadowHover">
    <div class="formContent" :key="componentKey">
      <h1>Actualizar empresa</h1>
      <br />
      <hr />
      <br />
      <BlitzForm
        :schema="schemaRegister"
        v-model="valuesRegister"

        gridGap="0"
        labelPosition="left"
      />
      <v-switch v-model="valuesRegister.ordenCompra" :label="`Orden de compra`" style="width: 300px; float: left;"></v-switch>
      <v-switch v-model="valuesRegister.remision" :label="`Requiere remisión`" style="width: 300px; float: left;"></v-switch>

      <v-select
          :items="tiemposDePago"
          v-model="valuesRegister.tiempoPago"
          label="Tiempo de pago"
          outlined
          style="width: 200px; float: left;"
        ></v-select>
      <!-- <div v-for="(servicio, index) in servicios" :key="index">
        <v-text-field
          @change="updateCosto($event, index)"
          v-model="costosServicios[index].costo"
          :label="'Costo de ' + servicio.NOMBRE_SUBSERVICIO"
          type="number"
          class="tirthyPercent"
        ></v-text-field>
      </div>
      <br /> -->
      <br><br><br><br><br>
      <h2>Costos de subservicios</h2>
      <p>
        Para habilitar un subservicio escriba el costo correspondiente, para
        deshabilitarlo, deje la casilla del costo vacía o con valor 0
      </p>
      <v-autocomplete
        v-model="preServiciosSelected"
        :items="servicios"
        color="blue-grey lighten-2"
        class="seleccionSubservicios"
        label="Seleccionar subservicio"
        item-text="NOMBRE_SUBSERVICIO"
        item-value="ID_SUBSERVICIO"
        chips
        closable-chips
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="remove(data.item)"
          >
            {{ data.item.NOMBRE_SUBSERVICIO }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-content>
              <v-list-item-title
                v-html="data.item.NOMBRE_SUBSERVICIO"
              ></v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <v-text-field
        label="Precio"
        prefix="$"
        type="number"
        v-model="precio"
        outlined
        class="campoPrecio"
      ></v-text-field>

      <v-text-field
        label="Observaciones"
        type="text"
        v-model="observaciones"
        outlined
        class="campoObservaciones"
      ></v-text-field>

      <v-btn
        color="primary"
        @click="agregarPrecio"
        class="botonAgregarPrecio"
        :disabled="preServiciosSelected == 0 || precio == null || precio == 0"
      >
        Agregar
      </v-btn>
    </div>

    <br>

    <div>
      <h2>Costos actuales</h2>
      <br />
      <div v-for="(objeto, index) in valuesRegister.costos" :key="index">
        <div class="seleccionSubservicios">
          {{ objeto.NOMBRE_SUBSERVICIO }}
        </div>
        <v-text-field
          label="Precio"
          prefix="$"
          type="number"
          v-model="objeto.costo"
          outlined
          class="campoPrecio"
        ></v-text-field>

        <v-text-field
          label="Observaciones"
          type="text"
          v-model="objeto.observaciones"
          outlined
          class="campoObservaciones"
        ></v-text-field>

        <v-btn
          color="primary"
          @click="eliminarPrecio(index)"
          class="botonAgregarPrecio"
        >
          Eliminar
        </v-btn>
      </div>
    </div>

    <div style="width: 100% !important; display: flow-root" >
      <v-btn color="primary"  @click="actualizarEmpresa">
        Actualizar
      </v-btn>
    </div>
    <br />

    <h1>Usuarios de empresa</h1>
    <div>
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on">
            Crear nuevo usuario principal
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Registrar usuario principal
          </v-card-title>

          <v-card-text>
            <BlitzForm
              :schema="schemaRegisterUsuario"
              v-model="valuesRegisterUsuario"
              :key="componentKey"
              gridGap="0"
              labelPosition="left"
            />

            <!-- <v-select
              v-model="valuesRegisterUsuario.serviciosAsociados"
              :items="serviciosDefault"
              item-text="nombre"
              item-value="id"
              label="Servicios disponibles"
              multiple
            ></v-select> -->

            <!-- <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="valuesRegister.fechaCorte"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  v-model="valuesRegister.fechaCorte"
                  chips
                  small-chips
                  label="Fecha de corte"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-combobox>
              </template>
              <v-date-picker
                v-model="valuesRegister.fechaCorte"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(valuesRegister.fechaCorte)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu> -->
            <v-btn color="primary" dark @click="registrarUsuarioAdmin">
              Crear usuario principal
            </v-btn>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <br />
    <div>
      <v-dialog v-model="dialog2" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on">
            Crear nuevo SubUsuario
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Registrar usuario
          </v-card-title>

          <v-card-text>
            <BlitzForm
              :schema="schemaRegisterUsuario"
              v-model="valuesRegisterUsuario"
              :key="componentKey"
              gridGap="0"
              labelPosition="left"
            />

            <!-- <v-select
              v-model="valuesRegisterUsuario.serviciosAsociados"
              :items="serviciosDefault"
              item-text="nombre"
              item-value="id"
              label="Servicios disponibles"
              multiple
            ></v-select> -->

            <!-- <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="valuesRegister.fechaCorte"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  v-model="valuesRegister.fechaCorte"
                  chips
                  small-chips
                  label="Fecha de corte"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-combobox>
              </template>
              <v-date-picker
                v-model="valuesRegister.fechaCorte"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(valuesRegister.fechaCorte)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu> -->
            <v-btn color="primary" dark @click="registrarSubUsuario">
              Crear subusuario
            </v-btn>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-data-table
      :headers="headers"
      :items="peticiones"
      :search="search"
      @click:row="seleccionarPeticion"
    ></v-data-table>
  </FullBoxVue>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";
import { BlitzForm } from "blitzar";
import searchUserMultipleVue from "../components/searchUserMultiple.vue";
import Vue from "vue";

import {
  VTextField,
  VRadioGroup,
  VRadio,
  VSlider,
  VCheckbox,
  VSwitch,
  VSelect,
} from "vuetify/lib";
// All components that are used in the form need to be globally registered.
Vue.component("VTextField", VTextField);
Vue.component("VRadioGroup", VRadioGroup);
Vue.component("VRadio", VRadio);
Vue.component("VSlider", VSlider);
Vue.component("VCheckbox", VCheckbox);
Vue.component("VSwitch", VSwitch);
Vue.component("VSelect", VSelect);

import {
  getEmpresa,
  getDocumento,
  updateEmpresa,
  postSubUsers,
  getAllSubServicio,
  getAllUsersEmpresa,
} from "@/api";

const valuesRegisterdef = {
  nombre: "",
  tipoDocumento: "",
  contacto: "",
  correo: "",
  documento: "",
  rol: 8,
  fechaCorte: "",
  costos: [],
};

const tenPercent = "width: 18%;max-width: 18%;margin: 1%;";
const tenPercentWFloat = "width: 18%;max-width: 18%;margin: 1%; float: left;";

const twentyPercent = "width: 18%;max-width: 18%;margin: 1%;";
const twentyPercentWFloat =
  "width: 18%;max-width: 18%;margin: 1%; float: left;";

const thirtyPercent = "width: 28%;max-width: 28%;margin: 1%;";
const thirtyPercentWFloat =
  "width: 28%;max-width: 28%;margin: 1%; float: left;";

const thirtytreePercent = "width: 32.333%;max-width: 32.333%;margin: 1%;";
const thirtytreePercentWFloat =
  "width: 31.333%;max-width: 31.333%;margin: 1%; float: left;";

const fourtyPercent = "width: 38%;max-width: 38%;margin: 1%;";
const fourtyPercentWFloat =
  "width: 38%;max-width: 38%;margin: 1%; float: left;";

const fiftyPercent = "width: 48%;max-width: 48%;margin: 1%;";
const fiftyPercentWFloat = "width: 48%;max-width: 48%;margin: 1%; float: left;";

const sixtyPercent = "width: 58%;max-width: 58%;margin: 1%;";
const sixtyPercentWFloat = "width: 58%;max-width: 58%;margin: 1%; float: left;";

const seventyPercent = "width: 68%;max-width: 68%;margin: 1%;";
const seventyPercentWFloat =
  "width: 68%;max-width: 68%;margin: 1%; float: left;";

const hundredPercent = "width: 98%;max-width: 98%;margin: 1%;";
const hundredPercentWFloat =
  "width: 98%;max-width: 98%;margin: 1%; float: left;";

export default {
  data() {
    return {
      tiemposDePago: [
        "30",
        "60",
        "90"
      ],
      idSolicitud: this.$route.params.id,
      valuesRegister: {
        nombre: "",
        tipoDocumento: "",
        documento: "",
        contacto: "",
        correo: "",
        representante_legal: "",
        direccion: "",
        fechaCorte: "",
        fechaRemision: "",
        rol: 8,
        userSearchSelected: [],
        costos: [],
        observaciones: ""
      },
      menu: false,
      serviciosDefault: [],
      schemaRegister: [
        {
          id: "razonsocial",
          component: "VTextField",
          label: "Razón Social",
          style: fiftyPercentWFloat,
        },
        //Id Tipo Documento
        {},
        {
          id: "documento",
          component: "VTextField",
          label: "No. Cedula / NIT",
          style: thirtyPercent,
        },
        {
          id: "direccion",
          component: "VTextField",
          label: "Dirección de empresa",
          style: thirtytreePercentWFloat,
        },
        {
          id: "fechaCorte",
          component: "VTextField",
          type: "number",
          label: "Día de Corte",
          style: thirtytreePercentWFloat,
        },
        {
          id: "fechaRemision",
          component: "VTextField",
          type: "number",
          label: "Día de Remisión",
          style: thirtytreePercentWFloat,
        },
        {
          id: "observaciones",
          component: "VTextField",
          label: "Observaciones",
          style: hundredPercent,
        },
        {
          id: "correoFacturacionElectronica",
          component: "VTextField",
          label: "Correo Facturación electrónica",
          style: fiftyPercentWFloat,
        },
        {
          id: "contactocontactoCarteraOPago",
          component: "VTextField",
          label: "Contacto cartera o pagos",
          style: fiftyPercentWFloat,
        },
        {
          id: "nombrecontactoCarteraOPago",
          component: "VTextField",
          label: "Nombre contacto cartera o pagos",
          style: fiftyPercentWFloat,
        },
      ],
      componentKey: 0,
      costo: 0,
      userSearch: [],
      servicios: [],
      costosServicios: [],
      headers: [
        { text: "Id", value: "id" },
        { text: "Estado Cliente", value: "estado" },
        { text: "Nombre Cliente", value: "nombre" },
        { text: "Rol", value: "rol" },
        { text: "Código de cliente", value: "cod_cliente" },
        { text: "Correo", value: "correo" },
        { text: "Documento", value: "documento" },
        { text: "Tipo de documento", value: "tipo_documento" },
      ],
      schemaRegisterUsuario: [
        {
          id: "nombre",
          component: "VTextField",
          label: "Nombre",
          style: hundredPercent,
        },
        //Id Tipo Documento
        {},
        {
          id: "documento",
          component: "VTextField",
          label: "Documento",
          style: hundredPercent,
        },
        {
          id: "contacto",
          component: "VTextField",
          label: "Teléfono",
          style: hundredPercent,
        },
        {
          id: "correo",
          component: "VTextField",
          label: "Correo",
          style: hundredPercent,
        },
        //Info contacto
        {},
        //Tipo de rol
        {},
      ],
      serviciosDefault: [],
      valuesRegisterUsuario: {
        nombre: "",
        tipoDocumento: "",
        documento: "",
        contacto: "",
        correo: "",
        representante_legal: "",
        direccion: "",
        fechaCorte: "",
        fechaRemision: "",
        userSearchSelected: [],
        costos: [],
      },
      peticiones: [],
      search: "",
      dialog: false,
      dialog2: false,
      preServiciosSelected: 0,
      precio: 0,
      observaciones: "",
    };
  },

  methods: {
    agregarPrecio() {
      for (let i in this.valuesRegister.costos) {
        if (this.valuesRegister.costos[i].ID_SUBSERVICIO == this.preServiciosSelected) {
          this.valuesRegister.costos[i] = {
            ID_SUBSERVICIO: this.preServiciosSelected,
            NOMBRE_SUBSERVICIO: this.getNombreServicio(this.preServiciosSelected),
            costo: this.precio,
            observaciones: this.observaciones,
          };
          this.recargarFormulario();
          return;
        }
      }

      if(this.valuesRegister.costos == null){
        this.valuesRegister.costos = []
      }

      this.valuesRegister.costos.push({
        ID_SUBSERVICIO: this.preServiciosSelected,
        NOMBRE_SUBSERVICIO: this.getNombreServicio(this.preServiciosSelected),
        costo: this.precio,
        observaciones: this.observaciones,
      });
    },

    getNombreServicio(id) {
      for (let i in this.servicios) {
        if (this.servicios[i].ID_SUBSERVICIO == id) {
          return this.servicios[i].NOMBRE_SUBSERVICIO;
        }
      }
    },

    postUser() {
      postSubUsers(this.valuesRegisterUsuario)
        .then(
          function (response) {
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            if (response.data == "") {
              Toast.fire({
                icon: "success",
                title: "Usuario registrado exitosamente",
              });
              this.valuesRegister = valuesRegisterdef;
              this.recargarFormulario();
              window.location.reload();
            } else {
              Toast.fire({
                icon: "error",
                title:
                  "Error al registrar el usuario, correo no válido y/o ya usado.",
              });
            }
          }.bind(this)
        )
        .catch(function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title:
              "Error al registrar el usuario, correo no válido y/o ya usado.",
          });
        });
    },

    registrarUsuarioAdmin() {
      this.valuesRegisterUsuario.rol = 8;
      this.valuesRegisterUsuario.empresa = this.idSolicitud;
      this.postUser();
    },

    registrarSubUsuario() {
      this.valuesRegisterUsuario.rol = 2;
      this.valuesRegisterUsuario.empresa = this.idSolicitud;
      this.postUser();
    },

    seleccionarPeticion(option) {
      this.idSolicitudSeleccionada = option;
      this.$router
        .push({ path: "/usuario/" + this.idSolicitudSeleccionada.id })
        .catch(() => {});
      //this.$swal('Hello Vue world!!!');
    },
    myEventHandler(e) {
      if (e.currentTarget.innerWidth <= 600) {
        let classNames = document.getElementsByClassName("blitz-field");
        for (let i = 0; i < classNames.length; i++) {
          document.getElementsByClassName("blitz-field")[i].style = "";
        }
      }
    },

    realizarConsulta() {
      getEmpresa(this.idSolicitud).then(
        function (response) {
          this.valuesRegister = response.data[0];
          if(this.valuesRegister.remision == "1"){
            this.valuesRegister.remision = true
          }else{
            this.valuesRegister.remision = false
          }
          if(this.valuesRegister.ordenCompra == "1"){
            this.valuesRegister.ordenCompra = true
          }else{
            this.valuesRegister.ordenCompra = false
          }
          this.consultaSubServicios();
          this.updateForm()
        }.bind(this)
      );
    },

    userSearchSelectedFunc(values) {
      this.valuesRegister.userSearchSelected = values;
    },
    recargarFormulario() {
      this.componentKey -= 1;
    },

    getCosto(index, servicioSel) {
      let totalAumentar = 0;
      for(let i in this.servicios){
        if(i==servicioSel){
          break;
        }
        totalAumentar += this.servicios[i].length
      }
      return this.costosServicios[totalAumentar+index].costo
      // this.valuesRegister.costos = JSON.parse(
      //   JSON.stringify(this.costosServicios)
      // );
    },

    updateCosto(value, index, servicioSel) {
      let totalAumentar = 0;
      for(let i in this.servicios){
        if(i==servicioSel){
          break;
        }
        totalAumentar += this.servicios[i].length
      }
      this.costosServicios[totalAumentar+index].costo = Number(value);
      // this.valuesRegister.costos = JSON.parse(
      //   JSON.stringify(this.costosServicios)
      // );
    },
    actualizarEmpresa() {
      let datosRegistrar = this.valuesRegister
      updateEmpresa(datosRegistrar)
        .then(
          function (response) {
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            if (response.data == "") {
              Toast.fire({
                icon: "success",
                title: "Empresa actualizada exitosamente",
              });
              this.valuesRegister = valuesRegisterdef;
              window.location.reload();
            } else {
              Toast.fire({
                icon: "error",
                title: "Error al actualizar empresa, vuélvalo a intentar.",
              });
            }
            this.updateForm()
          }.bind(this)
        )
        .catch(function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar empresa, vuélvalo a intentar.",
          });
          this.updateForm()
        });
      // postUser(this.valuesRegister)
      //   .then(
      //     function (response) {
      //       const Toast = this.$swal.mixin({
      //         toast: true,
      //         position: "top-end",
      //         showConfirmButton: false,
      //         timer: 3000,
      //         timerProgressBar: true,
      //         didOpen: (toast) => {
      //           toast.addEventListener("mouseenter", this.$swal.stopTimer);
      //           toast.addEventListener("mouseleave", this.$swal.resumeTimer);
      //         },
      //       });
      //       if (response.data == "") {
      //         Toast.fire({
      //           icon: "success",
      //           title: "Usuario registrado exitosamente",
      //         });
      //         this.valuesRegister = valuesRegisterdef;
      //         this.recargarFormulario();
      //       } else {
      //         Toast.fire({
      //           icon: "error",
      //           title:
      //             "Error al registrar el usuario, correo no válido y/o ya usado.",
      //         });
      //       }
      //     }.bind(this)
      //   )
      //   .catch(function (response) {
      //     const Toast = this.$swal.mixin({
      //       toast: true,
      //       position: "top-end",
      //       showConfirmButton: false,
      //       timer: 3000,
      //       timerProgressBar: true,
      //       didOpen: (toast) => {
      //         toast.addEventListener("mouseenter", this.$swal.stopTimer);
      //         toast.addEventListener("mouseleave", this.$swal.resumeTimer);
      //       },
      //     });
      //     Toast.fire({
      //       icon: "error",
      //       title:
      //         "Error al registrar el usuario, correo no válido y/o ya usado.",
      //     });
      //   });
    },
    eliminarPrecio(id) {
      this.valuesRegister.costos.splice(id, 1);
    },

    consultaSubServicios() {
      getAllSubServicio().then(
        function (response) {
          this.servicios = response.data;

          this.costosServicios = [];
          for (let key in this.servicios) {
            for (let i = 0; i < this.servicios[key].length; i++) {
              let costo = 0
              try{
                costo = this.valuesRegister.costos[key][
                    this.servicios[key][i].NOMBRE_SUBSERVICIO
                  ]
                if(costo == undefined){
                  costo=0
                }
              } catch(error){
                costo = 0
              }
              this.costosServicios.push({
                idServicio: this.servicios[key][i].ID_SUBSERVICIO,
                costo: costo
              });
            }
          }
          this.updateForm()
          // this.valuesRegister.costos = JSON.parse(
          //   JSON.stringify(this.costosServicios)
          // );
        }.bind(this)
      );
    },

    updateForm(){
      this.componentKey ++;
    }
  },

  mounted() {
    getAllUsersEmpresa(this.idSolicitud).then(
      function (response) {
        this.peticiones = response.data;
      }.bind(this)
    );

    this.realizarConsulta();

    const { offsetWidth, offsetHeight } = document.querySelector("#app");
    if (offsetWidth <= 600) {
      setTimeout(function () {
        let classNames = document.getElementsByClassName("blitz-field");
        for (let i = 0; i < classNames.length; i++) {
          document.getElementsByClassName("blitz-field")[i].style = "";
        }
      }, 200);
    }

    let classNames = document.getElementsByClassName("blitz-form__form");
    for (let i = 0; i < classNames.length; i++) {
      document.getElementsByClassName("blitz-form__form")[i].style =
        "display: block";
    }

    getDocumento().then(
      function (response) {
        const indice = 1;
        const itemsOptionsTemplate = {
          value: "",
          text: "Tipo documento",
          disabled: true,
        };

        const options = [itemsOptionsTemplate];

        for (let i = 0; i < response.data.length; i++) {
          // Solución planteada así por un error de referencia de memoria
          const itemsOptionsTemplate = {
            value: "",
            text: "",
          };
          const subTemplate = itemsOptionsTemplate;
          itemsOptionsTemplate.value = response.data[i].ID_TIPO_DOCUMENTO;
          subTemplate.text = response.data[i].DESC_TIPO_DOCUMENTO;
          options.push(subTemplate);
        }
        let object = {
          id: "tipoDocumento",
          span: true,
          component: "VSelect",
          label: "Tipo de documento",
          style: tenPercentWFloat,
          items: options,
        };

        this.schemaRegister[indice] = object;

        this.schemaRegisterUsuario[1] = object;

        this.recargarFormulario();
      }.bind(this)
    );
  },

  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },

  watch: {
    // valuesRegister(newValue, oldValue) {
    //   this.valuesRegister.costos = oldValue.costos;
    // },
  },

  components: {
    FullBoxVue,
    BlitzForm,
    searchUserMultipleVue,
  },
};
</script>

<style>
.tirthyPercent {
  width: 23%;
  max-width: 28%;
  margin: 1%;
  float: left;
}

.blitz-form__form {
  display: block !important;
}

.formContent {
  display: flow-root;
}
</style>
