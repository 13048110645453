import GenerCampos from '../generCampos'

const generCampos = new GenerCampos()

const idNombresApellidos = 6

var informeEjecutivoTipoA = [
    {
      titulo: 'INFORMACIÓN PERSONAL',
      campos: [
         generCampos.darCampoTexto('Nombres Completos',null,6,false,false).toJSON(),
         generCampos.darCampoDesplegable('Tipo de Documento',null,3,'documento',false).toJSON(),
         generCampos.darCampoTexto('No de Documento',null,4,false,false).toJSON()
      ]
    },
    {
      titulo: 'CONCEPTO GENERAL',
      campos: [
         {
            label: '',
            tipo: 'tabla',
            cabeceras: ['SERVICIO','NIVEL DE RIESGO'],
            formato: [],
            listaValores: [
               [
                  {label:'', tipo: 'texto', valor: 'Consulta Bases Publicas', noEditable: true},
                  {label: '', tipo: 'desplegable', lista: ['Bajo', 'Medio', 'Alto'], valor: null}
               ],
               [
                  {label: '', tipo: 'texto', valor: 'Consulta Bases privadas', noEditable: true},
                  {label: '', tipo: 'desplegable', lista: ['Bajo', 'Medio', 'Alto'], valor: null}
               ],
               [
                  {label:'', tipo: 'texto', valor: 'Concepto Financiero', noEditable: true},
                  {label: '', tipo: 'desplegable', lista: ['Bajo', 'Medio', 'Alto'], valor: null}
               ],
               [
                  {label: '', tipo: 'texto', valor: 'Concepto poligrafía', noEditable: true},
                  {label: '', tipo: 'desplegable', lista: ['Bajo', 'Medio', 'Alto'], valor: null}
               ]
            ],
            listaTotales: [],
            noEditable: true,
            ignorar: false
         },
         {
            label: 'CONCEPTO GENERAL ASESOR',
            tipo: 'textoLargo',
            valor: null,
            ignorar: false
         }
      ]
    },
    {
      titulo: 'CONCEPTO REPUTACIONAL',
      campos: [

         generCampos.darCampoTabla('',['TIPO DE CONSULTA', 'VERIFICACIÓN'],[],[
            [
               {label: '', tipo: 'texto', valor: null},
               {label: '', tipo: 'texto', valor: null},
            ],
            [
               {label: '', tipo: 'texto', valor: null},
               {label: '', tipo: 'texto', valor: null},
            ]
         ],true,false).toJSON(),

         generCampos.darCampoCompletarTexto('',[
            'Consultadas las diferentes bases, se confirma que el señor ',
            generCampos.darCampoDependiente('',null,6,-1,false).toJSON(),
            ', identificado con documento ',
            generCampos.darCampoDependiente('',null,3,-1,false).toJSON(),
            ' No' ,
            generCampos.darCampoDependiente('',null,4,-1,false).toJSON(),
            generCampos.darCampoDesplegable('',null,-1,[' SI REGISTRA ',' NO REGISTRA '],false).toJSON(),
            'anotaciones negativas por lo cual se considera con un',
            generCampos.darCampoDesplegable('',null,-1,[' BAJO ',' ALTO '],false).toJSON()
         ],false).toJSON(),

         {
            label: '',
            tipo: 'form',
            form: {
               titulo: 'CONCEPTO FINANCIERO',
               campos: [
                  generCampos.darCampoCompletarTexto('',[
                     'Consultadas las centrales de riesgo, se confirma que el señor ',
                     generCampos.darCampoDependiente('',null,6,-1,false).toJSON(),
                     ', identificado con documento ',
                     generCampos.darCampoDependiente('',null,3,-1,false).toJSON(),
                     ' No ',
                     generCampos.darCampoDependiente('',null,4,-1,false).toJSON(),
                     ' se considera con RIESGO',
                     generCampos.darCampoDesplegable('',null,-1,[' BAJO ',' MEDIO ',' ALTO '],false).toJSON()
                  ],false).toJSON()
               ]
            }
         },
         generCampos.darCampoForm('CONCEPTO POLIGRAFIA',[
            generCampos.darCampoCompletarTexto('',[
               'El señor (a) ',
               generCampos.darCampoDependiente('',null,6,-1,false).toJSON(),
               ', quien desempeña el cargo de ',
               generCampos.darCampoTexto('',null,-1,false,false).toJSON(),
               generCampos.darCampoDesplegable('',null,-1,[' NSR SIN ADMISIONES ',' NSR CON ADMISIONES ',' SR SIN ADMISIONES ', ' SR CON ADMISIONES ']).toJSON(),
               'respuestas fisiológicas significativas de falta de veracidad a las preguntas relevantes de este examen.'
            ],false).toJSON()
         ]).toJSON(),

         generCampos.darCampoForm('CONCEPTO GENERAL',[
            generCampos.darCampoCompletarTexto('',[
               'El resultado de los procesos del estudio concluye que el señor (a) ',
               generCampos.darCampoDependiente('',null,idNombresApellidos,-1,false).toJSON(),
               'un concepto de seguridad ',
               generCampos.darCampoDesplegable('',null,-1,[' FAVORABABLE ',' NO FAVORABLES '],false).toJSON()
            ],false).toJSON()
         ]).toJSON(),

         generCampos.darCampoTextoLargo('','Debido a lo sensible de la información entregada en estos requerimientos se recuerda que todas las anotaciones de los antecedentes de tipo judicial están protegidas por la reserva legal. Es deber de toda autoridad asegurar la confidencialidad de la misma, de conformidad con el art. 294, 418, 419, 463 del Código Penal (Ley 599/2000), art 149 y 150, Código Penal Militar (Ley 522/99); por lo tanto, no deben ser de conocimiento de las personas relacionadas en este requerimiento. Es importante tener en cuenta que el reporte no sustituye la decisión judicial, por tal motivo la información debe ser verificada con la autoridad judicial correspondiente que aparece en el mismo, esto para descartar posibles homónimos, el delito por el cual se adelanta el proceso y el estado del mismo. Es de anotar y tal como lo estipula la normatividad vigente, la información relacionada con anotaciones (medidas de aseguramiento y órdenes de captura), está sujeta a la reserva legal, la violación o desconocimiento a esta reserva genera para el funcionario que las viole consecuencias de orden penal, disciplinario y hasta patrimonial (acción de repetición).',
         -1,true,false).toJSON(),

         generCampos.darCampoFirma().toJSON()
      ]
   }
]

export default(informeEjecutivoTipoA) 