<template>
  <FullBox>
    <MiddleBox><RetornoCampo :campo="campo.confiabilidad" /></MiddleBox>
    <MiddleBox><RetornoCampo :campo="campo.riesgo"/></MiddleBox>
    <FullBox>
      <RetornoCampo :campo="campo.observacion"/>
    </FullBox>
  </FullBox>
</template>
<script>

import MiddleBox from './MiddleBox.vue';
import QuarterBox from './QuarterBox.vue';
import FullBox from './FullBox.vue';

export default({
  components: { MiddleBox, QuarterBox, FullBox },
  props: {
    campo: {
      required: true
    }
  },
  name: 'ConceptoGeneralVisita',
  computed: {
    styleConfiabilidad() {
      const listaConfiabilidad = [
        { valor: 'Alta', color: 'green' },
        { valor: 'Media', color: 'yellow' },
        { valor: 'Baja', color: 'red' },
      ];
      const valor = this.campo.confiabilidad?.valor; // Evita posibles errores indefinidos
      return listaConfiabilidad.find(item => item.valor === valor)?.color || '';
    },
    styleRiesgo() {
      const listaConfiabilidad = [
        { valor: 'Alta', color: 'red' },
        { valor: 'Media', color: 'yellow' },
        { valor: 'Baja', color: 'green' },
      ];
      const valor = this.campo.riesgo?.valor; // Evita posibles errores indefinidos
      return listaConfiabilidad.find(item => item.valor === valor)?.color || '';
    },
  },
  methods: {
    updateStyleConfiabilidad() {
      this.styleConfiabilidad = this.calculatedConfiabilidadColor;
    },
  },
  watch: {
    campo: {
      handler(newVal, oldVal) {
        this.updateStyleConfiabilidad();
      },
      deep: true
    },
  },
})
</script>
<style scoped>
</style>