import GenerCampos from '../generCampos'

const generCampos = new GenerCampos()

var informeEjecutivoTipoB = [
   {
      titulo: 'INFORMACIÓN PERSONAL',
      campos: [
         generCampos.darCampoTexto('Nombres Completos',null,6,false,false).toJSON(),
         generCampos.darCampoDesplegable('Tipo de Documento',null,3,'documento',false).toJSON(),
         generCampos.darCampoTexto('No de Documento',null,4,false,false).toJSON()
      ]
   },
   {
      titulo: 'CONCEPTO GENERAL',
      campos: [
         generCampos.darCampoTabla('',['SERVICIO','NIVEL DE RIESGO'],[],[
            [
               generCampos.darCampoTexto('','Información Financiera',-1,true,false).toJSON(),
               generCampos.darCampoDesplegable('',null,-1,['Bajo', 'Medio', 'Alto'],false).toJSON()
            ],
            [
               generCampos.darCampoTexto('','Consulta Listas Publicas',-1,true,false).toJSON(),
               generCampos.darCampoDesplegable('',null,-1,['Bajo', 'Medio', 'Alto'],false).toJSON()
            ],
            [
               generCampos.darCampoTexto('','Consulta Listas privadas',-1,true,false).toJSON(),
               generCampos.darCampoDesplegable('',null,-1,['Bajo', 'Medio', 'Alto'],false).toJSON()
            ]
         ],true,false).toJSON(),

         generCampos.darCampoTextoLargo('CONCEPTO GENERAL ASESOR',null,0,false,false).toJSON()
      ]
   },
   {
      titulo: 'CONCEPTO REPUTACIONES',
      campos: [
         generCampos.darCampoTabla('',['TIPO DE CONSULTA', 'VERIFICACIÓN'],[],
            [
               [
                  {label: '', tipo: 'texto', valor: null},
                  {label: '', tipo: 'texto', valor: null},
               ],
               [
                  {label: '', tipo: 'texto', valor: null},
                  {label: '', tipo: 'texto', valor: null},
               ]
            ],true,false).toJSON(),
         generCampos.darCampoCompletarTexto('',[
            'Consultadas las diferentes bases, se confirma que el señor ',
            {label: '',idDependiente:6,noEditable: true,tipo: 'texto',valor: null},
            ', identificado con documento ',
            {label: '',tipo: 'texto',idDependiente: 3,noEditable: true,valor: null},
            'No',
            {label: '',tipo: 'texto',idDependiente: 4,noEditable: true,valor: null},
            generCampos.darCampoDesplegable('',null,-1,[' SI REGISTRA' ,' NO REGISTRA'  ]).toJSON(),
            ' anotaciones negativas por lo cual se considera con un riesgo ',
            generCampos.darCampoDesplegable('',null,-1,['BAJO','ALTO']).toJSON(),
         ], false).toJSON(),

      ]
   },
   {
      titulo: 'ANÁLISIS FINANCIERO',
      campos: [
         {
            label: 'INFORMACIÓN CUENTAS BANCARIAS',
            tipo: 'tabla',
            cabeceras: ['Estado Cuenta', 'Tipo Cuenta', 'Entidad', 'Fecha Apertura', 'No. Cuenta'],
            formato: [
               { label: '', tipo: 'texto', valor: null },
               { label: '', tipo: 'texto', valor: null },
               { label: '', tipo: 'texto', valor: null },
               { label: '', tipo: 'texto', valor: null },
               { label: '', tipo: 'texto', valor: null }
            ],
            listaValores: [],
            ignorar: false
         },
         {
            label: 'OBSERVACIÓN',
            tipo: 'textoLargo',
            valor: null,
            ignorar: false
         }
      ]
   },
   {
      titulo: 'INFORMACIÓN OBLIGACIONES VIGENTES',
      campos: [
         {
            label: '',
            tipo: 'tabla',
            cabeceras: ['Tipo de Obligación', 'Entidad', 'Valor inicial', 'Valor Utilizado', 'Valor cuota', 'Valor mora', 'N cuotas pendientes'],
            formato:[
               { label: '', tipo: 'texto', valor: null },
               { label: '', tipo: 'texto', valor: null },
               { label: '', tipo: 'numero', valor: null, simbolo: '$'},
               { label: '', tipo: 'numero', valor: null, simbolo: '$' },
               { label: '', tipo: 'numero', valor: null, simbolo: '$' },
               { label: '', tipo: 'numero', valor: null, simbolo: '$' },
               { label: '', tipo: 'numero', valor: null, simbolo: '$' },
            ],

            listaValores: [],
            ignorar: false
         },
         generCampos.darCampoTextoLargo('Observaciones',null,null,false,false).toJSON(),
         generCampos.darCampoTextoLargo('',
            'Debido a lo sensible de la información entregada en estos requerimientos se recuerda que todas las anotaciones de los antecedentes de tipo judicial están protegidas por la reserva legal. Es deber de toda autoridad asegurar la confidencialidad de la misma, de conformidad con el art. 294, 418, 419, 463 del Código Penal (Ley 599/2000), art 149 y 150, Código Penal Militar (Ley 522/99); por lo tanto, no deben ser de conocimiento de las personas relacionadas en este requerimiento. Es importante tener en cuenta que el reporte no sustituye la decisión judicial, por tal motivo la información debe ser verificada con la autoridad judicial correspondiente que aparece en el mismo, esto para descartar posibles homónimos, el delito por el cual se adelanta el proceso y el estado del mismo. Es de anotar y tal como lo estipula la normatividad vigente, la información relacionada con anotaciones (medidas de aseguramiento y órdenes de captura), está sujeta a la reserva legal, la violación o desconocimiento a esta reserva genera para el funcionario que las viole consecuencias de orden penal, disciplinario y hasta patrimonial (acción de repetición).',
            null,true,false).toJSON(),
         generCampos.darCampoFirma().toJSON()
      ]
   }
]

export default(informeEjecutivoTipoB)