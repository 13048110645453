import GenerCampos from '../generCampos'

const generCampos = new GenerCampos()

const INGRESOS_PERSONA_ID = 15
const EGRESOS_PERSONA_ID = 16

var informePruebaPreenpleoVSA = [
    {
       titulo: 'INFORMACIÓN PERSONAL',
       campos: [
          {
             label: 'FOTO DEL USUARIO',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false,
             justify: 'center'
          },
          {
             label: 'Nombres y Apellidos',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             idCampo: 6
          },
          {
             label: 'Número de Cédula',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Lugar de Expedición',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Fecha de Expedición',
             tipo: 'fecha',
             valor: null,
             ignorar: false
          },
          {
             label: 'Lugar de Nacimiento',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Fecha de Nacimiento',
             tipo: 'fecha',
             valor: null,
             ignorar: false
          },
          {
             label: 'Edad',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Estado Civil',
             tipo: 'desplegable',
             lista: ['Casado', 'Soltero', 'Unión libre'],
             valor: null,
             ignorar: false
          },
          {
             label: 'Dirección Residencial',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Barrio / Localidad',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Ciudad',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Teléfono',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Fecha Examen Poligráfico',
             tipo: 'fecha',
             valor: null,
             ignorar: false
          },
          {
             label: 'No. Polígrafos Anteriores',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Cargo al que Aspira',
             tipo: 'texto',
             idCampo:5,
             valor: null,
             ignorar: false
          },
          {
             label: 'Compañía Solicitante',
             tipo: 'texto',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'ESTADO DE SALUD',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: [],
             listaValores: [
                [
                   {label: '', tipo: 'texto', valor:'¿Cómo califica su estado de salud?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: '', tipo: 'texto', valor:'¿Actualmente consume usted algún medicamento?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: '', tipo: 'texto', valor:'¿Ha tenido hospitalizaciones recientes?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: '', tipo: 'texto', valor:'¿Sufre de alguna enfermedad diagnosticada?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: '', tipo: 'texto', valor:'¿Ha estado en tratamientos psicológicos o psiquiátricos?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: '', tipo: 'texto', valor:'¿Le han realizado cirugías?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: '', tipo: 'texto', valor:'¿Ha tenido accidentes laborales?', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
             ],
             formato: [],
             noEditable: true,
             ignorar: false
          },
          {
             label: 'Observaciones',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          },
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `De acuerdo con lo mencionado frente al estado de salud físico y psicológico, se consideró a la persona  `
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      tipo: 'desplegable',
                      lista: ['IDÓNEA','NO IDONEA' ],
                      valor: null
                   }
                },
                {
                   tipo: 'texto',
                   texto: ` para el desarrollo de su prueba.`
                }
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'HISTORIAL FAMILIAR',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: ['PARENTESCO','NOMBRES','EDAD','OCUPACIÓN','CIUDAD DE RESIDENCIA','CONVIVE CON LA PERSONA'],
             formato: [
                {label: 'PARENTESCO', tipo: 'texto',valor: null},
                {label: 'NOMBRES', tipo: 'texto',valor: null},
                {label: 'EDAD', tipo: 'texto',valor: null},
                {label: 'OCUPACIÓN', tipo: 'texto',valor: null},
                {label: 'CIUDAD DE RESIDENCIA', tipo: 'texto',valor: null},
                {label: 'CONVIVE CON LA PERSONA', tipo: 'texto',valor: null},
             ],
             listaValores: [],
             ignorar: false
          },
          {
             label: 'Convivencia',
             tipo: 'inf',
             valor: 'Convivencia e Informacion Adicional',
             ignorar: false
          },
          {
             label: 'Observaciones',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
        titulo: 'INFORMACIÓN FINANCIERA Y PATRIMONIAL',
        campos: [
            generCampos.darCampoTabla(
               "INGRESOS PERSONA",
               ['',''],
               [],
               [
                  [
                  generCampos.darCampoTexto("","Ultimo Ingreso Mensual: ",null,true,false).toJSON(),
                  generCampos.darCampoValoraSumar("",INGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
                  ],
                  [
                  generCampos.darCampoTexto("","Ingresos Adicionales: ",null,true,false).toJSON(),
                  generCampos.darCampoValoraSumar("",INGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
                  ],
                  [
                  generCampos.darCampoTexto("","Total Ingresos",null,true,false).toJSON(),
                  generCampos.darCampoResultadoSuma('',INGRESOS_PERSONA_ID).setSimbolo('$').toJSON()
                  ]
               ],
               true,
               false
            ).toJSON(),
            generCampos.darCampoTabla(
               "EGRESOS PERSONA",
               ['',''],
               [],
               [
                  [
                     generCampos.darCampoTexto("","Gastos Financieros",0,true,false).toJSON(),
                     generCampos.darCampoValoraSumar("",EGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
                  ],
                  [
                     generCampos.darCampoTexto("","Arriendo",0,true,false).toJSON(),
                     generCampos.darCampoValoraSumar("",EGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
                  ],
                  [
                     generCampos.darCampoTexto("","Servicios",0,true,false).toJSON(),
                     generCampos.darCampoValoraSumar("",EGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
                  ],
                  [
                     generCampos.darCampoTexto("","Alimentación",0,true,false).toJSON(),
                     generCampos.darCampoValoraSumar("",EGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
                  ],
                  [
                     generCampos.darCampoTexto("","Transporte",0,true,false).toJSON(),
                     generCampos.darCampoValoraSumar("",EGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
                  ],
                  [
                     generCampos.darCampoTexto("","Gastos personales",0,true,false).toJSON(),
                     generCampos.darCampoValoraSumar("",EGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
                  ],
                  [
                     generCampos.darCampoTexto("","EGRESOS MENSUALES TOTAL",0,true,false).toJSON(),
                     generCampos.darCampoResultadoSuma('',EGRESOS_PERSONA_ID).setSimbolo('$').toJSON()
                  ],
                  [
                     generCampos.darCampoTexto("","¿Los gastos son compartidos?",0,true,false).toJSON(),
                     generCampos.darCampoTexto('',null,-1,false,false).toJSON()
                  ]
               ],
               true,
               false
            ).toJSON(),
           generCampos.darCampoTextoLargo("Informacion Adicional ",null,0,false,false).toJSON()
        ]
     },
    {
       titulo: 'OBLIGACIONES CREDITICIAS',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: [],
             formato: [],
             listaValores: [
                [
                   { label: '', tipo: 'texto', valor: 'Créditos', noEditable: true},
                   { label: '', tipo: 'texto', valor: null},
                ],
                [
                   { label: '', tipo: 'texto', valor: 'Tarjetas de Crédito', noEditable: true},
                   { label: '', tipo: 'texto', valor: null},
                ],
                [
                   { label: '', tipo: 'texto', valor: 'Registros en centrales de riesgo financiero', noEditable: true},
                   { label: '', tipo: 'texto', valor: null},
                ],
                [
                   { label: '', tipo: 'texto', valor: 'Bienes muebles e inmuebles a su nombre.', noEditable: true},
                   { label: '', tipo: 'texto', valor: null},
                ]
             ],
             columnasPeque: [0],
             ignorar: false,
             noEditable: true
          },
          {
             label: 'Información adicional',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'HISTORIAL ACADÉMICO',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: [ 'TÍTULO', 'AÑO GRADO', 'INSTITUCIÓN ACADÉMICA', 'CIUDAD'],
             formato: [
                { label: 'TÍTULO', tipo: 'texto', valor: null},
                { label: 'AÑO GRADO', tipo: 'texto', valor: null},
                { label: 'INSTITUCIÓN ACADÉMICA', tipo: 'texto', valor: null},
                { label: 'CIUDAD', tipo: 'texto', valor: null}
             ],
             listaValores: [],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'DOCUMENTACION',
       campos: [
          {
             label: '',
             tipo: 'entrevista',
             preguntas: [
                {
                   pregunta: '¿La Información suministrada en su hoja de vida es válida, y verificable?',
                   valor: null
                },
                {
                   pregunta: '¿Los documentos soporte de la hoja de vida son válidos y verificables?',
                   valor: null
                },
                {
                   pregunta: 'Omitio de información en su HV?',
                   valor: null
                },
                {
                   pregunta: 'Informacion Adicional',
                   valor: null
                },
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'CONSUMO BEBIDAS ALCOHOLICAS',
       campos: [
          {
             label: '',
             tipo: 'entrevista',
             preguntas: [
                {
                   pregunta: '¿Consume Bebidas Alcohólicas y con qué frecuencia?',
                   valor: null
                },
                {
                   pregunta: '¿Bebida alcohólica de Preferencia?',
                   valor: null
                },
                {
                   pregunta: '¿Cuál es su periodicidad de consumo?',
                   valor: null
                },
                {
                   pregunta: '¿Inconvenientes relacionados con consumo de alcohol a nivel social, legal y familiar?',
                   valor: null
                },
                {
                   pregunta: '¿Inconvenientes presentados laboralmente por consumo de bebidas alcohólicas?',
                   valor: null
                },
                {
                   pregunta: 'Informacion Adicional',
                   valor: null
                },
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'CONSUMO DE SUSTANCIAS PSICOACTIVAS/ALUCINOGENAS',
       campos: [
          {
             label: '',
             tipo: 'entrevista',
             preguntas: [
                {
                   pregunta: '¿Consumo de sustancias psicoactivas?',
                   valor: null
                },
                {
                   pregunta: '¿Ultimo consumo?',
                   valor: null
                },
                {
                   pregunta: '¿Ha Transportado, vendido  o cultivado  sustancias psicoactivas?',
                   valor: null
                },
                {
                   pregunta: '¿Familiar o amistades con consumo, venta o cultivo de sustancias psicoactivas.?',
                   valor: null
                },
                {
                   pregunta: 'Informaciona adicional',
                   valor: null
                },
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'OTRAS ADICCIONES',
       campos: [
          {
             label: '',
             tipo: 'entrevista',
             preguntas: [
                {
                   pregunta: 'Consume cigarrillo, y con qué frecuencia?',
                   valor: null
                },
                {
                   pregunta: 'Practica de  manera recurrente   juegos de azar, casino o pornografía u otras adicciones?',
                   valor: null
                },
                {
                   pregunta: 'Informaciona adicional',
                   valor: null
                },
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'VINCULOS ILICITOS',
       campos: [
          {
             label: '',
             tipo: 'entrevista',
             preguntas: [
                {
                   pregunta: '¿Tiene o ha tenido Vínculos comerciales, familiares o sentimentales con grupos al margen de la ley, como guerrilla, paramilitares o bandas delincuenciales',
                   valor: null
                },
                {
                   pregunta: '¿Tiene o ha tenido relacion con personas que se dediquen a actividades ilícitas?',
                   valor: null
                },
                {
                   pregunta: '¿Ha Participado en la planeación o ejecución de ilícitos?',
                   valor: null
                },
                {
                   pregunta: '¿Ha recibido beneficios por participar o ejecución de ilícitos?',
                   valor: null
                },
                {
                   pregunta: '¿Ha prestado su nombre o identidad con fines ilícitos?',
                   valor: null
                },
                {
                   pregunta: 'Informaciona adicional',
                   valor: null
                },
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'INFORMACION JUDICIAL',
       campos: [
          {
             label: '',
             tipo: 'entrevista',
             preguntas: [
                {
                   pregunta: '¿Ultima vez que consulto?',
                   valor: null
                },
                {
                   pregunta: '¿Presencia de anotaciones judiciales?',
                   valor: null
                },
                {
                   pregunta: '¿Ha estado privado de la libertad?',
                   valor: null
                },
                {
                   pregunta: '¿Algún familiar o conocido a estado privado de la libertad?',
                   valor: null
                },
                {
                   pregunta: '¿Tiene o ha tenido demandas o procesos judiciales?',
                   valor: null
                },
                {
                   pregunta: '¿Ha instaurado demandas en contra de alguna persona o institución?',
                   valor: null
                },
                {
                   pregunta: 'Información adicional',
                   valor: null
                },
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'PLANES DE INFILTRACION',
       campos: [
          {
             label: '',
             tipo: 'entrevista',
             preguntas: [
                {
                   pregunta: '¿Tiene intención de infiltrarse en la compañía con fines ilícitos?',
                   valor: null
                },
                {
                   pregunta: '¿Cual es su objetivo de participar en este proceso de selección?',
                   valor: null
                },
                {
                   pregunta: '¿Como se enteró de la oferta laboral?',
                   valor: null
                },
                {
                   pregunta: 'Informacion adicional',
                   valor: null
                },
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'HISTORIA LABORAL',
       campos: [
          {
             label: '',
             tipo:'duplicaCampos',
             campo: {
                label: '',
                tipo: 'tabla',
                cabeceras: [],
                listaValores: [
                   [
                      {label: 'Empresa', tipo: 'texto', valor: '', noEditable: true},
                      {label: '', tipo: 'texto', valor: null}
                   ],
                   [
                      {label: 'Fecha de ingreso', tipo: 'texto', valor: '', noEditable: true},
                      {label: '', tipo: 'fecha', valor: null}
                   ],
                   [
                      {label: 'Fecha de retiro', tipo: 'texto', valor: '', noEditable: true},
                      {label: '', tipo: 'fecha', valor: null}
                   ],
                   [
                      {label: 'Último cargo ', tipo: 'texto', valor: '', noEditable: true},
                      {label: '', tipo: 'texto', valor: null}
                   ],
                   [
                      {label: 'Salario', tipo: 'texto', valor: '', noEditable: true},
                      {label: '', tipo: 'numero', simbolo: '$', valor: null}
                   ],
                   [
                      {label: 'Último jefe inmediato', tipo: 'texto', valor: '', noEditable: true},
                      {label: '', tipo: 'texto', valor: null}
                   ],
                   [
                      {label: 'Motivo del retiro', tipo: 'texto', valor: '', noEditable: true},
                      {label: '', tipo: 'texto', valor: null}
                   ],
                   [
                      {label: 'Informacion adicional', tipo: 'texto', valor: '', noEditable: true},
                      {label: '', tipo: 'texto', valor: null}
                   ],
                ],
                formato: [],
                noEditable: true,
                ignorar: false
             },
             listadoCampos: [
                {
                   label: '',
                   tipo: 'tabla',
                   cabeceras: [],
                   listaValores: [
                      [
                         {label: 'Empresa', tipo: 'texto', valor: '', noEditable: true},
                         {label: '', tipo: 'texto', valor: null}
                      ],
                      [
                         {label: 'Fecha de ingreso', tipo: 'texto', valor: '', noEditable: true},
                         {label: '', tipo: 'fecha', valor: null}
                      ],
                      [
                         {label: 'Fecha de retiro', tipo: 'texto', valor: '', noEditable: true},
                         {label: '', tipo: 'fecha', valor: null}
                      ],
                      [
                         {label: 'Último cargo ', tipo: 'texto', valor: '', noEditable: true},
                         {label: '', tipo: 'texto', valor: null}
                      ],
                      [
                         {label: 'Salario', tipo: 'texto', valor: '', noEditable: true},
                         {label: '', tipo: 'numero', simbolo: '$', valor: null}
                      ],
                      [
                         {label: 'Último jefe inmediato', tipo: 'texto', valor: '', noEditable: true},
                         {label: '', tipo: 'texto', valor: null}
                      ],
                      [
                         {label: 'Motivo del retiro', tipo: 'texto', valor: '', noEditable: true},
                         {label: '', tipo: 'texto', valor: null}
                      ],
                      [
                         {label: 'Observaciones', tipo: 'texto', valor: '', noEditable: true},
                         {label: '', tipo: 'texto', valor: null}
                      ],
                   ],
                   formato: [],
                   noEditable: true,
                   ignorar: false
                },
             ],
             ignorar: false
          },
         //  {
         //     label: '',
         //     tipo: 'textoLargo',
         //     valor: null,
         //     ignorar: false
         //  }
       ]
    },
    {
       titulo: 'VACIOS LABORALES',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: ['Fecha Inicial','Fecha Final','Actividad realizada'],
             columnasPeque: [0,1],
             formato: [
                { label: '', tipo: 'fecha', valor: null},
                { label: '', tipo: 'fecha', valor: null},
                { label: '', tipo: 'texto', valor: null},
             ],
             listaValores: [
                [
                   { label: '', tipo: 'fecha', valor: null},
                   { label: '', tipo: 'fecha', valor: null},
                   { label: '', tipo: 'texto', valor: null},
                ]
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'SITUACION LABORAL',
       campos: [
          {
             label: '',
             tipo: 'entrevista',
             preguntas: [
                {
                   pregunta: '¿Trabajo que más le ha gustado y por qué?',
                   valor: null
                },
                {
                   pregunta: 'Trabajo en el que más ha durado?',
                   valor: null
                },
                {
                   pregunta: '¿Acusaciones injustas a nivel laboral?',
                   valor: null
                },
                {
                   pregunta: '¿Ha presentado descargos, memorandos, suspensiones, descuentos o ha sido investigado por algún incidente en empleos anteriore?',
                   valor: null
                },
                {
                   pregunta: '¿Ha fingido o presentado incapacidades falsas con el fin de no presentarse a trabajar?',
                   valor: null
                },
                {
                   pregunta: '¿Despidos laborales por mala conducta o faltas a las normas laborales?:  ',
                   valor: null
                },
                {
                   pregunta: '¿ha presentado algún otro tipo de inconveniente o algo que desee adicionar?',
                   valor: null
                },
             ],
             pegarTexto: false,
             mostrarPregunta: true,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'ACTOS DELICTIVOS EN EMPLEOS ANTERIORES',
       campos: [
          {
             label: '',
             tipo: 'entrevista',
             preguntas: [
                {
                   pregunta: '¿Ilícitos en empleos anteriores?',
                   valor: null
                },
                {
                   pregunta: 'Ha entregado Información Confidencial a personas no autorizadas con fines ilícitos',
                   valor: null
                }
             ],
             mostrarPregunta: true,
             pegarTexto: false,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'ADMISIONES OBTENIDAS DURANTE LA PRUEBA',
       campos: [
          {
             label: '',
             tipo: 'inf',
             valor: 'Agregar las admisiones mencionadas en el transcurso de la prueba',
             ignorar: false
          },
          {
             label: '',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'TEMAS A EVALUAR',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             columnasPeque: [0],
             formato: [
                { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                { label: '', tipo: 'texto', valor: null}
             ],
             cabeceras: [],
             listaValores: [
                [
                   { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                   { label: '', tipo: 'texto', valor: null}
                ],
                [
                   { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                   { label: '', tipo: 'texto', valor: null}
                ],
                [
                   { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                   { label: '', tipo: 'texto', valor: null}
                ],
                [
                   { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                   { label: '', tipo: 'texto', valor: null}
                ],
             ],
             ignorar: false,
             autoIncrementable: true
          },
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `Cada una de las preguntas anteriormente relacionadas, fueron explicadas con anterioridad a
                   la evaluación, las cuales se operacionalizaron, aclararon y enmarcaron dentro del contexto de lo
                   que se quería indagar, de tal forma que la evaluada las explicó y manifestó entenderlas con
                   claridad.`
                }
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'PARÁMETROS CIENTÍFICOS',
       campos: [
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `Durante la prueba se llevó a cabo el procedimiento de verificación de la verdad
                   estandarizado por la sociedad Internacional de Analistas de estrés (ISSA).  Se desarrollo
                   una entrevista con multiplicidad de preguntas, en donde se obtuvieron datos generales y se
                   insistió en la condición voluntaria de la prueba, toda vez que con ello se pretende manejar los
                   niveles de tensión previa a la aplicación del sistema, con el objetivo de conocer detalles de los
                   hechos investigados.`
                },
                {
                   tipo: 'br',
                },
                {
                   tipo: 'br',
                },
                {
                   tipo: 'texto',
                   texto: `Se realizado la prueba de TDVSA (Digital Voice Stress Análisys) con el propósito de
                   medir los niveles de decepción y No decepción respecto al objetivo de la investigación.
                   Durante este paso se repasaron previamente los protocolos a utilizar con el propósito de
                   enfocar a la persona en el tema de interés. Se utilizo el protocolo de análisis de tensión
                   tónica, con preguntas de control universal, de medición del estrés por causas ajenas al caso,
                   relevantes e irrelevantes de control.`
                },
                {
                   tipo: 'br',
                },
                {
                   tipo: 'br',
                },
                {
                   tipo: 'texto',
                   texto: `Se le realizaron varias gráficas de VSA, las cuales fueron analizadas y calificadas
                   por el analista, bajo los parámetros que, para ello, estipula la ISSA. Asociación internacional
                   de Analistas de Tensión Tónica de Voz.`
                }
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'RESULTADO DEL EXAMEN',
       campos: [
           {
              label: '',
              tipo: 'completarTexto',
              secuencias: [
                 {
                    tipo: 'texto',
                    texto: `Con base en los detalles suministrados por el entrevistado, en los criterios
                    estandarizados para leer la prueba, se concluye que  `
                 },
                 {
                    tipo: 'campo',
                    campo: {
                       label: '',
                       idDependiente: 6,
                       noEditable: true,
                       tipo: 'texto',
                       valor: null
                    }
                 },
                 {
                    tipo: 'texto',
                    texto: `, quien está aplicando al cargo de `
                 },
                 {
                    tipo: 'campo',
                    campo: {
                       label: '',
                       idDependiente: 5,
                       noEditable: true,
                       tipo: 'texto',
                       valor: null
                    }
                 },
                 {
                    tipo: 'texto',
                    texto: ','
                 },
                 {
                    tipo: 'campo',
                    campo: {
                       label: '',
                       tipo: 'desplegable',
                       lista: ['NO PRESENTA TENSIÓN O ENGAÑO EN LA RESPUESTA  (NDI),',' PRESENTA TENSIÓN O ENGAÑO EN LA RESPUESTA  (DI),'],
                       valor: null
                    }
                 },
                 {
                    tipo: 'campo',
                    campo: {
                       label: '',
                       tipo: 'textoLargo',
                       valor: ", en las preguntas de este examen ",
                       wx: 3,
                       ignorar: false
                    }
                 }
              ],
              ignorar: false
           },
          {
              label: 'FIRMA',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false
           }
       ]
    },
    {
       titulo: 'NOTAS ACLARATORIAS',
       campos: [
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `Los resultados de las pruebas de análisis de tensión tónica VSA siempre serán entregados
                   al cliente que contrató los servicios. Cada contratante está en plena autonomía de tomar las
                   decisiones laborales y/o judiciales que considere necesarias para cada situación. Sin embargo,
                   se recuerda que en Colombia la prueba poligráfica o de VSA no se constituye en prueba pericial o
                   judicial, es sólo un indicio. Según la legislación laboral colombiana, en NINGÚN momento se podrá
                   despedir a una persona por los resultados del examen VSA, pues no se considera justa causa. En
                   Colombia, los resultados de la evaluación VSA se utilizan para la investigación interna de las
                   organizaciones; ya sea en la verificación de antecedentes, idoneidad en cargos o en la investigación
                   de algún siniestro. En ningún momento, la información suministrada por la persona será utilizada
                   por el contratante para acusarlo/a judicialmente. b. Los resultados obtenidos en la evaluación de
                   VSA, son de carácter CONFIDENCIAL y la divulgación de los mismos, por parte del contratante a
                   personas que no hayan sido autorizadas por la evaluada, se considera una violación a su privacidad.`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `Debido a que la mayoría de las investigaciones en poligrafía, las han desarrollado
                   agencias gubernamentales y universidades americanas, los términos se han globalizado según su
                   traducción del inglés:`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `N.D.I. (No Significant Response), esto quiere decir que la persona no presentó reacciones
                   fisiológicas significativas de engaño.`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `D.I. (Significant Response), esto quiere decir que la persona si presentó reacciones
                   fisiológicas significativas de engaño, en otras palabras, que mintió.`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `Determinación de estado psicofisiológico:`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `Idóneo: En condiciones adecuadas para el desarrollo de la prueba`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `Marginal: Que presenta alguna situación médica o psicológica, que, aunque no impide
                   el desarrollo de la prueba, podría afectar la confiabilidad. Sin embargo, se puede desarrollar
                   la prueba y obtener información de interés o evitar un ilícito.`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `No idóneo: Que la evaluada no está en condiciones físicas o psicológicas para
                   presentar el examen.`
                },
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'DOCUMENTOS',
       campos: [
        {
          tipo: 'saltoPagina'
        },
          {
             label: 'FOTO CAPTURA DE LLAMADA',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'AUTORIZACIÓN',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
       ]
    }
  ]

export default(informePruebaPreenpleoVSA)
