<template>
  <FullBoxVue class="shadowHover">
    <h1>Modificar subservicios</h1>
    <br />
    <v-btn color="primary" dark @click="showModal = true"
      >Registrar nuevo subservicio</v-btn
    >

    <v-dialog v-model="showModal" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Ingrese el nombre del nuevo subservicio</span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            label="Nombre de subservicio"
            v-model="newData"
          ></v-text-field>
          <v-checkbox
            v-model="envioCorreos"
            label="Habilitar envío de correos"
          ></v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showModal = false"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveData">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <br /><br />
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="serviciosDefault"
        :search="search"
      >
        <template v-slot:body="{ items, headers }">
          <tbody>
            <tr v-for="(item, idx, k) in items" :key="idx">
              <td v-for="(header, key) in headers" :key="key">
                <v-edit-dialog
                  :return-value.sync="item[header.value]"
                  @save="save(item)"
                  save-text="Guardar"
                  @cancel="cancel"
                  cancel-text="Cancelar"
                  @open="open"
                  @close="close"
                  large
                >
                  {{ item[header.value] }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.nombre"
                      label="Nombre solicitud"
                      single-line
                    ></v-text-field>
                    <v-checkbox
                      v-model="item.envioCorreos"
                      label="Habilitar envío de correos"
                    ></v-checkbox>
                    <v-select
                      v-model="item.idInforme"
                      :items="informesSelect"
                      item-text="nombreForm"
                      item-value="idInforme"
                      label="Seleccionar formulario"
                      persistent-hint
                      single-line
                    ></v-select>
                  </template>
                </v-edit-dialog>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </FullBoxVue>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";

import { getSubServicio, newSubServicio, updateSubServicio, getAllInformes } from "../api";

export default {
  data() {
    return {
      search: "",
      headers: [{ text: "Nombre del subservicio", value: "nombre" }, { text: "Envia correos", value: "ENVIACORREOS"}, { text: "Informe Asociado", value: "informe"}],
      serviciosDefault: [],
      idSolicitudSeleccionada: {},
      infoSolicitudRef: null,
      showModal: false,
      newData: "",
      envioCorreos: true,
      informesSelect: [],
      selectedInform: null
    };
  },
  components: {
    FullBoxVue,
  },
  methods: {
    consultaServicio() {
      getSubServicio(this.$route.params.id).then(
        function (response) {
          this.preServicios = response.data;
          this.serviciosDefault = [];
          for (let i = 0; i < this.preServicios.length; i++) {
            this.serviciosDefault.push({
              id: this.preServicios[i].ID_SUBSERVICIO,
              nombre: this.preServicios[i].NOMBRE_SUBSERVICIO,
              idServicio: this.preServicios[i].ID_SERVICIO,
              ENVIACORREOS: this.preServicios[i].ENVIACORREOS==1 ? "Activado" : "Desactivado",
              envioCorreos: this.preServicios[i].ENVIACORREOS==1 ? true : false,
              informe: this.preServicios[i].informe,
              idInforme: this.preServicios[i].idInforme,
            });
          }
        }.bind(this)
      );
    },

    consultaInformes() {
      getAllInformes().then(
        function (response) {
          this.informesSelect = response.data
        }.bind(this)
      );
    },

    seleccionarPeticion(option) {
      this.idSolicitudSeleccionada = option;
      this.$router
        .push({ path: "/subservicio/" + this.idSolicitudSeleccionada.id })
        .catch(() => {});
    },

    saveData() {
      newSubServicio({
        nombre: this.newData,
        idServicio: this.$route.params.id,
        envioCorreos: this.envioCorreos
      }).then(
        function (response) {
          window.location.reload();
        }.bind(this)
      );
    },

    save(item) {
      updateSubServicio(item).then(
        function (response) {
          window.location.reload();
        }.bind(this)
      );
    },
    cancel() {},
    open() {},
    close() {},
  },
  mounted() {
    this.consultaServicio();
    this.consultaInformes();
  },
};
</script>

<style></style>
