var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FullBox',[_c('FullBox',[_c('h2',[_vm._v(_vm._s(_vm.titulo))]),_c('v-btn',{on:{"click":function($event){_vm.acivarExlusion = true}}},[_c('v-icon',[_vm._v("mdi mdi-playlist-edit")])],1)],1),_vm._l((_vm.campos),function(entry,i){return _c('div',{key:i},[(entry.wx === undefined && entry.ignorar === false)?_c('div',[(
            entry.tipo === 'texto' ||
            entry.tipo === 'numero'  ||
            entry.tipo === 'desplegable'  ||
            entry.tipo === 'fecha'  ||
            entry.tipo === 'archivo'
            )?_c('QuarterBox',[_c('RetornoCampo',{attrs:{"campo":entry,"idSolicitud":_vm.idSolicitud,"idForm":_vm.idForm,"idIteracion":_vm.idIteracion,"i":i.toString()}})],1):(
            entry.tipo === 'textoLargo' ||
            entry.tipo === 'form' ||
            entry.tipo === 'tabla' ||
            entry.tipo === 'tablaComparativa' ||
            entry.tipo === 'completarTexto' ||
            entry.tipo === 'inf' ||
            entry.tipo === 'entrevista' ||
            entry.tipo === 'duplicaCampos' ||
            entry.tipo === 'evaluacionPoligrafia' ||
            entry.tipo === 'ConceptoGeneralVisita'
            )?_c('FullBox',[_c('RetornoCampo',{attrs:{"campo":entry,"idSolicitud":_vm.idSolicitud,"idForm":_vm.idForm,"idIteracion":_vm.idIteracion,"i":i.toString()}})],1):_vm._e()],1):(entry.wx === 1 )?_c('QuarterBox',[_c('RetornoCampo',{attrs:{"campo":entry,"idSolicitud":_vm.idSolicitud,"idForm":_vm.idForm,"idIteracion":_vm.idIteracion,"i":i.toString()}})],1):(entry.wx === 2 )?_c('MiddleBox',[_c('RetornoCampo',{attrs:{"campo":entry,"idSolicitud":_vm.idSolicitud,"idForm":_vm.idForm,"idIteracion":_vm.idIteracion,"i":i.toString()}})],1):(entry.wx === 3 )?_c('FullBox',[_c('RetornoCampo',{attrs:{"campo":entry,"idSolicitud":_vm.idSolicitud,"idForm":_vm.idForm,"idIteracion":_vm.idIteracion,"i":i.toString()}})],1):_vm._e()],1)})],2),_c('v-dialog',{model:{value:(_vm.acivarExlusion),callback:function ($$v) {_vm.acivarExlusion=$$v},expression:"acivarExlusion"}},[_c('v-card',[_c('FullBox',[_c('v-btn',{on:{"click":function($event){_vm.acivarExlusion = false}}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi mdi-checkbox-multiple-marked")])],1)],1),_vm._l((_vm.campos),function(entry,i){return _c('MiddleBox',{key:i},[_c('h3',[_vm._v("Campo: "+_vm._s(i))]),_c('h3',[_vm._v(_vm._s(entry.label))]),(entry.ignorar=== true)?_c('v-btn',{staticClass:"red",on:{"click":function($event){entry.ignorar = false}}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi mdi-alpha-x-box")])],1):(entry.ignorar=== false)?_c('v-btn',{staticClass:"green",on:{"click":function($event){entry.ignorar = true}}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi mdi-checkbox-marked")])],1):_vm._e()],1)}),_c('FullBox',[_c('v-btn',{on:{"click":function($event){_vm.acivarExlusion = false}}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi mdi-checkbox-multiple-marked")])],1)],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }