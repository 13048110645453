import { render, staticRenderFns } from "./FullBox.vue?vue&type=template&id=33b7185e&scoped=true&"
import script from "./FullBox.vue?vue&type=script&lang=js&"
export * from "./FullBox.vue?vue&type=script&lang=js&"
import style0 from "./FullBox.vue?vue&type=style&index=0&id=33b7185e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b7185e",
  null
  
)

export default component.exports