<template>
  <div>
    <FullBoxVue class="shadowHover">
      <h1>Cargar plantilla de generación masiva de solicitudes</h1>

      <br><br>
      <v-alert
        :type="data.icon"
        v-for="(data, index) in mensajesEnvioForm"
        :key="index"
        closable
      >
        <span v-html="data.title"></span
      ></v-alert>
    </FullBoxVue>
    <MiddleBox class="shadowHover">


      <p>Primero, descargue la plantilla para las solicitudes masivas y siga el instructivo de la página "INSTRUCTIVO"</p>
      <v-btn @click="descargarExcel" download color="primary"
        >Haga click aquí para descargar plantilla</v-btn
      >
      <br><br>

      <h2>Herramienta de apoyo de generador de Servicios y subservicios para plantilla</h2>
      <br>
      <p>
        Use este generador para pegar los datos dentro de la plantilla de
        solicitudes masivas. ESTA HERRAMIENTA ES DE APOYO, NO ES OBLIGATORIA PARA LA GENERACIÓN DE SOLICITUDES.
      </p>

      <p>
        Puede ajustar los parámetros según lo necesite e ir copiando y pegando en la plantilla, puede generar distintas solicitudes
        de servicio y subservicio, de igual manera con diferente departamento y ciudad. La información debe de ser igual a como se la
        entrega el generador o a como está en la pestaña de INSTRUCTIVO de la plantilla.
      </p>

      <p>Tenga en cuenta que la columna idUsuario* va asociada con el usuario que se selecciona en el generador de servicios y subservicios para la plantilla.</p>

      <searchUserVue
        v-bind:people="userSearch"
        @selected="userSearchSelectedFunc"
      ></searchUserVue>
      <div v-for="(data, index) in schemaServicio" :key="index">
        <v-select
          @change="buscarSubservicio(valuesServicio[index].servicio, index)"
          v-model="valuesServicio[index].servicio"
          :items="data.servicio"
          item-text="nombre"
          item-value="nombre"
          label="Servicio"
        ></v-select>
        <v-select
          v-model="valuesServicio[index].subservicio"
          :items="data.subservicio"
          item-text="nombre"
          item-value="nombre"
          label="SubServicio"
        ></v-select>
      </div>
      <br />
      <BlitzForm
        :schema="schemaUbicacion"
        v-model="valuesUbicacion"
        :key="componentKeyUbicacion"
        gridGap="0"
        labelPosition="left"
      />
      {{ msgFormato }}
      <br />
      <v-btn @click="validarGenerador()"> Copiar formato </v-btn>
    </MiddleBox>
    <MiddleBox class="shadowHover">
      <h2>Subir plantilla</h2>
      <div v-if="!loading">
        <br />
        <input type="file" @change="uploadFile" ref="file" />
        <br /><br />
        <v-btn color="primary" dark @click="enviarFormulario">
          Enviar Plantilla
        </v-btn>
      </div>
      <div v-else>
        <loadingCSS>
          Se están creando las solicitudes... <br />
          Esto puede tomar unos momentos
        </loadingCSS>
      </div>
    </MiddleBox>
  </div>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";
import { BlitzForm } from "blitzar";
import Vue from "vue";

import {
  VTextField,
  VRadioGroup,
  VRadio,
  VSlider,
  VCheckbox,
  VSwitch,
  VSelect,
} from "vuetify/lib";
// All components that are used in the form need to be globally registered.
Vue.component("VTextField", VTextField);
Vue.component("VRadioGroup", VRadioGroup);
Vue.component("VRadio", VRadio);
Vue.component("VSlider", VSlider);
Vue.component("VCheckbox", VCheckbox);
Vue.component("VSwitch", VSwitch);
Vue.component("VSelect", VSelect);

import {
  getServicioSpecificUser,
  getSubServicioByNameSpecificUser,
  getDepartamento,
  downloadFile,
  getCiudad,
  getDocumento,
  leerCargaMasivaDeSolicitudes,
  getClientes,
} from "@/api";
import MiddleBox from "@/components/static/MiddleBox.vue";
import searchUserVue from "../components/searchUser.vue";
import loadingCSS from "../components/static/loadingCSS.vue";

const valuesServiciodef = {
  servicio: "",
  subservicio: "",
};

const valuesUbicaciondef = {
  departamento: "",
  ciudad: "",
};

const valuesCandidatodef = {
  nombreCandidato: "",
  tipodocumento: "",
  documento: "",
  numeroCandidato: "",
  correo: "",
  observaciones: "",
};

export default {
  data() {
    return {
      valuesServicio: [],
      CentroCostos: "",
      schemaServicio: [],

      valuesUbicacion: {
        departamento: "",
        ciudad: "",
      },
      msgFormato: "",
      schemaUbicacion: [],
      componentKey: 0,
      componentKeyUbicacion: -1,
      depCiudad: {
        departamento: "",
        ciudad: "",
      },

      valuesCandidato: {
        nombreCandidato: "",
        tipodocumento: "",
        documento: "",
        numeroCandidato: "",
        correo: "",
        observaciones: "",
      },
      schemaCandidato: [],
      Images: null,
      datosUser: {},
      userSearch: [],
      userSearchSelected: [],
      serviciosDefault: [],
      loading: false,
      mensajesEnvioForm: [],
    };
  },

  methods: {
    descargarExcel() {
      let filepath = "../upload/plantillaSolicitudesMasivas.xlsx";
      downloadFile(filepath).then((response) => {
        let fileName = filepath.split("/")[2];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
    },
    validarGenerador() {
      let usuarioSeleccionado = this.userSearchSelected;
      let servicio = this.valuesServicio[0].servicio;
      let subservicio = this.valuesServicio[0].subservicio;
      let departamento = this.depCiudad.departamento;
      let ciudad = this.depCiudad.ciudad;

      if (
        usuarioSeleccionado != "" &&
        servicio != "" &&
        subservicio != "" &&
        departamento != "" &&
        ciudad != ""
      ) {
        let str =
          usuarioSeleccionado +
          "\t" +
          servicio +
          "\t" +
          subservicio +
          "\t" +
          departamento +
          "\t" +
          ciudad +
          "\t";
        this.msgFormato =
          "Formato copiado, lo puede pegar directamente en el excel de la plantilla";
        navigator.clipboard.writeText(str);
      } else {
        this.msgFormato = "Faltan datos para llenar";
      }
    },

    userSearchSelectedFunc(values) {
      this.userSearchSelected = values;
    },
    recargarFormulario() {
      this.componentKey += 1;
      this.componentKeyUbicacion -= 1;
    },

    uploadFile() {
      this.Images = this.$refs.file.files[0];
    },

    enviarFormulario() {
      this.mensajesEnvioForm = [];
      this.loading = true;
      const formData = new FormData();
      formData.append("file", this.Images);
      leerCargaMasivaDeSolicitudes(formData).then(
        function (response) {
          const separarString = (value) => value.split(/\r\n|\r|\n/, -1);
          let stringSeparada = separarString(response.data);
          this.mensajesEnvioForm = [];
          for (let msg in stringSeparada) {
            if (stringSeparada[msg] != "") {
              this.mensajesEnvioForm.push({
                icon: "info",
                title: stringSeparada[msg],
              });
            }
          }
          // const Toast = this.$swal.mixin({
          //   toast: true,
          //   position: "top-end",
          //   showConfirmButton: false,
          //   timer: 3000,
          //   timerProgressBar: true,
          //   didOpen: (toast) => {
          //     toast.addEventListener("mouseenter", this.$swal.stopTimer);
          //     toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          //   },
          // });
          // Toast.fire({
          //   icon: "success",
          //   title: "Solicitud enviada exitosamente",
          // });
          //this.valuesServicio = valuesServiciodef;
          //this.valuesUbicacion = valuesUbicaciondef;
          //this.valuesCandidato = valuesCandidatodef;
          this.recargarFormulario();
          this.loading = false;
        }.bind(this)
      );
    },

    agregarServicios() {
      this.schemaServicio.push(
        structuredClone({
          servicio: this.serviciosDefault,
          subservicio: [],
        })
      );
      this.valuesServicio.push(structuredClone(valuesServiciodef));
    },

    consultaServicio(idEmpresa) {
      getServicioSpecificUser(idEmpresa).then(
        function (response) {
          this.preServicios = response.data;
          this.serviciosDefault = [];
          for (let i = 0; i < this.preServicios.length; i++) {
            this.serviciosDefault.push({
              id: this.preServicios[i].ID_SERVICIO,
              nombre: this.preServicios[i].NOMBRE_SERVICIO,
            });
          }
          this.schemaServicio = [];
          this.schemaServicio.push(
            structuredClone({
              servicio: this.serviciosDefault,
              subservicio: [],
            })
          );
          this.valuesServicio = [];
          this.valuesServicio.push(structuredClone(valuesServiciodef));

          //this.agregarServicios();
          this.recargarFormulario();
        }.bind(this)
      );
    },

    buscarSubservicio(id, indice) {
      this.valuesServicio[indice].subservicio = "";
      let idUser = this.userSearchSelected;
      if (this.$dataUser[0].idRol == 8 || this.$dataUser[0].idRol == 2) {
        idUser = this.$dataUser[0].id;
      }
      getSubServicioByNameSpecificUser(id, idUser).then(
        function (response) {
          if (response.data.length > 0) {
            let preSubServicios = response.data;
            let subserviciosDefault = [];
            for (let i = 0; i < preSubServicios.length; i++) {
              subserviciosDefault.push({
                id: preSubServicios[i].ID_SUBSERVICIO,
                nombre: preSubServicios[i].NOMBRE_SUBSERVICIO,
              });
            }
            this.schemaServicio[indice].subservicio = subserviciosDefault;
            this.recargarFormulario();
          }
        }.bind(this)
      );
    },

    consultaCiudad(id) {
      getCiudad(id).then(
        function (response) {
          const indice = 1;
          if (response.data.length > 0) {
            const itemsOptionsTemplate = {
              value: "",
              text: "Seleccione la ciudad",
              disabled: true,
            };

            const options = [itemsOptionsTemplate];

            for (let i = 0; i < response.data.length; i++) {
              // Solución planteada así por un error de referencia de memoria
              const itemsOptionsTemplate = {
                value: "",
                text: "",
              };
              const subTemplate = itemsOptionsTemplate;
              itemsOptionsTemplate.value = response.data[i].ID_CIUDAD;
              subTemplate.text = response.data[i].NOMBRE_CIUDAD;
              options.push(subTemplate);
            }

            if (this.schemaUbicacion[indice] == null) {
              this.schemaUbicacion.splice(indice, 0, {
                id: "ciudad",
                span: true,
                component: "VSelect",
                label: "Ciudad",
                items: options,
              });
            } else {
              this.schemaUbicacion[indice] = {
                id: "ciudad",
                span: true,
                component: "VSelect",
                label: "Ciudad",
                items: options,
              };

              this.recargarFormulario();
            }
          } else {
            this.schemaUbicacion.splice(indice, 1);
          }
        }.bind(this)
      );
    },
  },

  mounted() {
    if (this.$dataUser[0].idRol == 8 || this.$dataUser[0].idRol == 2) {
      this.consultaServicio(this.$dataUser[0].id);
    }

    getClientes().then(
      function (response) {
        this.userSearch = response.data;
      }.bind(this)
    );

    getDepartamento().then(
      function (response) {
        const indice = 0;
        const totalForms = 5;
        const itemsOptionsTemplate = {
          value: "",
          text: "Seleccione el departamento",
          disabled: true,
        };

        const options = [itemsOptionsTemplate];

        for (let i = 0; i < response.data.length; i++) {
          // Solución planteada así por un error de referencia de memoria
          const itemsOptionsTemplate = {
            value: "",
            text: "",
          };
          const subTemplate = itemsOptionsTemplate;
          itemsOptionsTemplate.value = response.data[i].ID_DEPARTAMENTO;
          subTemplate.text = response.data[i].NOMBRE_DEPARTAMENTO;
          options.push(subTemplate);
        }

        if (this.schemaUbicacion[indice] == null) {
          this.schemaUbicacion.splice(indice, 0, {
            id: "departamento",
            span: true,
            component: "VSelect",
            label: "Departamento",
            items: options,
          });
        } else {
          this.schemaUbicacion[indice] = {
            id: "departamento",
            span: true,
            component: "VSelect",
            label: "Departamento",
            items: options,
          };
        }
        this.recargarFormulario();
      }.bind(this)
    );

    getDocumento().then(
      function (response) {
        const indice = 0;
        const totalForms = 5;
        const itemsOptionsTemplate = {
          value: "",
          text: "Seleccionar tipo de documento",
          disabled: true,
        };

        const options = [itemsOptionsTemplate];

        for (let i = 0; i < response.data.length; i++) {
          // Solución planteada así por un error de referencia de memoria
          const itemsOptionsTemplate = {
            value: "",
            text: "",
          };
          const subTemplate = itemsOptionsTemplate;
          itemsOptionsTemplate.value = response.data[i].ID_TIPO_DOCUMENTO;
          subTemplate.text = response.data[i].DESC_COMPLE_TIPO_DOCUMENTOcol;
          options.push(subTemplate);
        }

        const opcionesDocumentos = {
          id: "tipodocumento",
          span: true,
          component: "VSelect",
          label: "Tipo de documento",
          items: options,
        };

        this.schemaCandidato = [
          {
            id: "nombreCandidato",
            component: "VTextField",
            label: "Nombre del candidato",
          },
          opcionesDocumentos,
          {
            id: "documento",
            component: "VTextField",
            label: "Número de documento",
          },
          {
            id: "numeroCandidato",
            component: "VTextField",
            label: "Número telefónico",
          },
          {
            id: "correo",
            component: "VTextField",
            label: "Correo",
          },
          {
            id: "observaciones",
            component: "VTextField",
            label: "Observaciones",
          },
        ];

        this.recargarFormulario();
      }.bind(this)
    );
  },

  watch: {
    valuesUbicacion(newValue, oldValue) {
      if (
        newValue.departamento &&
        newValue.departamento != oldValue.departamento
      ) {
        this.valuesUbicacion.ciudad = "";
        this.consultaCiudad(newValue.departamento);
        for (let departamento in this.schemaUbicacion[0].items) {
          if (
            this.schemaUbicacion[0].items[departamento].value ==
            newValue.departamento
          ) {
            this.depCiudad.departamento =
              this.schemaUbicacion[0].items[departamento].text;
          }
        }
        // this.depCiudad.departamento =
      }

      if (newValue.ciudad && newValue.ciudad != oldValue.ciudad) {
        for (let ciudad in this.schemaUbicacion[1].items) {
          if (this.schemaUbicacion[1].items[ciudad].value == newValue.ciudad) {
            this.depCiudad.ciudad = this.schemaUbicacion[1].items[ciudad].text;
          }
        }
      }
    },
    userSearchSelected(newValue, oldValue) {
      this.consultaServicio(newValue);
    },
  },

  components: {
    FullBoxVue,
    BlitzForm,
    MiddleBox,
    searchUserVue,
    loadingCSS,
  },
};
</script>

<style scoped>
div {
  width: 100%;
}
</style>
