<template>
  <v-menu
    v-model="mostrar"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="localValor"
        :label="label"
        :rules="rules"
        hint="MM/DD/YYYY"
        persistent-hint
        prepend-inner-icon="mdi-calendar-range"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="localValor" no-title @input="mostrar = false; updateValue(localValor)">
      <v-btn @click="mostrar = false">Cancelar</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'CampoFecha',
  props: {
    valor: {
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    rules: {
      default: () => []
    }
  },
  data() {
    return {
      mostrar: false,
      localValor: this.valor
    };
  },
  methods: {
    updateValue(newValue) {
      this.localValor = newValue;
      this.$emit('input', newValue); // Emitir el evento 'input' con el nuevo valor
    }
  },
  watch: {
    valor(newValue) {
      // Si el valor prop cambia desde el componente padre,
      // actualiza el valor local para mantenerlo sincronizado
      this.localValor = newValue;
    }
  }
};
</script>
