<template>
  <FullBoxVue class="shadowHover">
    <h1>Ver solicitudes</h1>
    <br />
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="peticiones"
        :search="search"
        @click:row="seleccionarPeticion"
        :items-per-page="40"
      ></v-data-table>

    </v-card>
  </FullBoxVue>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";

import { getPeticiones } from "../api";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Id", value: "id",  align: ' d-none' },
        { text: "Nombre Servicio", value: "NOMBRE_SERVICIO" },
        { text: "Nombre SubServicio", value: "NOMBRE_SUBSERVICIO" },
        { text: "Nombre Empresa", value: "nombreEmpresa" },
        { text: "Nombre Usuario", value: "nombreCliente" },
        { text: "Evaluado", value: "nombreCandidato" },
        { text: "Cargo", value: "cargo" },
        { text: "Ciudad", value: "NOMBRE_CIUDAD" },
        { text: "Departamento", value: "NOMBRE_DEPARTAMENTO" },
        { text: "Fecha Creación", value: "fechaCreacion" },
        { text: "Estado", value: "estado" },
        { text: "Analista Asignado", value: "encargados" }
      ],
      peticiones: [],
      idSolicitudSeleccionada: {},
      infoSolicitudRef: null
    };
  },
  components: {
    FullBoxVue,
  },
  methods: {
    seleccionarPeticion(option) {
      this.idSolicitudSeleccionada = option;
      this.$router.push({ path: '/peticion/'+this.idSolicitudSeleccionada.id}).catch(()=>{});
      //this.$swal('Hello Vue world!!!');
    },
  },
  mounted() {
    getPeticiones().then(
      function (response) {
        this.peticiones = response.data;
      }.bind(this)
    );
  },
};
</script>

<style></style>
