<template>
  <div :key="componentKey">
    <FullBoxVue class="shadowHover">
      <h1>Descargar informes</h1>
    </FullBoxVue>
    <MiddleBox class="shadowHover">
      <h3>Parámetros de descarga</h3>
      <v-form ref="form" v-model="valid" lazy-validation>
        Solicitudes de usuarios
        <v-checkbox
          v-model="buscarTodosUsuarios"
          :label="`Obtener información de todos los usuarios disponibles`"
          @change="changeForm()"
        ></v-checkbox>
        <searchUserMultipleVue
          v-if="!buscarTodosUsuarios"
          v-bind:people="userSearch"
          @selected="userSearchSelectedFunc"
          @change="changeForm()"
        ></searchUserMultipleVue>
        <v-checkbox
          v-model="buscarTodasFechas"
          :label="`Buscar por todas las fechas`"
          @change="changeForm()"
        ></v-checkbox>
        <div v-if="!buscarTodasFechas">
          <v-date-picker
            v-model="rangoFechas"
            @change="changeForm()"
            range
          ></v-date-picker>
        </div>

        <v-checkbox
          v-model="buscarTodasSolicitud"
          :label="`Buscar por todos los estados de solicitudes`"
          @change="changeForm()"
        ></v-checkbox>
        <v-select
          v-if="!buscarTodasSolicitud"
          v-model="estadoSolicitud"
          :items="estados"
          item-text="nombre"
          item-value="id"
          label="Estado"
          @change="changeForm()"
        ></v-select>

        <v-checkbox
          v-model="buscarTodosDepartamentos"
          :label="`Buscar por todos los departamentos`"
          @change="changeForm()"
        ></v-checkbox>
        <BlitzForm
          v-if="!buscarTodosDepartamentos"
          :schema="schemaUbicacion"
          v-model="valuesUbicacion"
          gridGap="0"
          labelPosition="left"
        />

        <v-checkbox
          v-model="buscarTodosCandidatos"
          :label="`Buscar por todos los candidatos`"
          @change="changeForm()"
        ></v-checkbox>
        <searchUserMultipleVue
          v-if="!buscarTodosCandidatos"
          v-bind:people="candidatoSearch"
          @selected="candidatoSearchSelectedFunc"
        ></searchUserMultipleVue>
        <div v-if="$dataUser[0].rol == 'Admin'">
          <v-checkbox
            v-model="buscarTodosCoordinadores"
            :label="`Buscar por todos los coordinadores`"
            @change="changeForm()"
          ></v-checkbox>
          <searchUserMultipleVue
            v-if="!buscarTodosCoordinadores"
            v-bind:people="coordinadoresSearch"
            @selected="coordinadoresSearchSelectedFunc"
          ></searchUserMultipleVue>

          <v-checkbox
            v-model="buscarTodosFuncionarios"
            :label="`Buscar por todos los funcionarios`"
            @change="changeForm()"
          ></v-checkbox>
          <searchUserMultipleVue
            v-if="!buscarTodosFuncionarios"
            v-bind:people="funcionarioSearch"
            @selected="funcionarioSearchSelectedFunc"
          ></searchUserMultipleVue>
        </div>
        <v-checkbox
          v-model="buscarDiasTranscurridos"
          :label="`Buscar por todos los días transcurridos`"
          @change="changeForm()"
        ></v-checkbox>
        <v-text-field
          v-if="!buscarDiasTranscurridos"
          v-model="diasTranscurridos"
          label="Número de días transcurridos"
          type="number"
        ></v-text-field>
      </v-form>
    </MiddleBox>
    <MiddleBox class="shadowHover" v-if="!loadingTable && peticiones.length > 0 && Array.isArray(peticiones)">
      <v-btn color="success" @click="descargarCertificadoPDF()"
        >Descargar certificado PDF</v-btn
      >
      <br /><br />
      <v-btn color="success" @click="descargarCertificadoXLS()" v-if="$dataUser[0].rol == 'Admin' || $dataUser[0].rol == 'Funcionario - Coordinador' || $dataUser[0].rol == 'Funcionario - Analista' || $dataUser[0].rol == 'Proveedor'"
        >Descargar certificado XLS (Excel)</v-btn
      >
    </MiddleBox>
    <MiddleBox class="shadowHover" v-else>
      El informe no es válido para descargar.
    </MiddleBox>
    <FullBoxVue class="shadowHover">
      <v-data-table
        :headers="headers"
        :items="peticiones"
        :search="search"
        v-if="!loadingTable"
      ></v-data-table>
      <div v-else>
        <loadingCSS>
          Se están creando las solicitudes... <br>
          Esto puede tomar unos momentos
        </loadingCSS>
      </div>
    </FullBoxVue>
  </div>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";
import MiddleBox from "@/components/static/MiddleBox.vue";
import { BlitzForm } from "blitzar";
import loadingCSS from "../components/static/loadingCSS.vue";


import searchUserMultipleVue from "../components/searchUserMultiple.vue";

import {
  generarCertificadosSolicitudesFuncionario,
  previewCertificadosSolicitudes,
  getClientes,
  getEstadosPeticiones,
  getDepartamento,
  getCiudad,
  getAllCandidatos,
  getCoordinadores,
  getFunProv,
} from "@/api";

export default {
  data() {
    return {
      componentKey: 0,
      valid: true,
      itemsSelected: 0,
      userSearch: [],
      funcionarioSearch: [],
      candidatoSearch: [],
      userSearchSelected: "",
      candidatoSearchSelected: "",
      coordinadoresSearchSelected: "",
      funcionarioSearchSelected: "",
      diasTranscurridos: "",
      buscarTodosUsuarios: true,
      buscarTodasFechas: true,
      buscarTodasSolicitud: true,
      buscarTodosDepartamentos: true,
      buscarTodosCandidatos: true,
      buscarTodosCoordinadores: true,
      buscarTodosFuncionarios: true,
      buscarDiasTranscurridos: true,
      rangoFechas: [],
      estadoSolicitud: null,
      schemaUbicacion: [],
      valuesUbicacion: {
        departamento: "",
        ciudad: "",
      },
      estados: null,
      headers: [
        { text: "Id Solicitud", value: "id" },
        { text: "Año", value: "anio" },
        { text: "Mes", value: "mesCreado" },
        { text: "Fecha solicitud", value: "fecha_creado" },
        { text: "Fecha hoy", value: "fecha_actual" },
        { text: "Días transcurridos", value: "diastranscurridos" },
        { text: "Departamento", value: "departamento" },
        { text: "Ciudad", value: "ciudad" },
        { text: "Empresa", value: "nombreEmpresa" },
        { text: "Nombre Cliente", value: "nombreCliente" },
        { text: "Cargo", value: "cargo" },
        { text: "Nombre Servicio", value: "servicio" },
        { text: "Nombre Subservicio", value: "subservicio" },
        { text: "Nombre Candidato", value: "nombreCandidato" },
        { text: "Dirección Candidato", value: "direccion" },
        { text: "# Documento Candidato", value: "numDocCandidato" },
        { text: "Teléfono Candidato", value: "numTelCandidato" },
        { text: "Coordinador(es) asignado(s)", value: "coordinadores" },
        { text: "Analista(s) asignado(s)", value: "encargados" },
        { text: "Estado solicitud", value: "estado" },
        { text: "Fecha fin", value: "updated" },
        { text: "Observaciones Cliente", value: "observacionesCandidato" },
        { text: "Observaciones Analista", value: "observaciones" },
      ],
      peticiones: [],
      search: "",
      loadingTable: true,
    };
  },

  methods: {
    recargarFormulario() {
      this.componentKey += 1;
    },
    userSearchSelectedFunc(values) {
      this.userSearchSelected = values;
    },
    candidatoSearchSelectedFunc(values) {
      this.candidatoSearchSelected = values;
    },

    coordinadoresSearchSelectedFunc(values) {
      this.coordinadoresSearchSelected = values;
    },

    funcionarioSearchSelectedFunc(values) {
      this.funcionarioSearchSelected = values;
    },

    consultaCiudad(id) {
      getCiudad(id).then(
        function (response) {
          const indice = 1;
          if (response.data.length > 0) {
            const itemsOptionsTemplate = {
              value: "",
              text: "Seleccione la ciudad",
              disabled: true,
            };

            const options = [itemsOptionsTemplate];

            for (let i = 0; i < response.data.length; i++) {
              // Solución planteada así por un error de referencia de memoria
              const itemsOptionsTemplate = {
                value: "",
                text: "",
              };
              const subTemplate = itemsOptionsTemplate;
              itemsOptionsTemplate.value = response.data[i].ID_CIUDAD;
              subTemplate.text = response.data[i].NOMBRE_CIUDAD;
              options.push(subTemplate);
            }

            if (this.schemaUbicacion[indice] == null) {
              this.schemaUbicacion.splice(indice, 0, {
                id: "ciudad",
                span: true,
                component: "VSelect",
                label: "Ciudad",
                items: options,
              });
            } else {
              this.schemaUbicacion[indice] = {
                id: "ciudad",
                span: true,
                component: "VSelect",
                label: "Ciudad",
                items: options,
              };

              this.recargarFormulario();
            }
          } else {
            this.schemaUbicacion.splice(indice, 1);
          }
        }.bind(this)
      );
    },

    descargarCertificadoPDF() {
      let body = {
        archivo: "pdf",
        buscarTodosUsuarios: this.buscarTodosUsuarios,
        buscarTodasFechas: this.buscarTodasFechas,
        buscarTodasSolicitud: this.buscarTodasSolicitud,
        buscarTodosDepartamentos: this.buscarTodosDepartamentos,
        buscarTodosCandidatos: this.buscarTodosCandidatos,
        buscarTodosCoordinadores: this.buscarTodosCoordinadores,
        buscarTodosFuncionarios: this.buscarTodosFuncionarios,
        diasTranscurridos: this.diasTranscurridos,
        userSearchSelected: this.userSearchSelected,
        rangoFechas: this.rangoFechas,
        estadoSolicitud: this.estadoSolicitud,
        valuesUbicacion: this.valuesUbicacion,
        candidatoSearchSelected: this.candidatoSearchSelected,
        coordinadoresSearchSelected: this.coordinadoresSearchSelected,
        funcionarioSearchSelected: this.funcionarioSearchSelected,
        buscarDiasTranscurridos: this.buscarDiasTranscurridos,
      };

      generarCertificadosSolicitudesFuncionario(body).then((response) => {
        const linkSource = `data:application/pdf;base64,${response.data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = "certificado.pdf";
        downloadLink.click();
      });
    },

    descargarCertificadoXLS() {
      let body = {
        archivo: "excel",
        buscarTodosUsuarios: this.buscarTodosUsuarios,
        buscarTodasFechas: this.buscarTodasFechas,
        buscarTodasSolicitud: this.buscarTodasSolicitud,
        buscarTodosDepartamentos: this.buscarTodosDepartamentos,
        buscarTodosCandidatos: this.buscarTodosCandidatos,
        buscarTodosCoordinadores: this.buscarTodosCoordinadores,
        buscarTodosFuncionarios: this.buscarTodosFuncionarios,
        diasTranscurridos: this.diasTranscurridos,
        userSearchSelected: this.userSearchSelected,
        rangoFechas: this.rangoFechas,
        estadoSolicitud: this.estadoSolicitud,
        valuesUbicacion: this.valuesUbicacion,
        candidatoSearchSelected: this.candidatoSearchSelected,
        coordinadoresSearchSelected: this.coordinadoresSearchSelected,
        funcionarioSearchSelected: this.funcionarioSearchSelected,
        buscarDiasTranscurridos: this.buscarDiasTranscurridos,
      };

      generarCertificadosSolicitudesFuncionario(body).then((response) => {
        let blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Let's create a link in the document that we'll
        // programmatically 'click'.
        const link = document.createElement("a");

        // Tell the browser to associate the response data to
        // the URL of the link we created above.
        link.href = window.URL.createObjectURL(blob);

        // Tell the browser to download, not render, the file.
        link.setAttribute(
          "download",
          "Certificado de solicitudes de usuarios.xlsx"
        );

        // Place the link in the DOM.
        document.body.appendChild(link);

        // Make the magic happen!
        link.click();
      });
    },

    realizarConsultaEstados() {
      getEstadosPeticiones().then(
        function (response) {
          this.estados = response.data;
        }.bind(this)
      );
    },

    changeForm() {
      this.loadingTable = true;
      let body = {
        buscarTodosUsuarios: this.buscarTodosUsuarios,
        buscarTodasFechas: this.buscarTodasFechas,
        buscarTodasSolicitud: this.buscarTodasSolicitud,
        buscarTodosDepartamentos: this.buscarTodosDepartamentos,
        buscarTodosCandidatos: this.buscarTodosCandidatos,
        buscarTodosCoordinadores: this.buscarTodosCoordinadores,
        buscarTodosFuncionarios: this.buscarTodosFuncionarios,
        diasTranscurridos: this.diasTranscurridos,
        userSearchSelected: this.userSearchSelected,
        rangoFechas: this.rangoFechas,
        estadoSolicitud: this.estadoSolicitud,
        valuesUbicacion: this.valuesUbicacion,
        candidatoSearchSelected: this.candidatoSearchSelected,
        coordinadoresSearchSelected: this.coordinadoresSearchSelected,
        funcionarioSearchSelected: this.funcionarioSearchSelected,
        buscarDiasTranscurridos: this.buscarDiasTranscurridos,
      };

      previewCertificadosSolicitudes(body).then((response) => {
        this.peticiones = response.data;
        this.loadingTable = false;
      });
    },
  },

  mounted() {
    getClientes().then(
      function (response) {
        this.userSearch = response.data;
      }.bind(this)
    );
    getCoordinadores().then(
      function (response) {
        this.coordinadoresSearch = response.data;
      }.bind(this)
    );
    getAllCandidatos().then(
      function (response) {
        this.candidatoSearch = response.data;
      }.bind(this)
    );

    getFunProv().then(
      function (response) {
        let funcionarioSearch = [];
        for (let user in response.data) {
          funcionarioSearch.push({
            id: response.data[user].id,
            nombre: response.data[user].nombre + " - " + response.data[user].rol,
          });
        }
        this.funcionarioSearch = funcionarioSearch;
      }.bind(this)
    );

    this.realizarConsultaEstados();
    this.changeForm();
    getDepartamento().then(
      function (response) {
        const indice = 0;
        const totalForms = 5;
        const itemsOptionsTemplate = {
          value: "",
          text: "Seleccione el departamento",
          disabled: true,
        };

        const options = [itemsOptionsTemplate];

        for (let i = 0; i < response.data.length; i++) {
          // Solución planteada así por un error de referencia de memoria
          const itemsOptionsTemplate = {
            value: "",
            text: "",
          };
          const subTemplate = itemsOptionsTemplate;
          itemsOptionsTemplate.value = response.data[i].ID_DEPARTAMENTO;
          subTemplate.text = response.data[i].NOMBRE_DEPARTAMENTO;
          options.push(subTemplate);
        }

        if (this.schemaUbicacion[indice] == null) {
          this.schemaUbicacion.splice(indice, 0, {
            id: "departamento",
            span: true,
            component: "VSelect",
            label: "Departamento",
            items: options,
          });
        } else {
          this.schemaUbicacion[indice] = {
            id: "departamento",
            span: true,
            component: "VSelect",
            label: "Departamento",
            items: options,
          };
        }
        this.recargarFormulario();
      }.bind(this)
    );
  },

  watch: {
    userSearchSelected(newState) {
      this.changeForm();
    },

    candidatoSearchSelected(newState) {
      this.changeForm();
    },

    coordinadoresSearchSelected(newState) {
      this.changeForm();
    },

    funcionarioSearchSelected(newState) {
      this.changeForm();
    },

    diasTranscurridos(newState) {
      this.changeForm();
    },




    valuesUbicacion(newValue, oldValue) {
      if (
        newValue.departamento &&
        newValue.departamento != oldValue.departamento
      ) {
        this.valuesUbicacion.ciudad = "";
        this.consultaCiudad(newValue.departamento);
      }
      this.changeForm();
    },
  },

  components: {
    FullBoxVue,
    MiddleBox,
    searchUserMultipleVue,
    BlitzForm,
    loadingCSS
  },
};
</script>

<style scoped>
div {
  width: 100%;
}
</style>
