<template>
  <FullBoxVue class="shadowHover">
    <h1>Usuarios de empresa</h1>
    <div>
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on">
            Crear nuevo usuario principal
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Registrar usuario principal
          </v-card-title>

          <v-card-text>
            <BlitzForm
              :schema="schemaRegisterUsuario"
              v-model="valuesRegisterUsuario"
              :key="componentKey"
              gridGap="0"
              labelPosition="left"
            />

            <!-- <v-select
              v-model="valuesRegisterUsuario.serviciosAsociados"
              :items="serviciosDefault"
              item-text="nombre"
              item-value="id"
              label="Servicios disponibles"
              multiple
            ></v-select> -->

            <!-- <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="valuesRegister.fechaCorte"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  v-model="valuesRegister.fechaCorte"
                  chips
                  small-chips
                  label="Fecha de corte"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-combobox>
              </template>
              <v-date-picker
                v-model="valuesRegister.fechaCorte"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(valuesRegister.fechaCorte)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu> -->
            <v-btn color="primary" dark @click="registrarUsuarioAdmin">
              Crear usuario principal
            </v-btn>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <br />
    <div>
      <v-dialog v-model="dialog2" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on">
            Crear nuevo SubUsuario
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Registrar usuario
          </v-card-title>

          <v-card-text>
            <BlitzForm
              :schema="schemaRegisterUsuario"
              v-model="valuesRegisterUsuario"
              :key="componentKey"
              gridGap="0"
              labelPosition="left"
            />

            <!-- <v-select
              v-model="valuesRegisterUsuario.serviciosAsociados"
              :items="serviciosDefault"
              item-text="nombre"
              item-value="id"
              label="Servicios disponibles"
              multiple
            ></v-select> -->

            <!-- <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="valuesRegister.fechaCorte"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  v-model="valuesRegister.fechaCorte"
                  chips
                  small-chips
                  label="Fecha de corte"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-combobox>
              </template>
              <v-date-picker
                v-model="valuesRegister.fechaCorte"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(valuesRegister.fechaCorte)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu> -->
            <v-btn color="primary" dark @click="registrarSubUsuario">
              Crear subusuario
            </v-btn>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-data-table
      :headers="headers"
      :items="peticiones"
      :search="search"
      @click:row="seleccionarPeticion"
    ></v-data-table>
  </FullBoxVue>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";
import { BlitzForm } from "blitzar";
import searchUserMultipleVue from "../components/searchUserMultiple.vue";
import Vue from "vue";

import {
  VTextField,
  VRadioGroup,
  VRadio,
  VSlider,
  VCheckbox,
  VSwitch,
  VSelect,
} from "vuetify/lib";
// All components that are used in the form need to be globally registered.
Vue.component("VTextField", VTextField);
Vue.component("VRadioGroup", VRadioGroup);
Vue.component("VRadio", VRadio);
Vue.component("VSlider", VSlider);
Vue.component("VCheckbox", VCheckbox);
Vue.component("VSwitch", VSwitch);
Vue.component("VSelect", VSelect);

import {
  getEmpresa,
  getDocumento,
  updateEmpresa,
  postSubUsers,
  getAllSubServicio,
  getAllUsersEmpresa,
} from "@/api";

const valuesRegisterdef = {
  nombre: "",
  tipoDocumento: "",
  contacto: "",
  correo: "",
  documento: "",
  rol: 8,
  fechaCorte: "",
  costos: [],
};

const tenPercent = "width: 18%;max-width: 18%;margin: 1%;";
const tenPercentWFloat = "width: 18%;max-width: 18%;margin: 1%; float: left;";

const twentyPercent = "width: 18%;max-width: 18%;margin: 1%;";
const twentyPercentWFloat =
  "width: 18%;max-width: 18%;margin: 1%; float: left;";

const thirtyPercent = "width: 28%;max-width: 28%;margin: 1%;";
const thirtyPercentWFloat =
  "width: 28%;max-width: 28%;margin: 1%; float: left;";

const thirtytreePercent = "width: 32.333%;max-width: 32.333%;margin: 1%;";
const thirtytreePercentWFloat =
  "width: 31.333%;max-width: 31.333%;margin: 1%; float: left;";

const fourtyPercent = "width: 38%;max-width: 38%;margin: 1%;";
const fourtyPercentWFloat =
  "width: 38%;max-width: 38%;margin: 1%; float: left;";

const fiftyPercent = "width: 48%;max-width: 48%;margin: 1%;";
const fiftyPercentWFloat = "width: 48%;max-width: 48%;margin: 1%; float: left;";

const sixtyPercent = "width: 58%;max-width: 58%;margin: 1%;";
const sixtyPercentWFloat = "width: 58%;max-width: 58%;margin: 1%; float: left;";

const seventyPercent = "width: 68%;max-width: 68%;margin: 1%;";
const seventyPercentWFloat =
  "width: 68%;max-width: 68%;margin: 1%; float: left;";

const hundredPercent = "width: 98%;max-width: 98%;margin: 1%;";
const hundredPercentWFloat =
  "width: 98%;max-width: 98%;margin: 1%; float: left;";

export default {
  data() {
    return {
      valuesRegister: {
        nombre: "",
        tipoDocumento: "",
        documento: "",
        contacto: "",
        correo: "",
        representante_legal: "",
        direccion: "",
        fechaCorte: "",
        fechaRemision: "",
        rol: 8,
        userSearchSelected: [],
        costos: [],
      },
      menu: false,
      serviciosDefault: [],
      schemaRegister: [
        {
          id: "razonsocial",
          component: "VTextField",
          placeholder: "Razón Social",
          style: fiftyPercentWFloat,
        },
        //Id Tipo Documento
        {},
        {
          id: "documento",
          component: "VTextField",
          placeholder: "No. Cedula / NIT",
          style: thirtyPercent,
        },
        {
          id: "direccion",
          component: "VTextField",
          placeholder: "Dirección de empresa",
          style: thirtytreePercentWFloat,
        },
        {
          id: "fechaCorte",
          component: "VTextField",
          type: "date",
          label: "Fecha de Corte",
          style: thirtytreePercentWFloat,
        },
        {
          id: "fechaRemision",
          component: "VTextField",
          type: "date",
          label: "Fecha de Remisión",
          style: thirtytreePercentWFloat,
        },
      ],
      componentKey: 0,
      costo: 0,
      userSearch: [],
      servicios: [],
      costosServicios: [],
      headers: [
        { text: "Id", value: "id" },
        { text: "Estado Cliente", value: "estado" },
        { text: "Nombre Cliente", value: "nombre" },
        { text: "Rol", value: "rol" },
        { text: "Código de cliente", value: "cod_cliente" },
        { text: "Correo", value: "correo" },
        { text: "Documento", value: "documento" },
        { text: "Tipo de documento", value: "tipo_documento" },
      ],
      schemaRegisterUsuario: [
        {
          id: "nombre",
          component: "VTextField",
          label: "Nombre",
          style: hundredPercent,
        },
        //Id Tipo Documento
        {},
        {
          id: "documento",
          component: "VTextField",
          label: "Documento",
          style: hundredPercent,
        },
        {
          id: "contacto",
          component: "VTextField",
          label: "Teléfono",
          style: hundredPercent,
        },
        {
          id: "correo",
          component: "VTextField",
          label: "Correo",
          style: hundredPercent,
        },
        //Info contacto
        {},
        //Tipo de rol
        {},
      ],
      serviciosDefault: [],
      valuesRegisterUsuario: {
        nombre: "",
        tipoDocumento: "",
        documento: "",
        contacto: "",
        correo: "",
        representante_legal: "",
        direccion: "",
        fechaCorte: "",
        fechaRemision: "",
        userSearchSelected: [],
        costos: [],
      },
      peticiones: [],
      search: "",
      dialog: false,
      dialog2: false,
    };
  },

  methods: {
    postUser() {
      postSubUsers(this.valuesRegisterUsuario)
        .then(
          function (response) {
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            if (response.data == "") {
              Toast.fire({
                icon: "success",
                title: "Usuario registrado exitosamente",
              });
              this.valuesRegister = valuesRegisterdef;
              this.recargarFormulario();
              window.location.reload();
            } else {
              Toast.fire({
                icon: "error",
                title:
                  "Error al registrar el usuario, correo no válido y/o ya usado.",
              });
            }
          }.bind(this)
        )
        .catch(function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title:
              "Error al registrar el usuario, correo no válido y/o ya usado.",
          });
        });
    },

    registrarUsuarioAdmin() {
      this.valuesRegisterUsuario.rol = 8;
      this.valuesRegisterUsuario.empresa = this.$dataUser[0].empresa;
      this.postUser();
    },

    registrarSubUsuario() {
      this.valuesRegisterUsuario.rol = 2;
      this.valuesRegisterUsuario.empresa = this.$dataUser[0].empresa;
      this.postUser();
    },

    seleccionarPeticion(option) {
      this.idSolicitudSeleccionada = option;
      this.$router
        .push({ path: "/usuario/" + this.idSolicitudSeleccionada.id })
        .catch(() => {});
      //this.$swal('Hello Vue world!!!');
    },
    myEventHandler(e) {
      if (e.currentTarget.innerWidth <= 600) {
        let classNames = document.getElementsByClassName("blitz-field");
        for (let i = 0; i < classNames.length; i++) {
          document.getElementsByClassName("blitz-field")[i].style = "";
        }
      }
    },

    realizarConsulta() {
      getEmpresa(this.$dataUser[0].empresa).then(
        function (response) {
          this.valuesRegister = response.data[0];
          this.updateForm()
        }.bind(this)
      );
    },

    userSearchSelectedFunc(values) {
      this.valuesRegister.userSearchSelected = values;
    },
    recargarFormulario() {
      this.componentKey -= 1;
    },

    getCosto(index, servicioSel) {
      let totalAumentar = 0;
      for(let i in this.servicios){
        if(i==servicioSel){
          break;
        }
        totalAumentar += this.servicios[i].length
      }
      return this.costosServicios[totalAumentar+index].costo
      // this.valuesRegister.costos = JSON.parse(
      //   JSON.stringify(this.costosServicios)
      // );
    },

    updateCosto(value, index, servicioSel) {
      let totalAumentar = 0;
      for(let i in this.servicios){
        if(i==servicioSel){
          break;
        }
        totalAumentar += this.servicios[i].length
      }
      this.costosServicios[totalAumentar+index].costo = Number(value);
      // this.valuesRegister.costos = JSON.parse(
      //   JSON.stringify(this.costosServicios)
      // );
    },
      // postUser(this.valuesRegister)
      //   .then(
      //     function (response) {
      //
      //       const Toast = this.$swal.mixin({
      //         toast: true,
      //         position: "top-end",
      //         showConfirmButton: false,
      //         timer: 3000,
      //         timerProgressBar: true,
      //         didOpen: (toast) => {
      //           toast.addEventListener("mouseenter", this.$swal.stopTimer);
      //           toast.addEventListener("mouseleave", this.$swal.resumeTimer);
      //         },
      //       });
      //       if (response.data == "") {
      //         Toast.fire({
      //           icon: "success",
      //           title: "Usuario registrado exitosamente",
      //         });
      //         this.valuesRegister = valuesRegisterdef;
      //         this.recargarFormulario();
      //       } else {
      //         Toast.fire({
      //           icon: "error",
      //           title:
      //             "Error al registrar el usuario, correo no válido y/o ya usado.",
      //         });
      //       }
      //     }.bind(this)
      //   )
      //   .catch(function (response) {
      //     const Toast = this.$swal.mixin({
      //       toast: true,
      //       position: "top-end",
      //       showConfirmButton: false,
      //       timer: 3000,
      //       timerProgressBar: true,
      //       didOpen: (toast) => {
      //         toast.addEventListener("mouseenter", this.$swal.stopTimer);
      //         toast.addEventListener("mouseleave", this.$swal.resumeTimer);
      //       },
      //     });
      //     Toast.fire({
      //       icon: "error",
      //       title:
      //         "Error al registrar el usuario, correo no válido y/o ya usado.",
      //     });
      //   });

    updateForm(){
      this.componentKey ++;
    }
  },

  mounted() {
    getAllUsersEmpresa(this.$dataUser[0].empresa).then(
      function (response) {
        this.peticiones = response.data;
      }.bind(this)
    );

    this.realizarConsulta();

    const { offsetWidth, offsetHeight } = document.querySelector("#app");
    if (offsetWidth <= 600) {
      setTimeout(function () {
        let classNames = document.getElementsByClassName("blitz-field");
        for (let i = 0; i < classNames.length; i++) {
          document.getElementsByClassName("blitz-field")[i].style = "";
        }
      }, 200);
    }

    let classNames = document.getElementsByClassName("blitz-form__form");
    for (let i = 0; i < classNames.length; i++) {
      document.getElementsByClassName("blitz-form__form")[i].style =
        "display: block";
    }

    getDocumento().then(
      function (response) {
        const indice = 1;
        const itemsOptionsTemplate = {
          value: "",
          text: "Tipo documento",
          disabled: true,
        };

        const options = [itemsOptionsTemplate];

        for (let i = 0; i < response.data.length; i++) {
          // Solución planteada así por un error de referencia de memoria
          const itemsOptionsTemplate = {
            value: "",
            text: "",
          };
          const subTemplate = itemsOptionsTemplate;
          itemsOptionsTemplate.value = response.data[i].ID_TIPO_DOCUMENTO;
          subTemplate.text = response.data[i].DESC_TIPO_DOCUMENTO;
          options.push(subTemplate);
        }
        let object = {
          id: "tipoDocumento",
          span: true,
          component: "VSelect",
          placeholder: "Tipo de documento",
          style: tenPercentWFloat,
          items: options,
        };

        this.schemaRegister[indice] = object;

        this.schemaRegisterUsuario[1] = object;

        this.recargarFormulario();
      }.bind(this)
    );
  },

  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },

  watch: {
    // valuesRegister(newValue, oldValue) {
    //   this.valuesRegister.costos = oldValue.costos;
    // },
  },

  components: {
    FullBoxVue,
    BlitzForm,
    searchUserMultipleVue,
  },
};
</script>

<style>
.tirthyPercent {
  width: 23%;
  max-width: 28%;
  margin: 1%;
  float: left;
}

.blitz-form__form {
  display: block !important;
}

.formContent {
  display: flow-root;
}
</style>
