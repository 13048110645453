<template>
  <div class="app">
    <v-divider/>
      <h2> {{ label }}</h2>
    <v-divider/>
      <table class="tabla" density="compact">
        <thead>
          <tr>
            <th class="text-left inlineBlock" v-for="(cabecera,i) in cabeceras" :key="i" >
              {{cabecera}}
            </th>
            <th v-if="!noEditable" class="filaBtn">
              <v-btn class="blue white--text" @click="agregarOtro()"><v-icon>mdi-plus</v-icon></v-btn>
            </th>
          </tr>
        </thead>
        <tbody class="contenido">
          <tr v-for="(fila,i) in lista" :key="i">
            <th v-for="(campo,j) in fila" :key="j" :class="esPequeColumna(j)">
              <RetornoCampo :campo="campo" :idSolicitud="idSolicitud" :muestraLabel="false" :i="i.toString() + j.toString()" ></RetornoCampo>
            </th>
            <th v-if="!noEditable" class="filaBtn">
              <v-btn class="blue white--text" @click="borrarFila(i)"><v-icon >mdi-delete</v-icon></v-btn>
            </th>
          </tr>
        </tbody>
      </table>
    <v-divider/>
  </div>
</template>
<script>

export default({
  props: {
    cabeceras: {
      default: []
    },
    label: {
      default: ''
    },
    formato: {
      default: []
    },
    lista: {
      default: []
    },
    idIteracion: {
      default: ''
    },
    noEditable: {
      default: false
    },
    listaTotales: {
      default: ''
    },
    autoIncrementable: {
      default: false
    },
    idSolicitud: {
      default: ''
    },
    columnasPeque: {
      type: Array,  // O el tipo de dato correcto
      default: function () {
        return [];  // Puedes devolver un objeto vacío o un array vacío según sea necesario
      }
    }
  },
  components: {  },
  name: 'CreateTable',
  data () {
    return {
    }
  },
  methods: {
    reCargarAutoIncremento: function () {
      let n =1
      this.lista.forEach(fila => {
        let campo= fila[0]
        campo.valor = n
        n ++
      })
    },
    agregarOtro: function() {
      for(let i = 0; i<this.formato.length; i++){
        this.formato[i].real = true;
      }

      const nuevo = JSON.parse(JSON.stringify(this.formato));
      this.lista.push(nuevo)

      if (this.autoIncrementable){
        this.reCargarAutoIncremento()
      }else {

      }

    },
    borrarFila: function(i) {
      if (i >= 0 && i < this.lista.length) {
        this.lista.splice(i, 1);
      }
    },
    esPequeColumna(nColumna){
      if (this.autoIncrementable === true && nColumna === 0){
        return 'columnaPeque'
      }

      if ( Array.isArray(this.columnasPeque) ){
        for (const columna of this.columnasPeque) {
          if (columna === nColumna) {
            return 'columnapeque2'
          }
        }
      }
      return ''
    }
  }
})
</script>
<style scoped>
.tabla {
  width: 100%;
  text-align: center;
  border: #919191 outset 1px;
  border-radius: 5px;
}
.tabla th{
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}
th {
  min-width: 150px;
}

.filaBtn{
  width: 80px;
}
.tabla .columnapeque2 {
  width: 250px;
}
.tabla th.columnaPeque {
  min-width: 50px;
}
</style>
