<template>
  <FullBoxVue class="shadowHover">
    <h1>Ver usuarios</h1>
    <br />

    <router-link :to="'/registerUser'">
      <v-btn color="primary">Agregar nueva cuenta</v-btn>
    </router-link>
    <br><br>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="peticiones"
        :search="search"
        @click:row="seleccionarPeticion"
        :items-per-page="40"
      ></v-data-table>

    </v-card>
  </FullBoxVue>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";

import { getAllUsers } from "../api";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Estado Cliente", value: "estado" },
        { text: "Nombre Cliente", value: "nombre" },
        { text: "Rol", value: "rol" },
        { text: "Código de cliente", value: "cod_cliente" },
        { text: "Correo", value: "correo" },
        { text: "Documento", value: "documento" },
        { text: "Tipo de documento", value: "tipo_documento" },
      ],
      peticiones: [],
      idSolicitudSeleccionada: {},
      infoSolicitudRef: null
    };
  },
  components: {
    FullBoxVue,
  },
  methods: {
    seleccionarPeticion(option) {
      this.idSolicitudSeleccionada = option;
      this.$router.push({ path: '/usuario/'+this.idSolicitudSeleccionada.id}).catch(()=>{});
      //this.$swal('Hello Vue world!!!');
    },
  },
  mounted() {
    getAllUsers().then(
      function (response) {
        this.peticiones = response.data;
      }.bind(this)
    );
  },
};
</script>

<style></style>
