<template>
  <FullBoxVue class="shadowHover">
    <div class="formContent">
      <h1>Registrar Usuario</h1>
      <br />
      <hr />
      <br /><br />
      <BlitzForm
        :schema="schemaRegister"
        v-model="valuesRegister"
        :key="componentKey"
        gridGap="0"
        labelPosition="left"
      />
      <v-select
        v-if="
          valuesRegister.rol == '7' ||
          valuesRegister.rol == '5' ||
          valuesRegister.rol == '6'
        "
        v-model="valuesRegister.serviciosAsociados"
        :items="serviciosDefault"
        item-text="nombre"
        item-value="id"
        label="Servicios disponibles"
        style="width: 31.333%; max-width: 31.333%; margin: 1%; float: left"
        multiple
      ></v-select>

      <div v-if="valuesRegister.rol == '6'">
        <v-text-field
          v-model="valuesRegister.cuenta"
          label="Número de cuenta Banco"
          style="width: 31.333%; max-width: 31.333%; margin: 1%; float: left"
        ></v-text-field>
        <v-text-field
          v-model="valuesRegister.banco"
          label="Banco"
          style="width: 31.333%; max-width: 31.333%; margin: 1%; float: left"
        ></v-text-field>
        <v-text-field
          v-model="valuesRegister.tipoCuenta"
          label="Tipo de cuenta"
          style="width: 31.333%; max-width: 31.333%; margin: 1%; float: left"
        ></v-text-field>
        <v-text-field
          v-model="valuesRegister.ciudad"
          label="Ciudad"
          style="width: 31.333%; max-width: 31.333%; margin: 1%; float: left"
        ></v-text-field>
        <v-text-field
          v-model="valuesRegister.direccion"
          label="Dirección"
          style="width: 31.333%; max-width: 31.333%; margin: 1%; float: left"
        ></v-text-field>
      </div>

      <searchUserMultipleVue
        v-if="valuesRegister.rol == '8'"
        v-bind:people="userSearch"
        @selected="userSearchSelectedFunc"
        style="width: 31.333%; max-width: 31.333%; margin: 1%; float: left"
      ></searchUserMultipleVue>
    </div>
    <FullBoxVue class="shadowHover">
      <v-alert
        v-if="errorRequeridos != ''"
        title="Hay errores en su formulario"
        type="error"
      >
        <span v-html="errorRequeridos"></span
      ></v-alert>
      <div>
        <v-btn
          color="primary"
          @click="enviarFormulario"
          :disabled="errorRequeridos != ''"
        >
          Crear
        </v-btn>
      </div>
    </FullBoxVue>
  </FullBoxVue>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";
import { BlitzForm } from "blitzar";
import searchUserMultipleVue from "../components/searchUserMultiple.vue";
import Vue from "vue";

import {
  VTextField,
  VRadioGroup,
  VRadio,
  VSlider,
  VCheckbox,
  VSwitch,
  VSelect,
} from "vuetify/lib";
// All components that are used in the form need to be globally registered.
Vue.component("VTextField", VTextField);
Vue.component("VRadioGroup", VRadioGroup);
Vue.component("VRadio", VRadio);
Vue.component("VSlider", VSlider);
Vue.component("VCheckbox", VCheckbox);
Vue.component("VSwitch", VSwitch);
Vue.component("VSelect", VSelect);

import {
  getDocumento,
  postUser,
  getRoles,
  getServicio,
  getClientes,
} from "@/api";

const tenPercent = "width: 18%;max-width: 18%;margin: 1%;";
const tenPercentWFloat = "width: 18%;max-width: 18%;margin: 1%; float: left;";

const twentyPercent = "width: 18%;max-width: 18%;margin: 1%;";
const twentyPercentWFloat =
  "width: 18%;max-width: 18%;margin: 1%; float: left;";

const thirtyPercent = "width: 28%;max-width: 28%;margin: 1%;";
const thirtyPercentWFloat =
  "width: 28%;max-width: 28%;margin: 1%; float: left;";

const thirtytreePercent = "width: 32.333%;max-width: 32.333%;margin: 1%;";
const thirtytreePercentWFloat =
  "width: 31.333%;max-width: 31.333%;margin: 1%; float: left;";

const fourtyPercent = "width: 38%;max-width: 38%;margin: 1%;";
const fourtyPercentWFloat =
  "width: 38%;max-width: 38%;margin: 1%; float: left;";

const fiftyPercent = "width: 48%;max-width: 48%;margin: 1%;";
const fiftyPercentWFloat = "width: 48%;max-width: 48%;margin: 1%; float: left;";

const sixtyPercent = "width: 58%;max-width: 58%;margin: 1%;";
const sixtyPercentWFloat = "width: 58%;max-width: 58%;margin: 1%; float: left;";

const seventyPercent = "width: 68%;max-width: 68%;margin: 1%;";
const seventyPercentWFloat =
  "width: 68%;max-width: 68%;margin: 1%; float: left;";

const hundredPercent = "width: 98%;max-width: 98%;margin: 1%;";
const hundredPercentWFloat =
  "width: 98%;max-width: 98%;margin: 1%; float: left;";

const valuesRegisterdef = {
  nombre: "",
  tipoDocumento: "",
  contacto: "",
  correo: "",
  documento: "",
  rol: "",
  serviciosAsociados: "",
  fechaCorte: "",
};
export default {
  data() {
    return {
      valuesRegister: {
        nombre: "",
        tipoDocumento: "",
        contacto: "",
        correo: "",
        documento: "",
        rol: "",
        serviciosAsociados: "",
        fechaCorte: "",
        userSearchSelected: [],
        cuenta: "",
        banco: "",
        tipoCuenta: "",
        direccion: "",
      },
      errorRequeridos: "",
      menu: false,
      serviciosDefault: [],
      schemaRegister: [
        {
          id: "nombre",
          component: "VTextField",
          label: "Nombre",
          style: fiftyPercentWFloat,
          isRequired: true,
        },
        //Id Tipo Documento
        {},
        {
          id: "documento",
          component: "VTextField",
          label: "Documento",
          style: thirtyPercent,
          isRequired: true,
        },
        {
          id: "contacto",
          component: "VTextField",
          label: "Teléfono",
          style: thirtytreePercentWFloat,
          isRequired: true,
        },
        {
          id: "fechaCorte",
          component: "VTextField",
          type: "number",
          label: "Día de corte",
          style: thirtytreePercentWFloat,
          isRequired: true,
        },
        {
          id: "correo",
          component: "VTextField",
          label: "Correo",
          style: thirtytreePercentWFloat,
          isRequired: true,
        },

        //Info contacto
        {},
        //Tipo de rol
        {},
      ],
      componentKey: 0,
      userSearch: [],
    };
  },

  methods: {
    userSearchSelectedFunc(values) {
      this.valuesRegister.userSearchSelected = values;
    },
    recargarFormulario() {
      this.componentKey += 1;
    },
    enviarFormulario() {
      postUser(this.valuesRegister)
        .then(
          function (response) {
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            if (response.data == "") {
              Toast.fire({
                icon: "success",
                title: "Usuario registrado exitosamente",
              });
              this.valuesRegister = valuesRegisterdef;
              this.recargarFormulario();
            } else {
              Toast.fire({
                icon: "error",
                title:
                  "Error al registrar el usuario, correo no válido y/o ya usado.",
              });
            }
          }.bind(this)
        )
        .catch(function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title:
              "Error al registrar el usuario, correo no válido y/o ya usado.",
          });
        });
    },

    consultaServicio() {
      getServicio().then(
        function (response) {
          this.preServicios = response.data;
          this.serviciosDefault = [];
          for (let i = 0; i < this.preServicios.length; i++) {
            this.serviciosDefault.push({
              id: this.preServicios[i].ID_SERVICIO,
              nombre: this.preServicios[i].NOMBRE_SERVICIO,
            });
          }
        }.bind(this)
      );
    },

    myEventHandler(e) {
      if (e.currentTarget.innerWidth <= 600) {
        let classNames = document.getElementsByClassName("blitz-field");
        for (let i = 0; i < classNames.length; i++) {
          document.getElementsByClassName("blitz-field")[i].style = "";
        }
      }
    },

    validarForm(schema, values) {
      let returnValue = true;
      for (let i = 0; i < schema.length; i++) {
        let element = schema[i];
        if (element.isRequired) {
          if (
            values[element.id] == "" ||
            values[element.id] === null ||
            values[element.id].length === 0
          ) {
            if (this.errorRequeridos == "") {
              if(element.placeholder){
                this.errorRequeridos =
                  " El campo " + element.placeholder + " es requerido <br>";
              }else{
                this.errorRequeridos =
                  " El campo " + element.label + " es requerido <br>";
              }
            } else {
              if(element.placeholder){
                this.errorRequeridos +=
                  " El campo " + element.placeholder + " es requerido <br>";
              }else{
                this.errorRequeridos +=
                  " El campo " + element.label + " es requerido <br>";
              }
            }
            returnValue = false;
          }
        }
      }
      return returnValue;
    },

    validarFormularios() {
      this.errorRequeridos = "";
      this.validarForm(this.schemaRegister, this.valuesRegister)
    },
  },

  mounted() {
    const { offsetWidth, offsetHeight } = document.querySelector("#app");
    if (offsetWidth <= 600) {
      setTimeout(function () {
        let classNames = document.getElementsByClassName("blitz-field");
        for (let i = 0; i < classNames.length; i++) {
          document.getElementsByClassName("blitz-field")[i].style = "";
        }
      }, 200);
    }

    let classNames = document.getElementsByClassName("blitz-form__form");
    for (let i = 0; i < classNames.length; i++) {
      document.getElementsByClassName("blitz-form__form")[i].style =
        "display: block";
    }

    getClientes().then(
      function (response) {
        this.userSearch = response.data;
      }.bind(this)
    );

    getDocumento().then(
      function (response) {
        const indice = 1;
        const itemsOptionsTemplate = {
          value: "",
          text: "Tipo documento",
          disabled: true,

        };

        const options = [itemsOptionsTemplate];

        for (let i = 0; i < response.data.length; i++) {
          // Solución planteada así por un error de referencia de memoria
          const itemsOptionsTemplate = {
            value: "",
            text: "",
          };
          const subTemplate = itemsOptionsTemplate;
          itemsOptionsTemplate.value = response.data[i].ID_TIPO_DOCUMENTO;
          subTemplate.text = response.data[i].DESC_TIPO_DOCUMENTO;
          options.push(subTemplate);
        }

        this.schemaRegister[indice] = {
          id: "tipoDocumento",
          span: true,
          component: "VSelect",
          label: "Tipo de documento",
          style: tenPercentWFloat,
          items: options,
          isRequired: true
        };

        this.recargarFormulario();
      }.bind(this)
    ),
      getRoles().then(
        function (response) {
          const indice = 7;
          const itemsOptionsTemplate = {
            value: "",
            text: "Seleccione un rol",
            disabled: true,
          };

          const options = [itemsOptionsTemplate];

          for (let i = 0; i < response.data.length; i++) {
            // Solución planteada así por un error de referencia de memoria
            const itemsOptionsTemplate = {
              value: "",
              text: "",
            };
            const subTemplate = itemsOptionsTemplate;
            itemsOptionsTemplate.value = response.data[i].id;
            subTemplate.text = response.data[i].nombre;
            options.push(subTemplate);
          }

          this.schemaRegister[indice] = {
            id: "rol",
            span: true,
            component: "VSelect",
            label: "Rol",
            style: thirtytreePercentWFloat,
            items: options,
          };

          this.recargarFormulario();
        }.bind(this)
      );
    this.consultaServicio();
  },

  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },

  watch: {
    valuesRegister(newValue, oldValue) {
      this.valuesRegister.serviciosAsociados = oldValue.serviciosAsociados;
      this.valuesRegister.userSearchSelected = oldValue.userSearchSelected;

      const regex = /^[0-9]+$/;

      if(parseInt(this.valuesRegister.fechaCorte) < 1 || !regex.test(this.valuesRegister.fechaCorte)){
        this.valuesRegister.fechaCorte = 1;
        this.recargarFormulario();
      }else if(parseInt(this.valuesRegister.fechaCorte) > 28){
        this.valuesRegister.fechaCorte = 28;
        this.recargarFormulario();
      }

      this.validarFormularios();

    },
  },

  components: {
    FullBoxVue,
    BlitzForm,
    searchUserMultipleVue,
  },
};
</script>

<style></style>
