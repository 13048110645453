import GenerCampos from '../generCampos'

const generCampos = new GenerCampos()

var DomiciliariaDeIngreso = [
    {
       titulo: 'INFORMACIÓN PERSONAL',
       campos: [
           {
              label: 'FOTO DEL USUARIO',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false,
              justify: 'center'
           },
           {
            label: 'Nombres y Apellidos',
            tipo: 'texto',
            valor: null,
            idCampo:6,
            ignorar: false
         },
            {
             label: 'Tipo de documento',
             tipo: 'desplegable',
             lista: 'documento',
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'No documento',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'Lugar de Expedición',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'Fecha de Expedición',
             tipo: 'fecha',
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'Fecha de Nacimiento',
             tipo: 'fecha',
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'Lugar de Nacimiento',
             tipo: 'desplegable',
             lista: 'ciudad',
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'Edad',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'Género',
             tipo: 'desplegable',
             lista: ['Femenino', 'Masculino', 'Otro'],
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'Estado civil',
             tipo: 'desplegable',
             lista: ['Casado', 'Soltero', 'Unión libre'],
             valor: null,
             ignorar: false,
             obligatorio:  false
            },
            {
             label: 'Rh',
             tipo: 'desplegable',
             valor: null,
             ignorar: false,
             lista: ['A+','O+','B+','AB+','A-','O-','B-','AB-']
           },
           {
             label: 'Libreta militar',
             tipo: 'desplegable',
             valor: null,
             ignorar: false,
             lista: ['Si', 'NO', 'No aplica']
           },
           {
             label: 'Clase',
             tipo: 'desplegable',
             valor: null,
             ignorar: false,
             lista: ['Primera clase', 'Segunda clase', 'Otra', 'No aplica']
           },
           {
             label: 'Teléfono Fijo',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'Celular',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'EPS',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'Fondo de Pensiones',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'ARL',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'C. compensación',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'Cesantías',
             tipo: 'texto',
             valor: null,
             ignorar: false,
           },
           {
             label: 'Señales particulares',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
           }
       ]
    },
    {
       titulo: 'CONCEPTO GENERAL DE LA VISITA',
       campos: [
           {
              label: '',
              tipo: 'ConceptoGeneralVisita',
              confiabilidad: {
                 label: 'Confiabilidad',
                 tipo: 'desplegable',
                 lista: ['Alta','Media','Baja'],
                 valor: null
              },
              riesgo: {
                 label: 'Riesgo',
                 tipo: 'desplegable',
                 lista: ['Alto','Medio','Bajo'],
                 valor: null
              },
              observacion: {
                 label: 'Observación',
                 tipo: 'textoLargo',
                 valor: null
              },
              ignorar: false
           }
       ]
    },
    {
       titulo: 'INFORMACIÓN MIGRATORIA',
       campos: [
           {
              label: '',
              tipo: 'tabla',
              ignorar: false,
              cabeceras: [],
              formato: [],
              listaValores: [
                 [
                    { label: '', tipo: 'texto',valor: 'Pasaporte', noEditable: true},
                    { label: '', tipo: 'desplegable',valor: 'Pasaporte', lista: ['Si', 'No', 'No aplica']},
                    { label: 'No', tipo: 'texto', valor: null},
                    { label: 'País', tipo: 'texto', valor: null},
                    { label: '', tipo: 'texto', valor: 'Número de salidas', noEditable: true},
                    { label: '', tipo: 'texto', valor: null},
                    { label: '', tipo: 'texto', valor: 'Motivo', noEditable: true},
                    { label: '', tipo: 'texto', valor: null},
                 ],
                 [
                    { label: '', tipo: 'texto',valor: 'Visa', noEditable: true},
                    { label: '', tipo: 'desplegable',valor: 'Pasaporte', lista: ['Si', 'No', 'No aplica']},
                    { label: 'No', tipo: 'texto', valor: null},
                    { label: 'País', tipo: 'texto', valor: null},
                    { label: '', tipo: 'texto', valor: 'Número de salidas', noEditable: true},
                    { label: '', tipo: 'texto', valor: null},
                    { label: '', tipo: 'texto', valor: 'Motivo', noEditable: true},
                    { label: '', tipo: 'texto', valor: null},
                 ],
              ],
              noEditable: true
           },
           {
             label: 'Observaciones',
             valor: null,
             tipo: 'textoLargo',
             ignorar: false,
             wx: 3
           }
       ]
    },
    {
       titulo: 'INFORMACIÓN DE TRÁNSITO',
       campos: [
          {
             label: 'Licencia de conducción',
             tipo: 'desplegable',
             valor: null,
             lista: ['Si', 'No'],
             ignorar: false
           },
           {
             label: 'Categoría',
             tipo: 'desplegable',
             valor: null,
             lista: ['A1','A2','B1','B2','B3','C1','C2','C3'],
             ignorar: false
           },
           {
             label: 'Fecha de vencimiento',
             tipo: 'fecha',
             valor: null,
             ignorar: false
           },
           {
             label: 'Comparendos',
             tipo: 'tabla',
             ignorar: false,
             cabeceras: ['Fecha', 'Estado', 'Concepto', 'Valor'],
             listaValores: [],
             formato: [
               {
                 label: 'Fecha',
                 tipo: 'fecha',
                 valor: null,
               },
               {
                 label: 'Estado',
                 tipo: 'desplegable',
                 lista: ['Activo', 'Inactivo'],
                 valor: null
               },
               {
                 label: 'Concepto',
                 tipo: 'texto',
                 valor: null,
               },
               {
                 label: 'valor',
                 tipo: 'numero',
                 simbolo: '$',
                 valor: null,
               }
             ]
           },
           {
               label: 'Observación',
               tipo: 'textoLargo',
               valor: null,
               ignorar: false,
               obligatorio: false,
               wx: 3
            }
       ]
    },
    {
       titulo: 'INFORMACIÓN ACADÉMICA',
       campos: [
           {
              label: '',
              tipo:'duplicaCampos',
              campo: {
                 label: '',
                 tipo: 'form',
                 ignorar: false,
                 form: {
                    titulo: '',
                    campos: [
                       {label: 'Nivel educativo', tipo: 'texto', valor: null},
                       {label: 'Título obtenido', tipo: 'texto', valor: null},
                       {label: 'Modalidad', tipo: 'texto', valor: null},
                       {label: 'Institución', tipo: 'texto', valor: null},
                       {label: 'País - Ciudad', tipo: 'texto', valor: null},
                       {label: 'Año de graduación', tipo: 'texto', valor: null},
                       {label: 'Icfes', tipo: 'texto', valor: null},
                       {label: 'Doc. de presentación', tipo: 'texto', valor: null},
                       {
                          label: 'Observación',
                          tipo: 'textoLargo',
                          valor: null,
                          ignorar: false,
                          obligatorio:  false,
                          wx: 3
                       }
                    ]
                 }
              },
              listadoCampos: [],
              ignorar: false
           },
           {
              label: '',
              tipo: 'inf',
              valor: 'Informacion Academica Sin Icfes',
              ignorar: false
           },
           {
              label: '',
              tipo:'duplicaCampos',
              campo: {
                 label: '',
                 tipo: 'form',
                 ignorar: false,
                 form: {
                    titulo: '',
                    campos: [
                       {label: 'Nivel educativo', tipo: 'texto', valor: null},
                       {label: 'Título obtenido', tipo: 'texto', valor: null},
                       {label: 'Modalidad', tipo: 'texto', valor: null},
                       {label: 'Institución', tipo: 'texto', valor: null},
                       {label: 'País', tipo: 'texto', valor: null},
                       {label: 'Año de graduación', tipo: 'texto', valor: null},
                       {
                          label: 'Observación',
                          tipo: 'textoLargo',
                          valor: null,
                          ignorar: false,
                          obligatorio:  false
                       }
                    ]
                 }
              },
              listadoCampos: [],
              ignorar: false
           }
       ]
    },
    {
       titulo: 'EXPERIENCIA LABORAL',
       campos: [
           {
              label: '',
              tipo:'duplicaCampos',
              campo: {
                 label: '',
                 tipo: 'form',
                 ignorar: false,
                 form: {
                    titulo: '',
                    campos: [
                       {label: 'Empresa', tipo: 'texto', valor: null},
                       {label: 'Fecha Inicio', tipo: 'fecha', valor: null},
                       {label: 'Fecha Retiro', tipo: 'fecha', valor: null},

                       {label: 'Cargo', tipo: 'texto', valor: null},
                       {label: 'Teléfono Empresa', tipo: 'texto', valor: null},
                       {label: 'Jefe Inmediato', tipo: 'texto', valor: null},

                       {label: 'Teléfono Jefe Inmediato', tipo: 'texto', valor: null},
                       {label: 'Motivo de Retiro', tipo: 'texto', valor: null},
                       {label: 'Tipo de Contrato', tipo: 'texto', valor: null},
                       {
                             label: 'Funciones Realizadas',
                             tipo: 'textoLargo',
                             valor: null,
                             ignorar: false,
                             obligatorio:  false
                       },
                       {
                             label: 'Concepto',
                             tipo: 'textoLargo',
                             valor: null,
                             ignorar: false,
                             obligatorio:  false
                       }
                    ]
                 }
              },
              listadoCampos: [],
              ignorar: false
           }
       ]
    },
    {
       titulo: 'VACÍOS LABORALES',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             ignorar: false,
             cabeceras: ['Fecha Inicio', 'Fecha Final', 'Actividad Realizada'],
             listaValores: [],
             columnasPeque: [0,1],
             formato: [
                {label: 'Fecha Inicio', tipo: 'fecha', valor: null},
                {label: 'Fecha Final', tipo: 'fecha', valor: null},
                {label: 'Actividad Realizada', tipo: 'texto', valor: null}
             ]
          }
       ]
    },
    {
       titulo: 'INFORMACIÓN VIVIENDA',
       campos: [
          {
             label: 'Departamento',
             tipo: 'desplegable',
             valor: null,
             lista: 'departamento',
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Ciudad',
             tipo: 'desplegable',
             valor: null,
             lista: 'ciudad',
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Barrio',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx:1
          },
          {
             label: 'Dirección',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Tenencia',
             tipo: 'desplegable',
             valor: null,
             lista: ['Propia', 'Alquilada', 'Familiar'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Tiempo Permanencia',
             tipo: 'texto',
             valor: null,
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Área',
             tipo: 'desplegable',
             lista: ['Urbana', 'Rural'],
             valor: null,
             ignorar: false,
             obligatorio: false
          }
       ]
    },
    {
       titulo: 'INFORMACIÓN DEL SECTOR/BARRIO',
       campos: [
           {
              label: '',
              tipo: 'tabla',
              ignorar: false,
              cabeceras: [],
              listaValores: [
                 [
                    {label: '',tipo: 'texto',valor: "Estrado",noEditable: true},
                    {
                       label: 'Estrado',
                       tipo: 'desplegable',
                       valor: null,
                       lista: ['1','2','3','4','5','6'],
                       ignorar: false,
                       obligatorio: false
                    },
                    {label: '',tipo: 'texto',valor: "Tipo Sector",noEditable: true},
                    {
                       label: 'Tipo Sector',
                       tipo: 'desplegable',
                       valor: null,
                       lista: ['Residencial', 'Comercial', 'Industrial', 'Militar'],
                       ignorar: false,
                       obligatorio: false
                    },
                    {label: '',tipo: 'texto',valor: "Vigilancia",noEditable: true},
                    {
                       label: 'Vigilancia',
                       tipo: 'desplegable',
                       valor: null,
                       lista: ['Si', 'No'],
                       ignorar: false,
                       obligatorio: false
                    }
                 ]
              ],
              formato: [],
              noEditable: true
           },
           {
              label: '',
              tipo: 'tabla',
              ignorar: false,
              cabeceras: [],
              listaValores: [
                 [
                    {label: '',tipo: 'texto',valor: "Estado vías peatonales",noEditable: true},
                    {
                       label: 'Estado vías peatonales',
                       tipo: 'desplegable',
                       valor: null,
                       lista: ['Excelente', 'Aceptable', 'Deficiente'],
                       ignorar: false,
                       obligatorio: false
                    },
                    {label: '',tipo: 'texto',valor: "Estado vías vehiculares",noEditable: true},
                    {
                       label: 'Estado vías vehiculares',
                       tipo: 'desplegable',
                       valor: null,
                       lista: ['Excelente', 'Aceptable', 'Deficiente'],
                       ignorar: false,
                       obligatorio: false
                    }
                 ]
              ],
              formato: [],
              noEditable: true
           },
       ]
    },
    {
        titulo: 'DISTRIBUCIÓN DE LA VIVIENDA',
        campos: [
           {
              label: '',
              tipo: 'tabla',
              ignorar: false,
              cabeceras: [],
              formato: [],
              listaValores: [
                 [
                    {
                       label: '',
                       tipo: 'texto',
                       valor: 'Sala',
                       noEditable: true
                    },
                    {
                       label: '',
                       tipo: 'numero',
                       valor: null
                    },
                    {
                       label: '',
                       tipo: 'texto',
                       valor: 'Comedor',
                       noEditable: true
                    },
                    {
                       label: '',
                       tipo: 'numero',
                       valor: null
                    },
                    {
                       label: '',
                       tipo: 'texto',
                       valor: 'Habitaciones',
                       noEditable: true
                    },
                    {
                       label: '',
                       tipo: 'numero',
                       valor: null
                    },
                    {
                       label: '',
                       tipo: 'texto',
                       valor: 'Estudio',
                       noEditable: true
                    },
                    {
                       label: '',
                       tipo: 'numero',
                       valor: null
                    },
                    {
                       label: '',
                       tipo: 'texto',
                       valor: 'Baños',
                       noEditable: true
                    },
                    {
                       label: '',
                       tipo: 'numero',
                       valor: null
                    }
                 ],
                 [
                    {
                       label: ' ',
                       tipo: 'texto',
                       valor: 'Cocina',
                       noEditable: true
                    },
                    {
                       label: '',
                       tipo: 'numero',
                       valor: null
                    },
                    {
                       label: '',
                       tipo: 'texto',
                       valor: 'Patio',
                       noEditable: true
                    },
                    {
                       label: '',
                       tipo: 'numero',
                       valor: null
                    },
                    {
                       label: '',
                       tipo: 'texto',
                       valor: 'Terraza',
                       noEditable: true
                    },
                    {
                       label: '',
                       tipo: 'numero',
                       valor: null
                    },
                    {
                       label: '',
                       tipo: 'texto',
                       valor: 'Garaje',
                       noEditable: true
                    },
                    {
                       label: '',
                       tipo: 'numero',
                       valor: null
                    },
                    {
                       label: '',
                       tipo: 'texto',
                       valor: 'Niveles',
                       noEditable: true
                    },
                    {
                       label: '',
                       tipo: 'numero',
                       valor: null
                    }
                 ]
              ],
              noEditable: true
           }
        ]
     },
     {
        titulo: 'SERVICIOS PÚBLICOS',
        campos: [
            {
               label: '',
               tipo: 'tabla',
               ignorar: false,
               cabeceras: [],
               formato: [],
               listaValores: [
                  [
                     {
                        label: '',
                        tipo: 'texto',
                        valor: 'Agua',
                        noEditable: true
                     },
                     {
                        label: '',
                        tipo: 'desplegable',
                        valor: null,
                        lista: ['Si', 'No'],
                        ignorar: false,
                        obligatorio: false
                     },
                     {
                        label: '',
                        tipo: 'texto',
                        valor: 'Alcantarillado',
                        noEditable: true
                     },
                     {
                        label: '',
                        tipo: 'desplegable',
                        valor: null,
                        lista: ['Si', 'No'],
                        ignorar: false,
                        obligatorio: false
                     },
                     {
                        label: '',
                        tipo: 'texto',
                        valor: 'Teléfono',
                        noEditable: true
                     },
                     {
                        label: '',
                        tipo: 'desplegable',
                        valor: null,
                        lista: ['Si', 'No'],
                        ignorar: false,
                        obligatorio: false
                     },
                     {
                        label: '',
                        tipo: 'texto',
                        valor: 'Internet',
                        noEditable: true
                     },
                     {
                        label: '',
                        tipo: 'desplegable',
                        valor: null,
                        lista: ['Si', 'No'],
                        ignorar: false,
                        obligatorio: false
                     },
                     {
                        label: '',
                        tipo: 'texto',
                        valor: 'Recolección Basuras',
                        noEditable: true
                     },
                     {
                        label: '',
                        tipo: 'desplegable',
                        valor: null,
                        lista: ['Si', 'No'],
                        ignorar: false,
                        obligatorio: false
                     }
                  ]
               ],
               noEditable: true
            }
        ]
     },
    {
       titulo: 'EVALUACIÓN VIVIENDA',
       campos: [
          {
             label: 'Estado estructural de la vivienda',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Proporción hab. /No habitaciones',
             tipo: 'desplegable',
             valor: null,
             lista: ['Si', 'No'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Orden y aseo',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Electrodomésticos',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Servicios públicos',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Seguridad de la vivienda',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Mobiliario',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Análisis de la vivienda',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 3
          }
       ]
    },
    {
       titulo: 'EVALUACIÓN DEL SECTOR /BARRIO',
       campos: [
          {
             label: 'Vías de Acceso',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Medios de transporte',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Ubicación',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Percepción de Seguridad',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Zonas comunes y de recreación',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Centros de atención a emergencias',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Salubridad',
             tipo: 'desplegable',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false
          },
          {
             label: 'Análisis del sector /barrio',
             tipo: 'textoLargo',
             valor: null,
             lista: ['Excelente', 'Aceptable', 'Deficiente'],
             ignorar: false,
             obligatorio: false,
             wx: 3
          }
       ]
    },
    {
        titulo: 'REGISTRO FOTOGRÁFICO',
        campos: [
           {
           tipo: 'saltoPagina'
           },
           {
              label: 'CAPTURA UBICACIÓN GEOGRÁFICA',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false
            },
           {
              label: 'Foto 1',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false,
              ancho: '49%'
           },
           {
              label: 'Foto 2',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false,
              ancho: '49%'
           },
           {
              label: 'Foto 3',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false,
              ancho: '49%'
           },
           {
              label: 'Foto 4',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false,
              ancho: '49%'
           },
           {
              label: 'Observación',
              tipo: 'textoLargo',
              valor: null,
              ignorar: false,
              obligatorio: false
           },
        ]
     },
    {
       titulo: 'INFORMACIÓN FAMILIAR',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             ignorar: false,
             cabeceras: ['Parentesco', 'Nombres', 'Edad', 'Ocupación', 'Teléfono', 'Ciudad de residencia', 'Convivencia'],
             listaValores: [],
             formato: [
                {label: 'Parentesco', tipo: 'texto', valor: null},
                {label: 'Nombres', tipo: 'texto', valor: null},
                {label: 'Edad', tipo: 'texto', valor: null},
                {label: 'Ocupación', tipo: 'texto', valor: null},
                {label: 'Teléfono', tipo: 'texto', valor: null},
                {label: 'Ciudad de residencia', tipo: 'texto', valor: null},
                {label: 'Convivencia', tipo: 'texto', valor: null}
             ],
             listaTotales: []
          },
          {
             label: 'Observación',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio: false
          }
       ]
    },
    {
        titulo: 'INFORMACIÓN FINANCIERA',
        campos: [
           {
              label: 'Número de personas que dependen económica de usted',
              tipo: 'numero',
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Ingreso principal',
              tipo: 'numero',
              columnaSumatoria: 16,
              simbolo: '$',
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Concepto',
              tipo: 'texto',
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Aporte',
              tipo: 'numero',
              columnaSumatoria: 17,
              simbolo: '$',
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Otros Ingresos',
              tipo: 'tabla',
              ignorar: false,
              cabeceras: ['Ingreso', 'Concepto', 'Aporte'],
              listaValores: [],
              formato: [
                 {label: 'Ingreso', tipo: 'numero', sumatorio: true, columnaSumatoria: 16, valor: null, simbolo: '$'},
                 {label: 'Concepto', tipo: 'texto', valor: null},
                 {label: 'Aporte', tipo: 'numero', sumatorio: true, columnaSumatoria: 17, valor: null, simbolo: '$'}
              ],
              listaTotales: []
           },
           {
              label: 'Total Ingresos',
              tipo: 'numero',
              valor: null,
              simbolo: '$',
              columnaSumatoria: 30,
              resultadoSumatoria: 16,
              noEditable: true,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Total Aportes',
              tipo: 'numero',
              simbolo: '$',
              columnaSumatoria: 30,
              resultadoSumatoria: 17,
              noEditable: true,
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Observaciones',
              tipo: 'textoLargo',
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Ingresos y aportes grupo familiar',
              tipo: 'tabla',
              ignorar: false,
              cabeceras: ['Nombre', 'Parentesco', 'Ingreso', 'Aporte'],
              listaValores: [],
              formato: [
                 {label: 'Nombre', tipo: 'texto', valor: null},
                 {label: 'Parentesco', tipo: 'texto', valor: null},
                 {label: 'Ingreso', sumatorio: true, columnaSumatoria: 18, simbolo: '$', tipo: 'numero', valor: null},
                 {label: 'Aporte', sumatorio: true, columnaSumatoria: 19, simbolo: '$', tipo: 'numero', valor: null}
              ],
              listaTotales: []
           },
           {
              label: 'Total Ingresos',
              tipo: 'numero',
              simbolo: '$',
              resultadoSumatoria: 18,
              noEditable: true,
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Total Aportes',
              tipo: 'numero',
              simbolo: '$',
              resultadoSumatoria: 19,
              noEditable: true,
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Observaciones',
              tipo: 'textoLargo',
              valor: null,
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'Propiedades inmuebles',
              tipo: 'tabla',
              ignorar: false,
              cabeceras: ['Tipo', 'Dirección', 'Ciudad', 'Valor'],
              listaValores: [],
              formato: [
                 {label: 'Tipo', tipo: 'desplegable', lista: ['Casa','Apartamento','Lote','Local','Bodega','Parqueadero','Otro'], valor: null},
                 {label: 'Dirección', tipo: 'texto', valor: null},
                 {label: 'Ciudad', tipo: 'texto', valor: null},
                 {label: 'Valor', tipo: 'numero', sumatorio: true, columnaSumatoria: 20, valor: null, simbolo: '$'}
              ],
              listaTotales: []
           },
           {
              label: 'Total Valor Inmuebles',
              tipo: 'numero',
              simbolo: '$',
              valor: null,
              columnaSumatoria: 60,
              resultadoSumatoria: 20,
              noEditable: true,
              ignorar: false
           },
           {
              label: 'Propiedades vehículos',
              tipo: 'tabla',
              ignorar: false,
              cabeceras: ['Tipo', 'Clase', 'Marca', 'Modelo', 'Placa', 'Valor'],
              listaValores: [],
              formato: [
                 {label: 'Tipo', tipo: 'desplegable', lista: ['Carro', 'Camioneta', 'Moto', 'Camión', 'Tracto mula', 'Otro'], valor: null},
                 {label: 'Clase', tipo: 'texto', valor: null},
                 {label: 'Marca', tipo: 'texto', valor: null},
                 {label: 'Modelo', tipo: 'texto', valor: null},
                 {label: 'Placa', tipo: 'texto', valor: null},
                 {label: 'Valor', tipo: 'numero', valor: null, sumatorio: true, columnaSumatoria: 21, simbolo: '$'}
              ]
           },
           {
              label: 'Total Propiedades Vehículos',
              tipo: 'numero',
              resultadoSumatoria: 21,
              columnaSumatoria: 60,
              simbolo: '$',
              noEditable: true,
              valor: null,
              ignorar: false
           },
           {
              label: 'Total Propiedades Patrimonio',
              tipo: 'numero',
              simbolo: '$',
              resultadoSumatoria: 60,
              noEditable: true,
              valor: null,
              ignorar: false
           },
           generCampos.darCampoTextoLargo('Observación',null,-1,false,false).toJSON()
        ]
     },
     {
        titulo: 'INFORMACIÓN CREDITICIA',
        campos: [
            {
               label: '',
               tipo: 'tabla',
               ignorar: false,
               cabeceras: ['Tipo de Obligación', 'Entidad', 'Valor Total', 'Cuota mensual', 'Tiempo en mora'],
               listaValores: [],
               formato: [
                  {label: 'Tipo de Obligación', tipo: 'texto', valor: null},
                  {label: 'Entidad', tipo: 'texto', valor: null},
                  {label: 'Valor Total Obligación', tipo: 'numero', columnaSumatoria: 65, simbolo: '$', valor: null},
                  {label: 'Cuota Mensual', tipo: 'numero', columnaSumatoria: 68, simbolo: '$', valor: null},
                  {label: 'Tiempo en Mora', tipo: 'texto', valor: null}
               ],
               listaTotales: []
            },
            {
               label: 'Total Obligación',
               tipo: 'numero',
               resultadoSumatoria: 65,
               simbolo: '$',
               valor: null,
               noEditable: true
            },
            {
               label: 'Total Cuota Mensual',
               tipo: 'numero',
               resultadoSumatoria: 68,
               valor: null,
               noEditable: true
            },
            {
               label: 'Total Tiempo de Mora',
               tipo: 'texto',
               valor: '',
            },
            {
               label: 'Observaciones',
               tipo: 'textoLargo',
               valor: null,
               ignorar: false,
               obligatorio: false
            }
        ]
     },
    {
       titulo: 'INFORMACIÓN REFERENCIAS PERSONALES Y FAMILIARES',
       campos: [

          {
            label: '',
            tipo:'duplicaCampos',
            campo: {
                label: '',
                tipo: 'form',
                ignorar: false,
                form: {
                    titulo: '',
                    campos: [
                      {label: 'Nombre', tipo: 'texto', valor: null},
                      {label: 'Edad', tipo: 'texto', valor: null},
                      {label: 'Parentesco', tipo: 'texto', valor: null},
                      {label: 'Tiempo', tipo: 'texto', valor: null},
                      {label: 'Teléfono', tipo: 'texto', valor: null},
                      {label: 'Ciudad', tipo: 'texto', valor: null},
                      {label: 'Ocupación', tipo: 'texto', valor: null},
                      {
                          label: 'Observación',
                          tipo: 'textoLargo',
                          valor: null,
                          ignorar: false,
                          obligatorio:  false,
                      }
                    ]
                }
            },

            listadoCampos: [],
            ignorar: false
          },
          {
             label: 'Observación',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 3
          },
          {
             label: 'EVALUACIÓN REFERENCIAS VECINOS',
             tipo: 'tabla',
             ignorar: false,
             cabeceras: ['Nombre', 'Convivencia', 'Confianza', 'Seguridad', 'Valores','Observación'],
             listaValores: [],
             formato: [
                {label: 'Nombre', tipo: 'texto', valor: null},
                {label: 'Convivencia', tipo: 'texto', valor: null},
                {label: 'Confianza', tipo: 'texto', valor: null},
                {label: 'Seguridad', tipo: 'texto', valor: null},
                {label: 'Valores', tipo: 'texto', valor: null},
                {
                  label: 'Observación',
                  tipo: 'textoLargo',
                  valor: null,
                  ignorar: false,
                  obligatorio: false,
                  wx: 3
               },
             ],
             listaTotales: []
          },
          {
             label: 'Observación',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 3
          }
       ]
    },
    {
       titulo: 'INFORMACIÓN JUDICIAL',
       campos: [
          {
             label: 'Procesos Judiciales propios',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Procesos judiciales familiares',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Observación',
             tipo: 'textoLargo',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 3
          },
       ]
    },
    {
        titulo: 'ASPIRACIONES Y PROYECTOS',
        campos: [
           {
              label: '',
              tipo: 'tabla',
              cabeceras: [],
              formato:[],
              listaValores: [
                 [
                    {label:'', tipo: 'textoLargo', noEditable: true, valor: 'Personales'},
                    {label:'', tipo: 'textoLargo', valor: null},
                 ],
                 [
                    {label:'', tipo: 'textoLargo', noEditable: true, valor: 'Familiares'},
                    {label:'', tipo: 'textoLargo', valor: null},
                 ]
              ],
              columnasPeque: [0],
              noEditable: true,
              ignorar: false,
           }
        ]
     },
    {
       titulo: 'OCUPACIÓN TIEMPO LIBRE',
       campos: [
          {
             label: 'Práctica de deportes',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Hobbies o pasatiempos',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Gremios / asociaciones',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Hábitos de lectura',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Consumo de sustancias psicoactivas',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Consumo de bebidas alcohólicas',
             tipo: 'desplegable',
             lista: ['Si', 'No'],
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 1
          },
          {
             label: 'Observación',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false,
             obligatorio: false,
             wx: 3
          },
       ]
    },
    {
        titulo: 'ASPECTOS GENERALES DEL ENTREVISTADO',
        campos: [
        {
           label: 'Presentación Personal',
           tipo: 'desplegable',
           valor: null,
           lista: ['Excelente', 'Aceptable', 'Deficiente'],
           ignorar: false,
           obligatorio: false
        },
        {
           label: 'Información suministrada',
           tipo: 'desplegable',
           valor: null,
           lista: ['Excelente', 'Aceptable', 'Deficiente'],
           ignorar: false,
           obligatorio: false
        },
        {
           label: 'Expresión verbal',
           tipo: 'desplegable',
           valor: null,
           lista: ['Excelente', 'Aceptable', 'Deficiente'],
           ignorar: false,
           obligatorio: false
        },
        {
           label: 'Colaboración ',
           tipo: 'desplegable',
           valor: null,
           lista: ['Excelente', 'Aceptable', 'Deficiente'],
           ignorar: false,
           obligatorio: false
        },
        {
           label: 'Observación ',
           tipo: 'textoLargo',
           valor: null,
           lista: ['Excelente', 'Aceptable', 'Deficiente'],
           ignorar: false,
           obligatorio: false
        },
        ]
     },
     {
        titulo: 'CONCEPTO GENERAL DE LA VISITA',
        campos: [
           {
              label: '',
              tipo: 'textoLargo',
              valor: null,
              lista: ['Excelente', 'Aceptable', 'Deficiente'],
              ignorar: false,
              obligatorio: false
           },
           {
              label: 'FIRMA',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false
           }
        ]
     },
     {
        titulo: 'DOCUMENTOS',
        campos: [
           {
              tipo: 'saltoPagina'
           },
           {
           label: 'AUTORIZACIÓN',
           tipo: 'archivo',
           permitido: 'image/png, image/jpeg',
           valor: null,
           ignorar: false,
           obligatorio: false
           },
           {
           label: 'CÉDULA',
           tipo: 'archivo',
           permitido: 'image/png, image/jpeg',
           valor: null,
           ignorar: false,
           obligatorio: false
           },
           {
           label: 'PASAPORTE',
           tipo: 'archivo',
           permitido: 'image/png, image/jpeg',
           valor: null,
           ignorar: false,
           obligatorio: false
           },
           {
           label: 'DIPLOMA BACHILLER',
           tipo: 'archivo',
           permitido: 'image/png, image/jpeg',
           valor: null,
           ignorar: false,
           obligatorio: false
           },
           {
           label: 'ICFES',
           tipo: 'archivo',
           permitido: 'image/png, image/jpeg',
           valor: null,
           ignorar: false,
           obligatorio: false
           },
           {
           label: 'ACTA DE GRADO BACHILLERATO',
           tipo: 'archivo',
           permitido: 'image/png, image/jpeg',
           valor: null,
           ignorar: false,
           obligatorio: false
           },
           {
           label: 'CERTIFICACIÓN LABORAL',
           tipo: 'archivo',
           permitido: 'image/png, image/jpeg',
           valor: null,
           ignorar: false,
           obligatorio: false
           },
           {
           label: 'OTROS SOPORTES',
           tipo: 'archivo',
           permitido: 'image/png, image/jpeg',
           valor: null,
           ignorar: false,
           obligatorio: false
           },
           {
           label: 'CONTRATO APRENDIZAJE',
           tipo: 'archivo',
           permitido: 'image/png, image/jpeg',
           valor: null,
           ignorar: false,
           obligatorio: false
           },
        ]
     }
]

export default(DomiciliariaDeIngreso)
