<template>
  <div :key="componentKey" v-if="valido">
    <FullBoxVue>
      <h1>¡Hola {{ datosWCandidato.nombreCandidato }}!</h1>
      <v-btn @click="descargarExcel" download color="primary"
        >Haga click aquí para descargar el formato de autorización</v-btn
      >
      <br />
      <p>
        Por favor suba los archivos relacionados con la solicitud del servicio
        {{ datosWCandidato.NOMBRE_SERVICIO }}/{{
          datosWCandidato.NOMBRE_SUBSERVICIO
        }}
      </p>
    </FullBoxVue>
    <FullBoxVue class="shadowHover">
      <div>
        <h1>Subir los archivos requeridos</h1>
      </div>
    </FullBoxVue>

    <div v-for="(archivo, index) in archivosRequeridos" :key="index">
      <MiddleBox class="shadowHover">
        <h2>{{ archivo.nombreCortado }}</h2>
        <p>{{ archivo.descripcion }}</p>
        <input type="file" @change="onFileChange($event, index)" />
        &nbsp;
        <v-btn color="success" @click="subirArchivo(index, archivo.nombre, archivo.nombreCortado)"
          >Subir archivo</v-btn
        >

        <v-container>
          <h2>Archivos subidos</h2>
          <br /><br />
          <v-data-table :headers="headers" :items="archivo.archivosSubidos">
            <template v-slot:item.comentarios="{ item }">
              <v-text-field
                v-model="item.comentarios"
                label="Ingrese acá las observaciones"
                @change="
                  actualizarComentariosArchivo(
                    item.id,
                    item.comentarios
                  )
                "
              ></v-text-field>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="text-truncate">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  @click="descargarArchivosTable(item)"
                >
                  mdi-download
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  color="error"
                  @click="eliminarArchivoTable(item)"
                >
                  mdi-delete
                </v-icon>
              </div>
            </template>
          </v-data-table>


        </v-container>
        <br />
        <hr />
        <br />
      </MiddleBox>
    </div>

    <FullBoxVue>
      Pulse el siguiente botón sólo cuando
      <b>esté seguro que subió los archivos necesarios</b>.

      <br />
      <v-btn color="error" @click="finalizarSubirArchivos()"
        >Finalizar proceso de subir archivos</v-btn
      >
    </FullBoxVue>
  </div>
  <div v-else>
    <br /><br />
    <FullBoxVue>
      <h1>Ya se subieron los archivos con éxito.</h1>
      Los archivos que NO se subieron fueron:
      <div v-for="(archivo, index) in archivosRequeridos" :key="index">
      {{ archivo }}
      </div>
    </FullBoxVue>
  </div>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";
import MiddleBox from "@/components/static/MiddleBox.vue";
import {
  getArchivosCandidato,
  subirArchivosCandidato,
  getPeticionCandidato,
  downloadFile,
  eliminarArchivosCandidato,
  finalizarSubirArchivosCandidato,
  updateObservacionesArchivoInd,
} from "@/api";
export default {
  data() {
    return {
      idSolicitudRef: "",
      infoSolicitud: {},
      infoSolicitudCandidatos: {},
      componentKey: 1,
      estadoSolicitud: null,
      estados: null,
      idSolicitud: this.$route.params.id,
      archivosSubidos: [],
      Images: null,
      valido: false,
      userSearchSelected: [],
      userSearch: [],
      datosWCandidato: {},
      archivosRequeridos: [],
      archivosSeleccionados: [],
      headers: [
      { text: 'Acciones', value: 'actions', sortable: false },
        { text: "Id", value: "id" },
        { text: "Nombre de archivo", value: "nombreArchivo" },
        { text: "Fecha de subida", value: "creado" },
        { text: "Observaciones", value: "comentarios" },
        { text: "Archivo requerido", value: "observaciones" },

      ],
    };
  },

  methods: {
    eliminarArchivoTable(data){
      this.eliminarArchivo(data.id)
    },

    descargarArchivosTable(data){
      this.descargarArchivos(data.archivo, data.nombreArchivo)
    },

    descargarExcel() {
      let filepath = "../upload/AUTORIZACION COLPRYST POLIGRAFIA 2023.pdf";
      downloadFile(filepath).then((response) => {
        let fileName = filepath.split("/")[2];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
    },

    actualizarComponente() {
      this.componentKey += 1;
    },

    actualizarComentariosArchivo(id, comentarios) {
      let bodySol = {
        observacionesAnalistaProveedor: comentarios,
        idSolicitud: id,
      };
      updateObservacionesArchivoInd(bodySol);
    },

    onFileChange(event, index) {
      let file = event.target.files[0];
      this.archivosSeleccionados[index] = file;
    },

    finalizarSubirArchivos() {
      finalizarSubirArchivosCandidato(this.idSolicitudRef).then(
        function (response) {
          window.location.reload();
        }.bind(this)
      );
    },

    realizarConsultaArchivos() {
      for (let i = 0; i < this.archivosRequeridos.length; i++) {
        this.archivosSeleccionados.push({});
      }
      for (let i = 0; i < this.archivosRequeridos.length; i++) {
        getArchivosCandidato(
          this.idSolicitudRef,
          this.archivosRequeridos[i].split("|")[0]
        ).then(
          function (response) {
            this.archivosRequeridos[i] = {
              nombre: this.archivosRequeridos[i],
              nombreCortado: this.archivosRequeridos[i].split("|")[0],
              descripcion: this.archivosRequeridos[i].split("|")[1],
              archivosSubidos: response.data,
            };
            this.actualizarComponente();
          }.bind(this)
        );
      }
    },

    eliminarArchivo(id) {
      eliminarArchivosCandidato(id).then(
        function (response) {
          window.location.reload();
        }.bind(this)
      );
    },

    realizarConsulta() {
      getPeticionCandidato(this.idSolicitud).then(
        function (response) {
          try {
            this.idSolicitudRef = response.data.id;
            this.datosWCandidato = response.data;
            this.archivosRequeridos = [];
            this.archivosRequeridos =
              this.datosWCandidato.DOCUMENTOS_REQUERIDOS.split("\n");

            if (this.idSolicitudRef != undefined) {
              this.valido = true;
              this.componentKey += 1;
              this.realizarConsultaArchivos();
            }

            if(response.data.habilitadoSubidaCandidato == 0){
              this.valido = false;
              this.componentKey += 1;
              response.data.archivosSubidos.forEach(element => {
                const indice = this.archivosRequeridos.indexOf(element.observaciones); // Obtiene el índice del elemento a eliminar

                if (indice > -1) {
                  this.archivosRequeridos.splice(indice, 1); // Elimina 1 elemento en el índice específico
                }
              });
            }
          } catch {
            this.valido = false;
          }
        }.bind(this)
      );
    },

    subirArchivo(index, descripcion, nombreCortado) {
      const formData = new FormData();
      formData.append("file", this.archivosSeleccionados[index]);
      formData.append("idSolicitud", this.idSolicitudRef);
      formData.append("nombreCortado", nombreCortado)
      formData.append("descripcion", descripcion);

      subirArchivosCandidato(formData).then(
        function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Solicitud enviada exitosamente",
          });
          window.location.reload();
        }.bind(this)
      );
    },

    descargarArchivos(filepath, fileName) {
      downloadFile(filepath).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
    },
  },

  mounted() {
    this.realizarConsulta();
  },

  components: {
    FullBoxVue,
    MiddleBox,
  },
};
</script>

<style></style>
