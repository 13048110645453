<template>
  <div class="tabla-container">
    <table class="tabla" density="compact">
      <tbody class="contenido">
        <tr >
          <th class="text-left inlineBlock" v-for="(texto,i) in campo.cabeceras" :key="i">
            {{ texto }}
          </th>
        </tr> 
        <tr>
          <th v-for="(campo,i) in campo.listaValores" :key="i">
            <RetornoCampo :campo="campo" class="campo"></RetornoCampo>
          </th>
        </tr>
        <tr>
          <th v-for="(campo,i) in campo.listaOpciones" :key="i">
            <RetornoCampo :campo="campo" class="campo"></RetornoCampo>
          </th>
        </tr>
        <tr>
          <th :colspan="campo.listaValores.length-2">
            Total puntuación por spot y resultado definitivo según p-valor (alfa) no corregido = 
          </th>
          <th colspan="2">
            <RetornoCampo :campo="campo.campoPuntuacion" class="campo"></RetornoCampo>
          </th>
        </tr>
        <tr>
          <th :colspan="campo.listaValores.length">
            <RetornoCampo :campo="campo.campoOpcionGeneral" class="campo"></RetornoCampo>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default({
  props: {
    campo: {
      default: {}
    }
  },
  name: 'evaluacionPoligrafia'
})
</script>
<style>
.tabla-container {
  overflow-x: auto;
}

.tabla {
  width: 100%;
  text-align: center;
  border: #919191 outset 1px;
  border-radius: 5px;
}

.tabla th {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
  min-width: 170px;
}

.campo {
  min-width: 170px;
}
</style>