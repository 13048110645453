<template>
  <div ref="cardContainer" class="dialog">
    <v-card class="vCard">
      <v-card-actions class="justify-end">
        <slot name="botones"></slot>
      </v-card-actions>
      <h1>
        <slot name="titulo"></slot>
      </h1>
    </v-card>
    <div class="stepper" ref="stepper">
      <v-stepper v-model="pasos" vertical>
        <template v-for="(form, i) in forms">
          <v-stepper-step
            :complete="formularioCompletado(i)"
            :step="i + 1"
            :key="i"
          >
            <button @click="irForm(i)">
              {{ i + 1 }}. {{ form.titulo }}
            </button>
          </v-stepper-step>
          <v-stepper-content :step="i + 1" :key="i + 100">
            <v-pagination
              @input="cambioPagina()"
              :length="formularioCompletado(i) ? nFormularios : 0"
              v-model="pasos"
              :total-visible="10"
            ></v-pagination>
            <CreateForm
              :idForm="idForm"
              :idIteracion="i.toString()"
              :titulo="form.titulo"
              :idSolicitud="idSolicitud"
              :campos="form.campos"
            />
            <v-pagination
              @input="cambioPagina()"
              :length="formularioCompletado(i) ? nFormularios : 0"
              v-model="pasos"
              :total-visible="10"
            ></v-pagination>
            <v-alert
              v-if="!formularioCompletado(i)"
              border="top"
              density="compact"
              prominent
              type="warning"
              >Debes completar los campos obligatorios</v-alert
            >
          </v-stepper-content>
        </template>
        <template>
          <v-stepper-step
            @input="cambioPagina()"
            :complete="pasos > nFormularios"
            :step="nFormularios"
          >
            GENERAR INFORME
          </v-stepper-step>
          <v-stepper-content :step="nFormularios">
            <v-pagination
              @input="cambioPagina()"
              :length="nFormularios"
              v-model="pasos"
              :total-visible="10"
            ></v-pagination>
            <slot name="ultimaPagina"></slot>
          </v-stepper-content>
        </template>
      </v-stepper>
    </div>
  </div>
</template>
<script>
import CreateForm from "./CreateForm.vue";

export default {
  props: {
    forms: {
      default: [],
    },
    tituloGeneral: {
      default: "",
    },
    idForm: {
      default: "",
    },
    idSolicitud: {
      default: "",
    },
  },
  components: { CreateForm },
  name: "CreateManyForm",
  created() {
    this.nFormularios = this.forms.length + 1;

  },
  data() {
    return {
      pasos: 1,
      nFormularios: 0,
      scroll: null,
      listaCamposSumados: [], //valores ya sumados
      listaCamposDependientes: [] //valores guardados
    };
  },
  mounted() {
    this.scroll = this.$refs.stepper;
    setInterval(function(){this.procesos(this.forms,true)}.bind(this), 2000);
  },
  methods: {
    formularioCompletado: function (i) {
      let completado = true;
      // desde la lista de formularios que se envia como propiedad al indice del formulario se ingresa a su llave campo
      // si por cada campo obligatorio es null o es '' se retornara como falso si no se terminara el for
      // y retornara true
      this.forms[i].campos.forEach((campo) => {
        if (campo.obligatorio === true) {
          if (!!!campo.valor) {
            //si es null, undifine or ''
            completado = false;
          }
        }
      });
      return completado;
    },
    cambioPagina: function () {
      setTimeout(() => {
        const scrollPosition = this.pasos * 70;
        this.scroll.scrollTop = scrollPosition;
      }, 1000);
    },
    irForm: function (i) {
      this.pasos = i + 1
      this.cambioPagina()
    },
    formateoMiles: function(valor) {
      //quitar caracter diferentes a numeros
      let listaNumeros = valor.toString().split("")
      let nuevoTexto = ''

      listaNumeros.forEach(caracter => {
        if ( '0123456789'.includes(caracter)){
          nuevoTexto += caracter
        }
      })

      //2)poner comas
      nuevoTexto = nuevoTexto.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return nuevoTexto
    },
    procesos: function(forms, inicializar= false){
      //inicializar sumatoria en vacio
      if (inicializar){
        this.listaCamposDependientes = []
        this.listaCamposSumados = []
      }
      //proceso general de todo el form
      forms.forEach(form => {
        this.procesosForm(form)
      })
    },
    procesosForm: function(form){
      //procesos del form
      form.campos.forEach(campo => {
        this.procesosCampo(campo)
      })
    },
    procesoCampoDuplicaCampos: function(campo){
      this.procesosCampo(campo.campo)
    },
    procesosCampo: function(campo){
      // que pasara con cada campo
      if (!campo.hasOwnProperty('tipo')){
        console.log('este campo no tiene tipo',campo)
      }
      
      if (!campo.hasOwnProperty('ignorar')){
        campo.ignorar = false
      }
      // dependencias
      if (campo.hasOwnProperty('idCampo')){
        let nuevo = {
          idCampo: campo.idCampo,
          valor: campo.valor
        }

        this.listaCamposDependientes.push(nuevo)
      }
      if (campo.hasOwnProperty('idDependiente')){
        this.listaCamposDependientes.forEach(campoDependiente =>{
          if (campoDependiente.idCampo === campo.idDependiente && campoDependiente.valor !== null){
            campo.valor = campoDependiente.valor
          }
        })
      }

      switch(campo.tipo){
        case 'numero':
          this.procesoCampoNumero(campo)
          break;
        case 'texto':
          this.procesoCampoTexto(campo)
          break;
        case 'archivo':
          this.procesoCampoTexto(campo)
          break;
        case 'fecha':
          this.procesoCampoFecha(campo)
          break;
        case 'entrevista':
          this.procesoCampoEntrevista(campo)
          break;
        case 'tabla':
          this.procesoCampoTabla(campo)
          break;
        case 'inf':
          this.procesoCampoInfo(campo)
          break;
        case 'textoLargo':
          this.procesoCampoTextoLargo(campo)
          break;
        case 'completarTexto':
          this.procesoCampoCompletarTexto(campo)
          break;
        case 'duplicaCampos':
          this.procesoCampoDuplicaCampos(campo)
          break;
        case 'evaluacionPoligrafia':
          //navigator.clipboard.writeText(JSON.stringify(campo))
          this.procesoCampoEvaluacionPoligrafia(campo)
          break;
        case 'form':
          this.procesosForm(campo.form)
      }
    },
    //clasificacion procesos de cada tipo de campo
    procesoCampoNumero: function(campo){
      //el formateo a miles se esta haciendo desde el componente

      //significa que va en la lista a sumar
      if (campo.hasOwnProperty('columnaSumatoria')){
        //se guarda el idCampo y su valor
        let idCampoSumar = campo.columnaSumatoria
        let valorASumar = 0
        //se verifica si no es null por tanto se sumara
        if(!!campo.valor === true){
          valorASumar = parseFloat(campo.valor.replace(/,/g,''))
        }
        //se usa una variable para decir que se encontro el id
        //en caso de que sea falso sebe añadir a la lista
        let seEncontro = false
        this.listaCamposSumados.forEach(jsonSumado =>{
          if (jsonSumado.columnaSumatoria === campo.columnaSumatoria){
            jsonSumado.valor += valorASumar
            seEncontro = true
          }
        })

        if( !seEncontro ){//si no se encontro en la lista
          let nuevo = {
            columnaSumatoria: idCampoSumar,
            valor: valorASumar
          }
          this.listaCamposSumados.push(nuevo)
        }
      }
      //significa que la lista debe contener la sumatoria y debe establecerce aqui
      if (campo.hasOwnProperty('resultadoSumatoria')){
        this.listaCamposSumados.forEach(jsonSumado =>{
          if (jsonSumado.columnaSumatoria === campo.resultadoSumatoria){
            campo.valor = this.formateoMiles(jsonSumado.valor)
          }
        })
      }
    },
    procesoCampoTexto: function(campo){

    },
    procesoCampoArchivo: function(campo){

    },
    procesoCampoFecha: function(campo){

    },
    procesoCampoEntrevista: function(campo){

    },
    procesoCampoTabla: function(campo){
      campo.listaValores.forEach(fila=>{
          fila.forEach(campo2 => {
            this.procesosCampo(campo2)
          })
        })
    },
    procesoCampoInfo: function(campo){

    },
    procesoCampoTextoLargo: function(campo){

    },
    procesoCampoCompletarTexto: function(campo){
      campo.secuencias.forEach(secuencia => {
            if (secuencia.tipo === 'campo'){
              if(secuencia.hasOwnProperty('campo')){
                this.procesosCampo(secuencia.campo)
              }else{
                console.error('Falta una llave "campo" en esta secuencia del tipo completarTexto')
              }
            }
          })
    },
    procesoCampoEvaluacionPoligrafia: function(campo){
      if (campo.hasOwnProperty('campoOpcionGeneral')){
        this.procesosCampo(campo.campoOpcionGeneral)
      }else{
        console.error('Falta una llave "campoOpcionGeneral" en esta secuencia del tipo evaluacionPoligrafia')
      }
      if (campo.hasOwnProperty('campoPuntuacion')){
        this.procesosCampo(campo.campoPuntuacion)
      }else{
        console.error('Falta una llave "campoPuntuacion" en esta secuencia del tipo evaluacionPoligrafia')
      }

      if (campo.hasOwnProperty('listaValores')){
        campo.listaValores.forEach(campo2=> {
          this.procesosCampo(campo2)
        })
      }else {
        console.error('Falta una llave con contenio array "listaValores" en esta secuencia del tipo evaluacionPoligrafia')
      }

      if (campo.hasOwnProperty('listaOpciones')){
        campo.listaValores.forEach(campo2=> {
          this.procesosCampo(campo2)
        })
      }else {
        console.log('Falta una llave con contenio array "listaOpciones" en esta secuencia del tipo evaluacionPoligrafia')
      }
    },
  },

   watch: {
     forms: {
       handler(val) {
         this.procesos(this.forms,true);
       },
       deep: true
     },
  },
};
</script>
<style scoped>
.vCard {
  height: 20%;
  overflow-y: hidden;
}
.stepper {
  height: 80%;
  overflow-y: scroll;
}
</style>
